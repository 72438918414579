import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../components/scroll_top";
import Swal from 'sweetalert2'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendMailWaitinglist } from "../api/apiHandler";

const WaitingList = () => {

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {

        var mailData = {
            "email": data.email
        }

        sendMailWaitinglist(mailData).then((res) => {
            var response = JSON.parse(res)

            if (response.code === 200) {
                Swal.fire({
                    position: 'top',
                    icon: 'success',
                    title: "Success, Please check your Mail",
                    toast: true,
                    showConfirmButton: false,
                    timer: 4000
                })
                navigate("/");
            }
            else {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 4000
                })
            }
        })

    }

    return (
        <>
            <ScrollToTop />
            <main className="h-100">
                <section className="h-100">
                    <div className="container h-100">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-10 col-12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="select-country-card">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                <p className="font_24 font_700 mb-0">Waiting List</p>
                                            </div>
                                            <Link to="/"> <img src="assets/images/icon/Close.png" alt="close"
                                                className="close-btn-img" /> </Link>

                                        </div>
                                        <p className="font_14 text">We’re not in your region yet, but you can sign up for our
                                            waiting list and we’ll let you know when we’re available there.</p>
                                        <div className="mb-3">
                                            <input type="text" {...register('email')} className="form-control" id="exampleFormControlInput1"
                                                placeholder="Email Address" />
                                            <div className="invalid-feedback">{errors.email?.message}</div>
                                        </div>
                                        <p className="font_14 text mb-4">Please see our <span className="">privacy policy </span>
                                            for
                                            more information on how we will
                                            process your personal
                                            data and which information we will provide to you via email.</p>

                                        <div className="row justify-content-center">
                                            <div className="col-lg-7">
                                                <button type="submit" style={{ "border": "none" }} className="get-start font_18 text-center text-white">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </section>

            </main>
        </>
    )
}
export default WaitingList; 