import FooterBusiness from "../components/footer/footer-business"
import HeaderBusiness from "../components/header/header-business"
import Model from "../components/model/model"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom'
import { useState } from "react"
import ScrollToTop from "../components/scroll_top"

const Business = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <HeaderBusiness />

                <section className="bg_black redefining">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-12">
                                <h1 className="text-white font_54 font_700 mb_30">Redefining <br /> Business Solutions</h1>
                                <p className="font_28 mb-0 font_400 text-white">Achieve your financial goals easily, like never
                                    before, with our dynamic business accounts. Stay on top of your game with our diverse range
                                    of services designed to suit your day to day business financial needs. </p>
                                <Link to="/select-country" target="_blank" className="try_mt_62 white_bordered_btn font_30 font_500">Try it
                                    Today</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg_light_black multi_currency">
                    <div className="container-fluid">
                        <div className="row align-items-center gap-xxl-4">
                            <div className="col-12 col-xxl-4 col-xl-5 col-lg-6 col-md-4 col-sm-12">
                                <div className="multicurrency-icon">
                                    <img src="assets/images/multi-currency-accounts.png" className="img-fluid" alt="currency" />
                                </div>
                            </div>
                            <div className="col-12 col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <h3 className="text-white font_28 font_700 mb_30">Multi-Currency IBANs in 20+ Currencies</h3>
                                <p className="mb-0 font_22 font_300 gray_9b ">With the FINSAI multi-currency IBAN, you can receive
                                    international payments from over 70 countries. Optimize your business operations smoothly
                                    with a specific IBAN that supports over more than 20 currencies globally. Manage your
                                    payments with ease while simultaneously managing transactions in USD, GBP, or EUR. </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg_black global_act">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-10 col-lg-10 col-md-11 col-sm-12">
                                <span className="font_32 gray_a3 font_400 d-block mb_10 text-center">Send global, act local</span>
                                <h2 className="font_54 font_700 text-white text-center mb-0">Uncover new methods for expanding the
                                    reach of your account globally</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid px-lg-0">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_1">
                                    <div className="row">
                                        <div className="col-4 col-xl-6 col-lg-6 col-md-5 col-sm-5">
                                            <div className="d-flex justify-content-center">
                                                <div className="icon">
                                                    <img src="assets/images/gateway.png" className="img-fluid" alt="gateway" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-xl-6 col-lg-6 col-md-7 col-sm-7">
                                            <h4 className="font_28 text-white font_700 mb_20">Gateway to Global <br /> Business
                                                Success</h4>
                                            <p className="font_22 font_300 mb-3 gray_9b text">Explore our seamless crypto to fiat
                                                conversion, simplifying your crypto assets into traditional currencies.
                                                Strengthen your business with the flexibility and adaptability it needs to
                                                flourish in evolving markets</p>
                                            <Link to="/select-country" target="_blank" className="blue_outline_btn font_22 font_500">Know More
                                                <img src="assets/images/right-circle.png" className="img-fluid ms-2" alt="arrow" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_2 gateway_1">
                                    <div className="row">
                                        <div className="col-4 col-xl-6 col-lg-6 col-md-5 col-sm-5">
                                            <div className="d-flex justify-content-center">
                                                <div className="icon">
                                                    <img src="assets/images/FX-1.png" className="img-fluid" alt="fx" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-xl-6 col-lg-6 col-md-7 col-sm-7">
                                            <h4 className="font_28 text-white font_700 mb_20">FX Solutions <br /> Simplified</h4>
                                            <p className="font_22 font_300 mb-3 gray_9b text">Invest in your business's future with
                                                FINSAI's Foreign Exchange Service. Take advantage today and explore a vast
                                                spectrum of currencies while easily tracking real-time conversions at
                                                competitive rates. All available under one single hub. </p>
                                            <Link to="/select-country" target="_blank"
                                                className="text-blue font_22 font_500 d-inline-flex try-it-btn align-items-center pt-4">Try
                                                It
                                                Now
                                                <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg_light_black financial_flexibility">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12 mb-4 mb-md-0">
                                <div className="finance_flex_image">
                                    <img src="assets/images/flexibility.png" className="img-fluid" alt="flexibility" />
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <h3 className="font_28 font_700 text-white mb-5">Financial Flexibility Through <br />
                                    FINSAI Cards</h3>
                                <p className="font_22 font_300 gray_9b mb-5">Achieve boundless financial flexibility through our
                                    Virtual and Debit Cards for your business. Get instant access to your funds, whether digital
                                    or with our physical cards, simplifying your purchases, withdrawals, and online payments
                                    with incredible ease and security. Empowering your business anytime, anywhere.</p>
                                <Link to="/select-country" target="_blank" className="blue_outline_btn font_22 font_500">Explore Cards <img
                                    src="assets/images/right-circle.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="easy_invoices bg_black">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12">
                                <div >
                                    <div className="invoice_easy_image">
                                        <img src="assets/images/invoice-easy.png" className="img-fluid" alt="easy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                                <div className="invoice_easy_text">
                                    <h3 className="font_28 font_700 text-white mb_25">Invoices Made Easy</h3>
                                    <p className="font_22 font_300 gray_9b mb_40">Take the Jargon out of issuing invoices and
                                        simplify your billing process with our easy and user-friendly invoicing feature.
                                        Generate and share your invoices with clients in a hassle-free manner, and get paid
                                        instantly.</p>
                                    <Link to="/select-country" target="_blank" className="blue_outline_btn font_22 font_500">Explore Cards <img
                                        src="assets/images/right-circle.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="double_accounts easy_invoices bg_black">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="invoice_easy_text">
                                    <h3 className="font_28 font_700 text-white mb_25">Double Accounts, Multiple Benefits</h3>
                                    <p className="font_22 font_300 gray_9b mb_40">Set up personal and business accounts on FINSAI
                                        and reap the rewards of increased efficiency. Navigate between your personal and
                                        business accounts, optimize your transactions, get notified, and take control of your
                                        business journey with confidence. Your financial success is now in your power.</p>
                                    <Link to="/select-country" target="_blank" className="text-blue font_22 font_500 try-it-btn">Create Account <img
                                        src="assets/images/right-circle-blue.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="d-flex justify-content-end">
                                    <div className="invoice_easy_image">
                                        <img src="assets/images/double-accounts.png" className="img-fluid" alt="accounts" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="waiting_text bg_black">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-10 col-lg-10 col-md-12 col-sm-12">
                                <p className="font_32 font_400 gray_a3 text-center mb_10">What are you waiting for?</p>
                                <h2 className="font_54 font_700 text-white text-center mb-0">Get organized with smart tools to track
                                    payments, spend, and more</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="making_penny bg_light_black">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="invoice_easy_text">
                                    <h3 className="font_28 font_700 text-white mb_25">Making Every Penny Count!</h3>
                                    <p className="font_22 font_300 gray_9b mb_40">Round up your transactions and channel the
                                        difference into your chosen pot. With every transaction you make, achieve your savings
                                        target stress-free. Make every single spare change count!</p>
                                    <Link className="try-it-btn font_22 font_500" href="/select-country" target="_blank">Explore More
                                        <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-6 col-md-6 col-sm-12 offset-xl-1">
                                <div className="invoice_easy_image"><img src="assets/images/every-penny1.png" className="img-fluid"
                                    alt="top-up" /></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="easy_invoices bg_black">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                <div className="invoice_easy_image">
                                    <img src="assets/images/save-today.png" className="img-fluid" alt="save-today" />
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                                <div className="invoice_easy_text">
                                    <h3 className="font_28 font_700 text-white mb_25">Save Today and Thrive Tomorrow</h3>
                                    <p className="font_22 font_300 gray_9b mb_40">Strengthen your business savings goals with
                                        FINSAI’s customized savings pots. Allocate your savings with ease for each of your goals
                                        and manage them all in one place, one pot at a time.</p>
                                    <Link to="/select-country" target="_blank" className="blue_outline_btn font_22 font_500">Start Saving Now <img
                                        src="assets/images/right-circle.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="double_accounts easy_invoices bg_black">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="invoice_easy_text">
                                    <h3 className="font_28 font_700 text-white mb_25">Top-Up Your Funds For Success</h3>
                                    <p className="font_22 font_300 gray_9b mb_40">Boost your business account with cash at ease with
                                        our top-up service. Access convenient locations across the UK and top-up your account
                                        balance instantly. You can now add your funds via bank transfer or between FINSAI
                                        accounts for free.</p>
                                    <Link to="/select-country" target="_blank" className="try-it-btn font_22 font_500">Create Account <img
                                        src="assets/images/right-circle-blue.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-6 col-md-6 col-sm-12 offset-xl-1">
                                <div className="invoice_easy_image">
                                    <img src="assets/images/top-up.png" className="img-fluid" alt="top-up" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="waiting_text bg_black">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-7 col-lg-7 col-md-10 col-sm-12">
                                <p className="font_32 font_400 gray_a3 text-center mb_10">Keep more of what you earn</p>
                                <h2 className="font_54 font_700 text-white text-center mb-0">Explore all the ways you can accept
                                    payments</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid px-lg-0">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_1">
                                    <div className="row align-items-center">
                                        <div className="col-4 col-xl-5 col-lg-5 col-md-4 col-sm-4">
                                            <div className="text-center">
                                                <img src="assets/images/fianance-partner.png" className="img-fluid" alt="fianance" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-xl-7 col-lg-7 col-md-8 col-sm-8">
                                            <h4 className="font_28 text-white font_700 mb_20">Your 24*7 <br /> Finance Partner </h4>
                                            <p className="font_20 font_300 mb-2 gray_9b text">Our smart digital payment transfer
                                                feature
                                                empowers you to conveniently access your personal or business account on your
                                                mobile device, anytime and anywhere, extending beyond the limitations of
                                                traditional banking hours. Start your financial journey with us today!</p>
                                            <Link to="/select-country" target="_blank" className="try-it-btn font_22 font_500">Download App Now
                                                <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_2 gateway_1">
                                    <div className="row align-items-center">
                                        <div className="col-4 col-xl-5 col-lg-5 col-md-4 col-sm-4">
                                            <div className="text-center">
                                                <img src="assets/images/fingertips.png" className="img-fluid" alt="fingertips" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-xl-7 col-lg-7 col-md-8 col-sm-8">
                                            <h4 className="font_28 text-white font_700 mb_20">Secure Your Account <br /> At Your
                                                Fingertips</h4>
                                            <p className="font_20 font_300 mb-2 gray_9b text">Enhance your business security by
                                                safeguarding your logins with our extra safe verification methods that utilize
                                                your fingerprint identification, or FaceID, effectively. You are the key to your
                                                business’s safety.  </p>
                                            <Link to="/select-country" target="_blank"
                                                className="try-it-btn font_22 font_500 d-inline-flex align-items-center">Learn More
                                                <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="keep_costs pb_100 bg_black">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-10 col-lg-10 col-md-12 col-sm-12">
                                <p className="font_32 font_400 gray_a3 mb_10 text-center">More time to focus on your business</p>
                                <h2 className="font_54 font_700 text-center text-white mb-0">Keep costs down and productivity high
                                    with Finsai Business </h2>
                                <div className="row mt_100">
                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="get_new_card">
                                            <div className="text-center mb_25 get-new-card-icon">
                                                <img src="assets/images/Get-Notified-1.png" className="img-fluid" alt="get" />
                                            </div>
                                            <h5 className="font_28 font_700 mb_40 text-white">Get Notified</h5>
                                            <p className="gray_9b font_22 font_300 text min-text-h">Stay informed of all your transactions,
                                                balances, and activities with our Real-Time Instant Notifications for your
                                                Business accounts. Enabling you to stay aware and alert on your financial
                                                spendings.</p>
                                            <Link to="/select-country" target="_blank"
                                                className="try-it-btn font_22 font_500 d-inline-flex align-items-center">Sign Up Now
                                                <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="get_new_card">
                                            <div className="text-center mb_25 get-new-card-icon">
                                                <img src="assets/images/New-Dimension.png" className="img-fluid" alt="new" />
                                            </div>
                                            <h5 className="font_28 font_700 text-white min-text-h">A New Dimension of Security</h5>
                                            <p className="gray_9b font_22 font_300 text">Protect your online purchases with an
                                                added layer of security with Mastercard 3D Secure - a safe and advanced
                                                two-factor authentication step that provides you with peace of mind while making
                                                a purchase. Your safety is our top most priority. </p>
                                            <Link to="/select-country" target="_blank"
                                                className="try-it-btn  font_22 font_500 d-inline-flex align-items-center">Learn More
                                                <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-pad bg_black">
                    <div className="container">
                        <div className="business_price_paln_mb d-flex justify-content-between align-items-center flex-wrap">
                            <div>
                                <p className="font_64 font_700 text-white">Pricing plans</p>
                                <p className="font_20 text-gray">Discover more, experience better – choose your plan and unlock a
                                    world tailored to you.</p>
                            </div>
                            <div>
                                <Link to="/select-country" target="_blank" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="business_card_plan card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/white-logo.svg" className="h_24 mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-white  lh-sm">Premium
                                            <br /> £ 9.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        <p className="text-gray font_14 text indulge_height">Experience the epitome of luxury with
                                            our Premium
                                            Debit
                                            Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                            that redefine your financial journey.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-white font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="bg-transparent ccard_shadow crcard-img">
                                        <img src="assets/images/plans-card--3.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="business_card_plan card-plan">
                                    <div className="w-330">
                                        <div className="d-flex mb-3 gap-2">
                                            <img src="assets/images/white-logo.svg" className="h_24" alt="logo" />
                                            <p className="vip-tag">VIP</p>
                                        </div>
                                        <p className="font_34 font_600 text-white  lh-sm">Metal
                                            <br /> £ 14.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        <p className="text-gray font_14 text indulge_height">Indulge yourself in the ultimate
                                            experience with our
                                            Metal
                                            Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                            rewards, top-tier security, and an aura of exclusivity.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-white font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="bg-transparent ccard_shadow crcard-img">
                                        <img src="assets/images/plans-card--4.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <FooterBusiness />
            </main>
            <Model />

            <HelmetProvider>
                <Helmet>
                    <script>
                        {
                            `   
                            ////text height////
                            var highestBox = 0;
                            $('.gateway_1 .text').each(function () {
                                // alert('this');
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.gateway_1 .text').height(highestBox);

                            var highestBox = 0;
                            $('.waiting_text .text').each(function () {
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.waiting_text .text').height(highestBox);

                            var highestBox = 0;
                            $('.indulge_height').each(function () {
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.indulge_height').height(highestBox);

                            ////text height////
                            var highestBox = 0;
                            $('.card-plan .text').each(function () {
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.card-plan .text').height(highestBox);
                            

                           
                        
                            `
                        }
                    </script>
                </Helmet>
            </HelmetProvider>
        </>
    )
}
export default Business