import { Link } from "react-router-dom"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const RiskAssessment = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="terms-title-sec">
                    <div className="container">
                        <p className="title mb-0">AML/CTF Risk Assessment</p>
                    </div>
                </section>
                <section className="terms-content-sec privacy-sec">
                    <div className="container-fluid">
                        <div className="row mb-4 ms-lg-3 me-lg-3">
                            <div className=" col-xl-4 col-lg-4 col-md-5">
                                <div className="nav custom-tabs rounded flex-column nav-pills me-lg-3" id="v-pills-tab"
                                    role="tablist" aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-tab1" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        <p className="num mb-0">1</p>
                                        <p className="mb-0 text">Overview </p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-2"
                                        type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        <p className="num mb-0">2</p>
                                        <p className="mb-0 text">Definitions</p>

                                    </button>
                                    <button className="nav-link" id="v-pills-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-3"
                                        type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        <p className="num mb-0">3</p>
                                        <p className="mb-0 text">Methodology</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-4"
                                        type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        <p className="num mb-0">4</p>
                                        <p className="mb-0 text">Risk factors</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-5"
                                        type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                        <p className="num mb-0">5</p>
                                        <p className="mb-0 text">Products and Services</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab6" data-bs-toggle="pill" data-bs-target="#v-pills-6"
                                        type="button" role="tab" aria-controls="v-pills-6" aria-selected="false">
                                        <p className="num mb-0">6</p>
                                        <p className="mb-0 text">Country or geographic area</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab7" data-bs-toggle="pill" data-bs-target="#v-pills-7"
                                        type="button" role="tab" aria-controls="v-pills-7" aria-selected="false">
                                        <p className="num mb-0">7</p>
                                        <p className="mb-0 text">Delivery mechanisms
                                            (channels)</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab8" data-bs-toggle="pill" data-bs-target="#v-pills-8"
                                        type="button" role="tab" aria-controls="v-pills-8" aria-selected="false">
                                        <p className="num mb-0">8</p>
                                        <p className="mb-0 text">Risk definition and analyze</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab9" data-bs-toggle="pill" data-bs-target="#v-pills-9"
                                        type="button" role="tab" aria-controls="v-pills-8" aria-selected="false">
                                        <p className="num mb-0">9</p>
                                        <p className="mb-0 text">Risk assessment</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab10" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-10" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">10</p>
                                        <p className="mb-0 text">Risk process</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab11" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-11" type="button" role="tab" aria-controls="v-pills-11"
                                        aria-selected="false">
                                        <p className="num mb-0">11</p>
                                        <p className="mb-0 text">Conclusion</p>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-7 bg-lightblue">

                                <div className="tab-content" id="v-pills-tabContent">


                                    <div className="tab-pane fade active show" id="v-pills-1" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-0 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 text-black font_700 pt-2">1. Overview </p>

                                                    <p className="text-height">This risk assessment is to understand and evaluate
                                                        the risks of money
                                                        laundering and terrorist financing in the activities of FINSAI, taking
                                                        into account relevant risk factors, including those related to
                                                        customers, countries or geographic areas, the products and services
                                                        offered, operations and transactions or delivery mechanisms.</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-2" role="tabpanel"
                                        aria-labelledby="v-pills-profile-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">2. Definitions
                                                    </p>

                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">

                                                                <p className="font_18 font_600 black_21">Money laundering</p>
                                                                <div>
                                                                    <p className="text-height">Money launderers
                                                                        take part in criminal
                                                                        activity to obtain financial gain by generating a profit
                                                                        from the individual or group that fall victim to such
                                                                        activity. Money laundering is the processing of proceeds
                                                                        to disguise their illegal origin. This process is of
                                                                        critical importance, as it enables the criminal to use
                                                                        these profits without jeopardizing their source of
                                                                        income.


                                                                    </p>
                                                                    <p className="text-height">

                                                                        Illegal arms sales, smuggling, and the activities of
                                                                        organized crime, including for example drug trafficking
                                                                        and prostitution rings, can generate huge amounts of
                                                                        proceeds. Embezzlement, insider trading, bribery and
                                                                        computer fraud schemes can also produce large profits
                                                                        and create the incentive to “legitimize” the ill-gotten
                                                                        gains through money laundering.

                                                                    </p>
                                                                    <p className="text-height pb-0">When a money launderer generates
                                                                        substantial profits, the individual or group involved
                                                                        must find a way to control the funds without attracting
                                                                        attention to the underlying activity or the persons
                                                                        involved. This is done by disguising the sources,
                                                                        changing the form, or moving the funds to a place where
                                                                        they are less likely to attract attention
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">

                                                                <p className="font_18 font_600 black_21">AML</p>
                                                                <div>
                                                                    <p className="text-height pb-0">Anti money laundering refers to
                                                                        a set
                                                                        of laws, regulations, and procedures intended to prevent
                                                                        criminals from disguising illegally obtained funds as
                                                                        legitimate income. Though anti-money-laundering (AML)
                                                                        laws cover a relatively limited range of transactions
                                                                        and criminal behaviors, their implications are
                                                                        far-reaching.
                                                                    </p>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">

                                                                <p className="font_18 font_600 black_21">CFT</p>
                                                                <div>
                                                                    <p className="text-height pb-0">The measures put in place to
                                                                        prevent those involved in the financing and perpetration
                                                                        of terror and terror-related activity from accessing
                                                                        financial services.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Client</p>
                                                                <div>
                                                                    <p className="text-height pb-0">Individuals: Individuals with an
                                                                        account, product or service. Entities: Natural persons,
                                                                        company, trust, charity, partnership, or sole trader
                                                                        with a FI account, product or service.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Beneficial owner</p>
                                                                <div>
                                                                    <p className="text-height pb-0">Beneficial owner refers to the
                                                                        natural person who ultimately owns or controls a
                                                                        customer and/or the natural person on whose behalf a
                                                                        transaction is being conducted. It also includes those
                                                                        persons who exercise ultimate effective control over a
                                                                        legal person or arrangement.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Client Due Diligence (CDD)
                                                                </p>
                                                                <div>
                                                                    <p className="text-height pb-0">The process of implementing
                                                                        policies and procedures that are designed to help
                                                                        monitor and evaluate the illicit finance risk posed by a
                                                                        client. Client due diligence can include but is not
                                                                        limited to establishing the identity of clients,
                                                                        determining expected client behaviour, and/or monitoring
                                                                        account activity to identify those transactions that do
                                                                        not conform with the normal or expected transactions for
                                                                        that client or type of account.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Enhanced Due Diligence
                                                                    (EDD)
                                                                </p>
                                                                <div>
                                                                    <p className="text-height pb-0">Additional information collected
                                                                        as part of the client due diligence process or increased
                                                                        cautionary measures, such as ongoing monitoring of
                                                                        activity, applied on a risk-sensitive basis in any
                                                                        situation which, by its nature, can present a higher
                                                                        risk of money laundering or terrorist financing. The
                                                                        extent of additional information sought, and of any
                                                                        monitoring carried out, in respect of any particular
                                                                        business relationship, or className/category of business
                                                                        relationship, will depend on the money laundering or
                                                                        terrorist financing risk that the client, or
                                                                        className/category of business relationship, is assessed to
                                                                        present to the firm.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Inherent risk
                                                                </p>
                                                                <div>
                                                                    <p className="text-height pb-0">Represents the exposure to money
                                                                        laundering, sanctions or bribery and corruption risk in
                                                                        the absence of any control environment being applied.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Risk assessment
                                                                </p>
                                                                <div>
                                                                    <p className="text-height pb-0">A risk assessment is an
                                                                        exercise used to identify key risks faced by the firm
                                                                        and to test the controls that a firm has in place to
                                                                        mitigate these risks. Risks can be both external and
                                                                        internal to the firm. The risk assessment aims to
                                                                        measure the total exposure a firm has to the risks it
                                                                        faces and to plan actions to reduce these risks.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="v-pills-3" role="tabpanel"
                                        aria-labelledby="v-pills-messages-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">3. Methodology
                                                    </p>
                                                    <p>The risk assessment of FINSAI follows the best international standards
                                                        and practices in Anti money laundering and Combating the financing of
                                                        terrorism. When preparing the risk assessment of money laundering and
                                                        terrorist financing, the following steps are taken:</p>
                                                    <ul className="ul-liststyle protection-policy">
                                                        <li>
                                                            Defining Key Risk Factors (RF) for the risk measurement</li>
                                                        <li>Perform quantitative and qualitative risk assessment</li>

                                                    </ul>
                                                    <p>For the purpose of assessing the risk of money laundering and terrorist
                                                        financing, four main (major) risk factors are introduced:</p>
                                                    <div>
                                                        <ul className="ul-liststyle protection-policy">
                                                            <li className="font_600">RF 1 Client and beneficial owner of the capital
                                                            </li>
                                                        </ul>
                                                        <p className="text-height">For the purposes of assessing the inherent money
                                                            laundering risk the
                                                            clients and business relationship are assessed. A number of client
                                                            types, activities, professions and businesses, alongside other
                                                            factors, such as the length of a client relationship, can increase
                                                            or decrease money laundering risks. The following categories will be
                                                            used to stratify the client base and to identify aspects of client
                                                            risk: client type, ownership, activity, profession, Pep’s.</p>

                                                    </div>
                                                    <div>
                                                        <ul className="ul-liststyle protection-policy">
                                                            <li className="font_600">RF 2 Products and Services
                                                            </li>
                                                        </ul>
                                                        <p className="text-height">All offering products and services of FINSAI are
                                                            assessing and the company taken the appropriate measures to decrease
                                                            money laundering and financing of terrorism risks.</p>

                                                    </div>
                                                    <div>
                                                        <ul className="ul-liststyle protection-policy">
                                                            <li className="font_600">RF 3 Country or geographic area
                                                            </li>
                                                        </ul>
                                                        <p className="text-height">Identifying geographic locations that may pose a
                                                            higher risk is a core component of any inherent risk assessment.
                                                            FINSAI seek to understand and evaluate the specific risks associated
                                                            with doing business in, opening and servicing accounts, offering
                                                            products and services and/or facilitating transactions involving
                                                            certain geographic locations.</p>

                                                    </div>
                                                    <div>
                                                        <ul className="ul-liststyle protection-policy">
                                                            <li className="font_600">RF 4 Delivery mechanisms (channels)
                                                            </li>
                                                        </ul>
                                                        <p className="text-height">Delivery mechanism (channels) can increase money
                                                            laundering risk consequently, it is assessed whether, and to what
                                                            extent, the method of account origination or account servicing, such
                                                            as non-face-to-face account opening. </p>
                                                        <p>Risk sub-factors are defined for all major (four) risk factors. They
                                                            are scored with points 1 to 3 depending on the risk that they carry:
                                                        </p>
                                                        <ul className="ul-liststyle protection-policy">
                                                            <li>High risk - over 3 accumulated points</li>
                                                            <li>Medium risk - between 1.5 and 2.9 points</li>
                                                            <li>Normal risk - between 0 and 1.4 points</li>
                                                        </ul>
                                                        <p>Intermediate points are thus formed to be used for the final
                                                            assessment of the risk of money laundering and terrorist financing.
                                                            For the final evaluation, the sum of the estimates of the four
                                                            interim evaluations is used. The final risk is determined by the
                                                            result:</p>
                                                        <ul className="ul-liststyle protection-policy">
                                                            <li>
                                                                High risk - over 3 accumulated points</li>
                                                            <li>Medium risk - between 1.5 and 2.9 points</li>
                                                            <li>Normal risk - between 0 and 1.4 points</li>
                                                        </ul>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-4" role="tabpanel"
                                        aria-labelledby="v-pills-settings-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">4. Risk factors
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">4.1. Client and beneficial
                                                                    owner the capital:
                                                                </p>
                                                                <div className="define-sub-card">
                                                                    <p className="font_18 font_500 black_21">4.1.1. Risk factors
                                                                        related with the clients:
                                                                    </p>
                                                                    <ul className="ul-liststyle">
                                                                        <li>
                                                                            Connectivity with sectors that are associated with a
                                                                            higher risk of corruption;
                                                                        </li>
                                                                        <li>

                                                                            Linked to sectors that are associated with a higher
                                                                            risk of money laundering and terrorist financing;
                                                                        </li>
                                                                        <li>
                                                                            Connectivity with sectors characterized by the
                                                                            active use of cash;
                                                                        </li>
                                                                        <li>
                                                                            Belonging to any of the categories of Pep’s;
                                                                        </li>
                                                                        <li>
                                                                            The level of corruption in the country or
                                                                            jurisdiction concerned;
                                                                        </li>
                                                                        <li>
                                                                            Belonging to watchlists (EU/USA sanctions, embargo
                                                                            and terrorism; Pep’s);
                                                                        </li>
                                                                        <li>
                                                                            Other risk factors;
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="define-sub-card">
                                                                    <p className="font_18 font_500 black_21">4.1.2. Risk factors
                                                                        related with beneficial owners of capital:
                                                                    </p>
                                                                    <ul className="ul-liststyle">
                                                                        <li>
                                                                            the availability of information in the media or
                                                                            other reliable sources of information about alleged
                                                                            terrorism or terrorist financing or asset blocking
                                                                            of the client or a person related to them in
                                                                            connection with the implementation of measures
                                                                            against the financing of terrorism;
                                                                        </li>
                                                                        <li>
                                                                            the existence of negative information about the
                                                                            client, including on alleged crimes, on the media,
                                                                            on official information pages or other reliable
                                                                            sources of information;
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                                <div className="define-sub-card mb-0">
                                                                    <p className="font_18 font_500 black_21">4.1.3. Risk factors
                                                                        related with client activity:
                                                                    </p>
                                                                    <ul className="ul-liststyle mb-0">
                                                                        <li>
                                                                            Inability or reluctance to present convincing
                                                                            evidence of identity;
                                                                        </li>
                                                                        <li>
                                                                            Doubts about the authenticity or accuracy of
                                                                            identification data;
                                                                        </li>
                                                                        <li>
                                                                            Presence or absence of economic or legal logic or
                                                                            purpose of transactions or operations that have been
                                                                            or have been declared or executed, which are
                                                                            complex, unusual or unexpectedly large, have unusual
                                                                            or unexpected rationale or which are carried out in
                                                                            unusual schemes;
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-5" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">5. Products and Services:
                                                    </p>
                                                    <ul className="ul-liststyle mb-0">
                                                        <li>Transparency of the product, service, transaction</li>
                                                        <li>
                                                            Complexity of the product, service, transaction; </li>
                                                        <li>Values or amount of the product, service, transaction</li>
                                                    </ul>


                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-6" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">6. Country or geographic area
                                                    </p>
                                                    <ul className="ul-liststyle mb-0">
                                                        <li>The effectiveness of prevention and prevention of money laundering
                                                            and terrorist financing systems in the country or geographical area;
                                                        </li>
                                                        <li>
                                                            The level of risk of terrorist financing in the country or
                                                            geographical area; </li>
                                                        <li>The level of transparency and with law in the country or
                                                            geographical area;</li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-7" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">7. Delivery mechanisms (channels)
                                                </p>
                                                <ul className="ul-liststyle mb-0">
                                                    <li>Physically present of the identification or is performed remotely;
                                                    </li>

                                                </ul>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-8" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">8. Risk definition and analyze
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Client Beneficial owner
                                                                </p>
                                                                <div className="define-sub-card">
                                                                    <p className="font_18 font_500 black_21">Description
                                                                    </p>
                                                                    <p className="pb-0">Individuals Legal entity</p>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-6 col-12">
                                                                        <div className="define-sub-card text-height">
                                                                            <p className="font_18 font_500 black_21">Analyze
                                                                            </p>
                                                                            <p className="text-height">Normal risk ​ – includes
                                                                                local customers with UK
                                                                                ID
                                                                            </p>
                                                                            <p className="text-height">
                                                                                Medium risk - clients, with no high-risk
                                                                                characteristics</p>
                                                                            <p className="text-height">High risk - customers with
                                                                                the following
                                                                                characteristics:</p>
                                                                            <p className="text-height">Client is from high-risk
                                                                                country.</p>
                                                                            <p className="text-height">Client is a local Pep’s, or a
                                                                                person associated
                                                                                with
                                                                                a Pep’s. </p>
                                                                            <p className="text-height">The legal person is situated
                                                                                in a country, which
                                                                                is
                                                                                listed in the list of high-risk countries.</p>
                                                                            <p className="text-height">The legal persons activities
                                                                                and liability are
                                                                                insufficiently regulated by law, and the
                                                                                legality of
                                                                                financing of which is not easy to screen.  </p>
                                                                            <p className="text-height">User is suspected to be or to
                                                                                have been linked
                                                                                with a
                                                                                financial offence or other suspicious
                                                                                activities.
                                                                            </p>
                                                                            <p className="text-height">User is a non-resident
                                                                                individual, whose place of
                                                                                residence or activities is in a country, which
                                                                                is
                                                                                listed in the list of high-risk countries. </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-12">
                                                                        <div className="define-sub-card">
                                                                            <p className="font_18 font_500 black_21">Measures
                                                                            </p>
                                                                            <p>Measures related with risk profile:
                                                                            </p>
                                                                            <ul className="ul-liststyle protection-policy">
                                                                                <li>Client with Normal risk - Simplified or
                                                                                    lesser
                                                                                    amount of due diligence</li>
                                                                                <li>
                                                                                    Client with Medium risk – Standard due
                                                                                    diligence
                                                                                    (CDD)
                                                                                </li>
                                                                                <li>
                                                                                    Client with High risk - Enhanced due
                                                                                    diligence (EDD)
                                                                                </li>
                                                                            </ul>
                                                                            <p className="text-height pb-0">When identifying a
                                                                                higher
                                                                                risk, additional action
                                                                                is
                                                                                taken, such as requiring additional documents,
                                                                                placing the customer under special monitoring,
                                                                                reporting to the competent authorities and
                                                                                terminating the relationship.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Products and services
                                                                </p>
                                                                <div className="define-sub-card">
                                                                    <p className="font_18 font_500 black_21">Description
                                                                    </p>
                                                                    <p className="pb-0">Buy and sell cryptocurrency</p>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-6 col-12 mb-3">
                                                                        <div className="define-sub-card text-height h-100">
                                                                            <p className="font_18 font_500 black_21">Analyze
                                                                            </p>
                                                                            <p className="text-height">FINSAI is more cautious and
                                                                                considers the
                                                                                provision of products and services to the
                                                                                following categories of persons to be higher
                                                                                risk:
                                                                            </p>
                                                                            <p className="text-height">
                                                                                Proxy</p>
                                                                            <p className="text-height">Pep’s</p>
                                                                            <p className="text-height">FINSAI provides Medium  and
                                                                                High-risk level
                                                                                product and services</p>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-12 mb-3">
                                                                        <div className="define-sub-card h-100">
                                                                            <p className="font_18 font_500 black_21">Measures
                                                                            </p>

                                                                            <p className="text-height pb-0">FINSAI appoints
                                                                                Compliance officer/MLRO to perform adequate AML
                                                                                measures and client/transaction monitoring. When
                                                                                FINSAI identifies High risk in clients or
                                                                                transaction the company perform EDD. Regular
                                                                                monitoring of operations and customer behavior
                                                                                is carried out to prevent and timely detect
                                                                                emerging risks associated with money laundering
                                                                                and terrorist financing.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Country or geographical
                                                                    area
                                                                </p>
                                                                <div className="define-sub-card">
                                                                    <p className="font_18 font_500 black_21">Description
                                                                    </p>
                                                                    <ul className="ul-liststyle protection-policy mb-0">
                                                                        <li>Individuals </li>
                                                                        <li>Legal entity</li>
                                                                    </ul>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-6 col-12 mb-2">
                                                                        <div className="define-sub-card h-100">
                                                                            <p className="font_18 font_500 black_21">Analyze
                                                                            </p>
                                                                            <p className="text-height">Clients are classNameified in
                                                                                different risk profiles
                                                                                according to their geographical location to
                                                                                manage and minimize the inherent risk associated
                                                                                with money laundering and terrorist financing.
                                                                            </p>


                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-12 mb-2">
                                                                        <div className="define-sub-card h-100">
                                                                            <p className="font_18 font_500 black_21">Measures
                                                                            </p>

                                                                            <p className="text-height pb-0">For high-risk clients an
                                                                                EDD check is performed, requiring additional
                                                                                documentation and verification of the client's
                                                                                operations and operations.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Delivery mechanisms
                                                                    (channels)
                                                                </p>
                                                                <div className="define-sub-card">
                                                                    <p className="font_18 font_500 black_21">Description
                                                                    </p>
                                                                    <p className="pb-0">FINSAI offer products and services online
                                                                        with compliance
                                                                        with law.</p>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-6 col-12 mb-2">
                                                                        <div className="define-sub-card h-100">
                                                                            <p className="font_18 font_500 black_21">Analyze
                                                                            </p>
                                                                            <p className="text-height pb-0">All clients will be
                                                                                identified online
                                                                            </p>


                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-12 mb-2">
                                                                        <div className="define-sub-card h-100">
                                                                            <p className="font_18 font_500 black_21">Measures
                                                                            </p>

                                                                            <p className="text-height pb-0">The processes of remote
                                                                                (online) offer of products and services are
                                                                                approved by the regulatory authorities and
                                                                                adequate monitoring is carried out by FINSAI.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-9" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">9. Risk assessment
                                                </p>
                                                <p className="text-height">In the following table, the interim risk assessments by
                                                    risk factors and the
                                                    overall risk assessment, based on the interim evaluations, were performed.
                                                </p>
                                                <div className="table-assess table-responsive mb-3">
                                                    <table className="table rounded-corners">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="4" className="text-center">Assessment RF 1 Client and
                                                                    beneficial owner</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="td-heading">
                                                                <td className="text-center ">No.</td>
                                                                <td>Assessment risk sub factor</td>
                                                                <td className="text-center">Assessment</td>
                                                                <td className="text-center">
                                                                    Level of risk
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">1</td>
                                                                <td>Individual</td>
                                                                <td className="text-center">1</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">2</td>
                                                                <td>Legal entity</td>
                                                                <td className="text-center">2</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" className="text-center font_500 black_21">Interim
                                                                    evaluation
                                                                </td>
                                                                <td className="text-center font_500 black_21">1.5</td>
                                                                <td className="text-center font_500 black_21">Medium risk</td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-assess table-responsive mb-3">
                                                    <table className="table rounded-corners">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="4" className="text-center">Assessment RF 2 Product and
                                                                    services</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="td-heading">
                                                                <td className="text-center ">No.</td>
                                                                <td>Assessment risk sub factor</td>
                                                                <td className="text-center">Assessment</td>
                                                                <td className="text-center">
                                                                    Level of risk
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">1</td>
                                                                <td>Buy and sell cryptocurrency</td>
                                                                <td className="text-center">3</td>
                                                                <td></td>
                                                            </tr>

                                                            <tr>
                                                                <td colSpan="2" className="text-center font_500 black_21">Interim
                                                                    evaluation
                                                                </td>
                                                                <td className="text-center font_500 black_21">3</td>
                                                                <td className="text-center font_500 black_21">Medium risk</td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-assess table-responsive mb-3">
                                                    <table className="table rounded-corners">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="4" className="text-center">Assessment RF 3 Country or
                                                                    geography area</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="td-heading">
                                                                <td className="text-center ">No.</td>
                                                                <td>Assessment risk sub factor</td>
                                                                <td className="text-center">Assessment</td>
                                                                <td className="text-center">
                                                                    Level of risk
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">1</td>
                                                                <td>Local customers UK</td>
                                                                <td className="text-center">1</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">2</td>
                                                                <td>Residents in EU</td>
                                                                <td className="text-center">2</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">3</td>
                                                                <td>Other</td>
                                                                <td className="text-center">3</td>
                                                                <td></td>
                                                            </tr>

                                                            <tr>
                                                                <td colSpan="2" className="text-center font_500 black_21">Interim
                                                                    evaluation
                                                                </td>
                                                                <td className="text-center font_500 black_21">3.5</td>
                                                                <td className="text-center font_500 black_21">Medium risk</td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-assess table-responsive mb-3">
                                                    <table className="table rounded-corners">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="4" className="text-center">Assessment RF 4 Delivery channels</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="td-heading">
                                                                <td className="text-center ">No.</td>
                                                                <td>Assessment risk sub factor</td>
                                                                <td className="text-center">Assessment</td>
                                                                <td className="text-center">
                                                                    Level of risk
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">1</td>
                                                                <td>Online offer services</td>
                                                                <td className="text-center">2</td>
                                                                <td></td>
                                                            </tr>

                                                            <tr>
                                                                <td colSpan="2" className="text-center font_500 black_21">Interim
                                                                    evaluation
                                                                </td>
                                                                <td className="text-center font_500 black_21">2</td>
                                                                <td className="text-center font_500 black_21">Medium risk</td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-assess table-responsive mb-3">
                                                    <table className="table rounded-corners">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">RF</th>
                                                                <th className="text-center">RF 1</th>
                                                                <th className="text-center">RF 2</th>
                                                                <th className="text-center">RF 3</th>
                                                                <th className="text-center">RF 4</th>
                                                                <th className="text-center border-end-0">Overall <br />
                                                                    assessment</th>
                                                                <th className="text-center">Overall <br />
                                                                    risk assessment</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="td-heading">
                                                                <td>Interim<br />
                                                                    evaluations</td>
                                                                <td className="text-center">1.5</td>
                                                                <td className="text-center">3</td>
                                                                <td className="text-center">
                                                                    3.5
                                                                </td>
                                                                <td className="text-center">
                                                                    2
                                                                </td>
                                                                <td className="text-center border-end-0">
                                                                    2.5
                                                                </td>
                                                                <td className="text-center">
                                                                    Medium risk
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-10" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">10. Risk process
                                                </p>
                                                <p>AML Risk Assessment are prepared by the Compliance officer and approved by
                                                    the General manager of FINSAI.</p>
                                                <p className="pb-0">
                                                    The Risk Assessments  are updated every two years. The assessment is also
                                                    updated in the following cases:</p>
                                                <ul className="ul-liststyle protection-policy mb-0">
                                                    <li>Significant change in the products or services.
                                                    </li>
                                                    <li>Occurrence of other events or factors that could be material to the
                                                        overall level of risk arising from the activity of FINSAI;</li>
                                                </ul>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-11" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">11. Conclusion
                                                </p>
                                                <p>Based on the assessment of the risk of money laundering and terrorist
                                                    financing of FINSAI, we confirm Medium risk level</p>

                                                <p className="pb-0">In all cases of increased risk, FINSAI will apply extended
                                                    measures to
                                                    effectively counter money laundering.</p>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="attach-sec">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="mb-0 title">Attachment 1:</p>
                                <p className="text mb-0">FINSAI AML / CFT Risk Assessment GAP analysis</p>
                            </div>
                            <div>
                                <Link to="/aml-risk-assessment-gap-analysis"> <img className="arrow-icon"
                                    src="assets/images/icon/white-circle-arrow.png" alt="icon" /> </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}
export default RiskAssessment