import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import { Link } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"


const Cards = () => {

    const [scroll, setScroll] = useState(true)

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main className="finsai-about-main">
                <Header />
                <section className="sec-pad">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div>
                                    <p className="font_64 font_700">Spending becomes
                                        easier with FINSAI</p>
                                    <p className="font_24 font_500 font_inter mb-sm-5">Manage finances with the card in one tap</p>
                                    <Link to="/select-country" target="_blank" className="get-started w-fit-content font_20 font_600">Explore
                                        now</Link>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-6 col-12">
                                <div className="card-section-img">
                                    <img src="assets/images/finsai-cards-img.png" alt="cards" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="payment-finance-sec">
                            <div className="row">
                                <div className="col-lg-6 col-xl-5 col-md-6 col-12">
                                    <div className="payment-finance">
                                        <div className="pays-image">
                                            <img src="assets/images/payment-finance.svg" alt="payment" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-7 col-md-6 col-12">
                                    <div>
                                        <p className="font_40 text-white font_700">Accessing Easy Finance with
                                            FINSAI: Your Smooth Payment
                                            Solution</p>
                                        <p className="font_inter font_18 font_500 text-white">In a fast-paced world where
                                            convenience is a
                                            priority, FINSAI opens the doors to
                                            effortless payment solutions. With the best financial technology at your fingertips,
                                            we bring you the best of financial convenience. We simplify your financial journey
                                            with mobile payments, contactless transactions, and a suite of personal finance
                                            tips.</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-12">
                                <div>
                                    <p className="font_40 font_700">Connect. Tap. Spend</p>
                                    <p className="gray_79 font_22 font_500">Connect your card and manage your finances under one
                                        roof,
                                        It's easier and more efficient
                                        than you can imagine. Simply add a card, tap on the options, and start paying. Say
                                        goodbye
                                        to complexity and embrace financial ease with FINSAI. It's time to unlock your financial
                                        potential.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12">
                                <div className="connect-tap-send">
                                    <img src="assets/images/conect-tap-send.png" alt="conect" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-4 col-lg-5 col-md-5 col-12 flex-order-2">
                                <div className="connect-tap-send">
                                    <img src="assets/images/yellow-bell.png" alt="yellow" />
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-7 col-12 flex-order-1">
                                <div>
                                    <p className="font_40 font_700">Notifications for you to Control</p>
                                    <p className="gray_79 font_22 font_500">Get instant notifications of your transactions and
                                        manage them with security by knowing the next financial step based on your payment
                                        reports. Because you deserve a flawless finance system with online payments that also
                                        helps keep us safe, secure, and smart.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad mb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-12">
                                <div>
                                    <p className="font_40 font_700">Swift, Worry-Free, Instant Financial Transactions</p>
                                    <p className="gray_79 font_22 font_500">Spending and handling different modes of transactions
                                        become easier now. Instant payments for your Instant urge to buy without worrying about
                                        anything! Seamlessly transition into the digital age with our user-friendly mobile and
                                        online payment options to make instant finance decisions.</p>

                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12">
                                <div className="connect-tap-send swift-transfer">
                                    <img src="assets/images/swift-transfer.png" alt="swift" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="payment-finance-sec">
                            <div className="row">

                                <div className="col-lg-6 col-xl-7 col-md-6 col-12 flex-order-2">
                                    <div>
                                        <p className="font_40 text-white font_700">Experience Financial Freedom Today</p>
                                        <p className="font_inter font_18 font_500 text-white mb-4">At FINSAI, we're your partners in
                                            uncomplicated finance with the best finance technology. We simplify your financial
                                            journey with mobile payments, contactless transactions, and a suite of personal
                                            finance tips. With FINSAI, you can now manage transactions effortlessly and handle
                                            your finances, leaving behind all the money stress. Your dream of easy online
                                            payments and a secure financial future starts here.</p>
                                        <Link to="/select-country" target="_blank" className="white_btn font_20 font_600 ">Sign Up Now</Link>
                                    </div>


                                </div>
                                <div className="col-lg-6 col-xl-5 col-md-6 col-12 flex-order-1">
                                    <div className="freedom-finance">
                                        <div className="pays-image">
                                            <img src="assets/images/freedom-finance.png" alt="finance" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <p className="font_64 font_700 text-center mb-120">What's More!</p>
                        <div className="row">
                            <div className="col-lg-4 col-xl-4 col-12">
                                <div className="whats-new-card">
                                    <div className="whats-new-icon">
                                        <img src="assets/images/secure.png" alt="secure" />
                                    </div>
                                    <div className="whats-new-para">
                                        <p className="font_24 font_700 mb-1">Secure</p>
                                        <ul className="gray_79 font_500 mb-0">
                                            <li>Prevents you from Fraudulent activities.</li>
                                            <li>Easy Lock and Unlock for your security.
                                            </li>
                                            <li>Secure yet Instant Payments.</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-12">
                                <div className="whats-new-card">
                                    <div className="whats-new-icon">
                                        <img src="assets/images/safe.png" alt="safe" />
                                    </div>
                                    <div className="whats-new-para">
                                        <p className="font_24 font_700 mb-1">Safe</p>
                                        <ul className="gray_79 font_500 mb-0">
                                            <li>Freeze and Unfreeze payments. </li>
                                            <li>Control every transaction.
                                            </li>
                                            <li>Get Notified to keep you safe.</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-12">
                                <div className="whats-new-card">
                                    <div className="whats-new-icon">
                                        <img src="assets/images/smart.png" alt="smart" />
                                    </div>
                                    <div className="whats-new-para">
                                        <p className="font_24 font_700 mb-1">Smart</p>
                                        <ul className="gray_79 font_500 mb-0">
                                            <li>Make Smart payments easier.
                                            </li>
                                            <li>No more Hassle and worry.
                                            </li>
                                            <li>Payments are on your fingertip.</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad mb_40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-5 col-12 ">
                                <div className="no-wallet-img">
                                    <img src="assets/images/no-wallet.png" alt="wallet" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-7 col-12 ">
                                <div>
                                    <p className="font_40 font_700">No Wallet, No Problem</p>
                                    <p className="gray_79 font_22 font_500">Effortlessly carry your cards on your mobile device for
                                        secure access to your funds,
                                        anywhere, anytime. Enjoy unmatched financial control and flexibility through digital
                                        payments.
                                        Welcome to a new era in finance with FINSAI!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="begins-sec mb_78">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-11 col-12">
                                <div className="text-center">
                                    <p className="title font_22">It all begins here</p>
                                    <p className="font_40 text-white font_700 mb-4">Discover a path to effortless payments and a
                                        financially
                                        secure future with FINSAI, your trusted
                                        financial service provider.</p>
                                    <Link to="/select-country" target="_blank"
                                        className="sign_gradient_btn font_18 font_600 mb-4 mb-sm-5"> Discover FINSAI
                                        now</Link>
                                    <div className="text-center d-sm-flex align-items-center justify-content-center">
                                        <Link to="/select-country" target="_blank" className="d-block">
                                            <img src="assets/images/play-store.svg"
                                                className="me-sm-3 mb-3 mb-sm-0 img-fluid icon-playstore" alt="play store" />
                                        </Link>
                                        <Link to="/select-country" target="_blank" className="d-block ">
                                            <img src="assets/images/app-store.svg" className="img-fluid icon-playstore"
                                                alt="app store" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-pad">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-120 flex-wrap">
                            <div>
                                <p className="font_64 font_700">Pricing plans</p>
                                <p className="font_20 gray_52">Discover more, experience better – choose your plan and unlock a
                                    world<br /> tailored to you.</p>
                            </div>
                            <div>
                                <Link to="/select-country" target="_blank" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Lite <br />
                                            £ 0.00<span className="font_14 font_600">/Month</span>
                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Start your journey with confidence toward meeting your
                                            financial goals. Experience optimized financial transactions and custom-made
                                            solutions for your needs.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--1.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Smart
                                            <br />
                                            £ 4.99<span className="font_14 font_600">/Month</span>

                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Conveniently handle your day-to-day finances with our
                                            Smart
                                            Plan.Unlock a world of convenient money management and personalized perks. Grab your
                                            key to a smarter way of handling your finances.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--2.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Premium
                                            <br /> £ 9.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Experience the epitome of luxury with our Premium
                                            Debit
                                            Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                            that redefine your financial journey.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--3.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <div className="d-flex mb-3 gap-2">
                                            <img src="assets/images/icon/logo-card.png" alt="logo" />
                                            <p className="vip-tag">VIP</p>
                                        </div>
                                        <p className="font_34 font_600 text-blue  lh-sm">Metal
                                            <br /> £ 14.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Indulge yourself in the ultimate experience with our
                                            Metal
                                            Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                            rewards, top-tier security, and an aura of exclusivity.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--4.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <Footer />
                <HelmetProvider>
                    <Helmet>
                        <script>
                            {
                                `
                                ////text height////
                                var highestBox = 0;
                                $('.card-plan .text').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.card-plan .text').height(highestBox);
                            `
                            }
                        </script>
                    </Helmet>
                </HelmetProvider>
            </main>
            <Model />

        </>
    )
}

export default Cards