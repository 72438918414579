import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const DataProtection = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="terms-title-sec">
                    <div className="container">
                        <p className="title mb-0">Data Protection Policy</p>
                    </div>
                </section>
                <section className="terms-content-sec privacy-sec">
                    <div className="container-fluid">


                        <div className="row mb-4 ms-lg-3 me-lg-3">
                            <div className=" col-xl-4 col-lg-4 col-md-5">
                                <div className="nav custom-tabs rounded flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-tab1" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        <p className="num mb-0">1</p>
                                        <p className="mb-0 text">Introduction </p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-2"
                                        type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        <p className="num mb-0">2</p>
                                        <p className="mb-0 text">Definitions</p>

                                    </button>
                                    <button className="nav-link" id="v-pills-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-3"
                                        type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        <p className="num mb-0">3</p>
                                        <p className="mb-0 text">Scope</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-4"
                                        type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        <p className="num mb-0">4</p>
                                        <p className="mb-0 text">Responsibility for this Policy</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-5"
                                        type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                        <p className="num mb-0">5</p>
                                        <p className="mb-0 text">Our Procedures</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab6" data-bs-toggle="pill" data-bs-target="#v-pills-6"
                                        type="button" role="tab" aria-controls="v-pills-6" aria-selected="false">
                                        <p className="num mb-0">6</p>
                                        <p className="mb-0 text">Subject Access Requests</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab7" data-bs-toggle="pill" data-bs-target="#v-pills-7"
                                        type="button" role="tab" aria-controls="v-pills-7" aria-selected="false">
                                        <p className="num mb-0">7</p>
                                        <p className="mb-0 text">GDPR Provisions</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab8" data-bs-toggle="pill" data-bs-target="#v-pills-8"
                                        type="button" role="tab" aria-controls="v-pills-8" aria-selected="false">
                                        <p className="num mb-0">8</p>
                                        <p className="mb-0 text">Consequences of Failing
                                            to Comply</p>
                                    </button>


                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-7 bg-lightblue">

                                <div className="tab-content" id="v-pills-tabContent">

                                    {/* <!-- <div className="tab-pane fade " id="v-pills-default" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="terms-para">

                                                    <p className="mb-0">PLEASE READ THE TERMS & CONDITIONS SET OUT HEREIN (THIS
                                                        “AGREEMENT”)
                                                        CAREFULLY BEFORE
                                                        ACCEPTING THEM AND USING THE SERVICES PROVIDED BY US. YOU AGREE THAT ANY
                                                        USE OF OUR
                                                        SERVICES BY YOU SHALL CONSTITUTE YOUR ACCEPTANCE OF THIS AGREEMENT. IF
                                                        YOU DO NOT
                                                        ACCEPT
                                                        THE TERMS AND CONDITIONS OUTLINED IN THIS AGREEMENT, DO NOT ACCESS OR
                                                        USE FINSAI
                                                        SERVICES.</p>

                                                </div>
                                                <div className="content-card">
                                                    <p className="mb-0">ALLOY PAY Limited is a legal entity (13858479) trading as
                                                        The activity of
                                                        FINSAI. The
                                                        activity of Finsai is regulated by the Payment Services Regulation 2017
                                                        and the
                                                        Electronic Money Regulation 2011 (SI 2011/99). This Agreement is an
                                                        electronic
                                                        agreement between you and FINSAI . This Agreement emphasizes certain
                                                        risks
                                                        associated with the use of our services. This Agreement is made in
                                                        English and the
                                                        English version of the Agreement prevails over any other version
                                                        translated into any
                                                        other language, which may be provided by us to you for convenience
                                                        purposes.</p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div> --> */}
                                    <div className="tab-pane fade active show" id="v-pills-1" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-0 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 text-black font_700 pt-2"> Introduction </p>

                                                    <p>This policy sets out how we seek to protect personal data and ensure
                                                        that staff understand the rules governing their use of personal data
                                                        to which they have access in the course of their work. In
                                                        particular, this policy requires staff to ensure that the appointed
                                                        Data Protection Officer (DPO) be consulted before any significant
                                                        new data processing activity is initiated to ensure that relevant
                                                        compliance steps are addressed.</p>


                                                    <p>We hold personal data about our employees, clients, suppliers and other
                                                        individuals for a variety of business purposes.</p>
                                                    <p>We need to collect and use certain types of information about staff,
                                                        clients and other individuals who come into contact with the company in
                                                        order to operate. In addition, it may be required by law to collect and
                                                        use certain types of information to comply with statutory obligations of
                                                        local authorities, government agencies and other bodies.
                                                    </p>
                                                    <div>
                                                        <p className="pb-0">This personal information must be dealt with properly
                                                            however it is
                                                            collected, recorded and used - whether on paper, in a computer, or
                                                            recorded on other material - and there are safeguards in place to
                                                            ensure this is undertaken in compliance with relevant legislation
                                                            including:</p>
                                                        <ul className="ul-liststyle protection-policy">
                                                            <li>Human Rights Act 1998  </li>
                                                            <li>Freedom of Information Act 2000 </li>
                                                            <li>Privacy and Electronic Communications Regulations 2003</li>
                                                            <li>Regulation of Investigatory Powers Act 2000 </li>
                                                            <li>Telecommunications (Lawful Business Practice) </li>
                                                            <li>Interception of Communications Regulations 2000  </li>
                                                            <li>Data Protection Act 1998 </li>
                                                            <li>Computer Misuse Act 1990</li>
                                                            <li>Regulation (EU) 2016/679 (General Data Protection Regulation)
                                                                ("GDPR")</li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-2" role="tabpanel"
                                        aria-labelledby="v-pills-profile-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">Definitions
                                                    </p>

                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">

                                                                <p className="font_18 font_600 black_21">Business purposes</p>
                                                                <div>
                                                                    <p className="mb-0 pb-0">The purposes for which personal data
                                                                        may be
                                                                        used by
                                                                        us:
                                                                    </p>
                                                                    <ul className="ul-liststyle">
                                                                        <li>Personnel, administrative, financial, regulatory,
                                                                            payroll
                                                                            and
                                                                            business development purposes</li>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <p className="mb-0 pb-0">

                                                                        Business purposes include the following:
                                                                    </p>
                                                                    <ul className="ul-liststyle protection-policy mb-0">
                                                                        <li>Compliance with our legal, regulatory and corporate
                                                                            governance obligations and good practice</li>
                                                                        <li>
                                                                            Gathering information as part of investigations by
                                                                            regulatory bodies or in connection with legal
                                                                            proceedings or
                                                                            requests
                                                                        </li>
                                                                        <li>
                                                                            Ensuring business policies are adhered to (such as
                                                                            policies
                                                                            covering email and internet use)
                                                                        </li>
                                                                        <li>
                                                                            Operational reasons, such as recording transactions,
                                                                            training and quality control, ensuring the
                                                                            confidentiality
                                                                            of commercially sensitive information, security
                                                                            vetting,
                                                                            credit scoring and checking
                                                                        </li>
                                                                        <li>Investigating complaints </li>
                                                                        <li>Checking references, ensuring safe working
                                                                            practices,
                                                                            monitoring and managing staff access to systems and
                                                                            facilities and staff absences, administration and
                                                                            assessments</li>
                                                                        <li>Monitoring staff conduct, disciplinary matters</li>
                                                                        <li>Marketing our business</li>
                                                                        <li>Improving services</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Personal data</p>
                                                                <p className="para-text">Information relating to identifiable
                                                                    individuals, such as job
                                                                    applicants, current and former employees, agency, contract
                                                                    and other staff, clients, suppliers and marketing contacts.
                                                                </p>
                                                                <br />
                                                                <p className="para-text">Personal data we gather may include individuals&#39; contact details,
                                                                    educational background, financial and pay details, details of
                                                                    certificates and diplomas, education and skills, marital status,
                                                                    nationality, job title, and CV.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Sensitive personal data</p>
                                                                <p className="para-text">Personal data about an individual's racial
                                                                    or
                                                                    ethnic origin,
                                                                    political opinions, religious or similar beliefs, trade
                                                                    union membership (or non-membership), physical or mental
                                                                    health or condition, criminal offences, or related
                                                                    proceedings - any use of sensitive personal data should be
                                                                    strictly controlled in accordance with this policy.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="v-pills-3" role="tabpanel"
                                        aria-labelledby="v-pills-messages-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">Scope
                                                    </p>
                                                    <p>This policy applies to all staff. You must be familiar with this policy
                                                        and comply with its terms.</p>
                                                    <p>This policy supplements our other policies relating to internet and email
                                                        use. We may supplement or amend this policy by additional policies and
                                                        guidelines from time to time. Any new or modified policy will be
                                                        circulated to staff before being adopted.</p>


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-4" role="tabpanel"
                                        aria-labelledby="v-pills-settings-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">Responsibility for this Policy
                                                    </p>
                                                    <p>Our appointed Data Protection Officer (DPO) has overall responsibility
                                                        for the day-to-day implementation of this policy.</p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-5" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">Our Procedures
                                                    </p>


                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">

                                                                <p className="font_18 font_600 black_21">Fair and Lawful Processing
                                                                </p>
                                                                <p>We must process personal data fairly and lawfully in
                                                                    accordance with
                                                                    individuals’ rights. This generally means that we should not
                                                                    process
                                                                    personal data unless the individual whose details we are
                                                                    processing
                                                                    has consented to this happening.</p>

                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <div>
                                                                    <p className="font_18 font_600 black_21">

                                                                        Data Protection Officer's Responsibilities
                                                                    </p>
                                                                    <ul className="ul-liststyle protection-policy mb-0">
                                                                        <li>Keeping the board updated about data protection
                                                                            responsibilities, risks and issues</li>
                                                                        <li>
                                                                            Reviewing all data protection procedures and
                                                                            policies on a
                                                                            regular basis
                                                                        </li>
                                                                        <li>
                                                                            Arranging data protection training and advice for
                                                                            all staff
                                                                            members and those included in this policy
                                                                        </li>
                                                                        <li>
                                                                            Answering questions on data protection from staff,
                                                                            board
                                                                            members and other stakeholders
                                                                        </li>
                                                                        <li>Responding to individuals such as clients and
                                                                            employees who
                                                                            wish to know which data is being held on them by
                                                                            Finsai
                                                                            Ltd. </li>
                                                                        <li>Checking and approving with third parties that
                                                                            handle the
                                                                            company’s data and any contracts or agreement
                                                                            regarding data
                                                                            processing.</li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">IT Manager's
                                                                    Responsibilities</p>
                                                                <ul className="ul-liststyle protection-policy mb-0">
                                                                    <li>Ensure all systems, services, software and equipment
                                                                        meet acceptable security standards</li>
                                                                    <li>Checking and scanning security hardware and software
                                                                        regularly to ensure it is functioning properly</li>
                                                                    <li>Researching third-party services, such as cloud services
                                                                        the company is considering using to store or process
                                                                        data</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Marketing Manager's
                                                                    Responsibilities</p>
                                                                <ul>
                                                                    <li>Approving data protection statements attached to emails
                                                                        and other marketing copy</li>
                                                                    <li>Addressing data protection queries from clients, target
                                                                        audiences or media outlets</li>
                                                                    <li>Coordinating with the DPO to ensure all marketing
                                                                        initiatives adhere to data protection laws and the
                                                                        company’s Data Protection Policy</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">

                                                                    Processing of all Data
                                                                </p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Must be necessary to deliver our services</li>
                                                                    <li>In our legitimate interests and not unduly prejudice the
                                                                        individual's privacy</li>
                                                                    <li>In most cases this provision will apply to routine
                                                                        business
                                                                        data
                                                                        processing activities</li>
                                                                    <li className="pb-3">Our terms of business/legal documents
                                                                        contain a
                                                                        privacy policy
                                                                        on data protection.
                                                                    </li>
                                                                    <li>
                                                                        The privacy policy:
                                                                    </li>
                                                                    <ul>
                                                                        <li>Sets out the purposes for which we hold personal
                                                                            data on
                                                                            customers and employees</li>
                                                                        <li>Highlights that our work may require us to give
                                                                            information
                                                                            to third parties such as professional advisers</li>
                                                                        <li>Provides that customers have a right of access to
                                                                            the
                                                                            personal data that we hold about them</li>
                                                                    </ul>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Sensitive Personal Data
                                                                    (Special Categories of Data)</p>
                                                                <p className="para-text">In most cases where we process sensitive
                                                                    personal data, we
                                                                    will require the data subject's explicit consent to do this
                                                                    unless exceptional circumstances apply or we are required to
                                                                    do this by law (e.g. to comply with legal obligations to
                                                                    ensure health and safety at work). Any such consent will
                                                                    need to clearly identify what the relevant data is, why it
                                                                    is being processed and to whom it will be disclosed.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">Accuracy and Relevance</p>
                                                                <p className="text-height">We will ensure that any personal data we
                                                                    process is accurate,
                                                                    adequate, relevant and not excessive, given the purpose for
                                                                    which it was obtained. We will not process personal data
                                                                    obtained for one purpose for any unconnected purpose unless
                                                                    the individual concerned has agreed to this or would
                                                                    otherwise reasonably expect this.

                                                                </p>
                                                                <p className="text-height">
                                                                    Individuals may ask that we correct inaccurate personal data
                                                                    relating to them. If you believe that information is
                                                                    inaccurate you should record the fact that the accuracy of
                                                                    the information is disputed and inform the DPO.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Your Personal Data</p>
                                                                <p className="para-text">You must take reasonable steps to ensure
                                                                    that personal data we hold about you is accurate and updated
                                                                    as required. For example, if your personal circumstances
                                                                    change, please inform the DPO so that they can update your
                                                                    records.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Data Security</p>
                                                                <p className="para-text">You must keep personal data secure against
                                                                    loss or misuse. Where other organisations process personal
                                                                    data as a service on our behalf, the DPO will establish
                                                                    what, if any, additional specific data security arrangements
                                                                    need to be implemented in contracts with those third-party
                                                                    organisations.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="font_18 font_600 black_21">

                                                                    Storing Data Securely
                                                                </p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>In cases when data is stored on printed paper, it should
                                                                        be kept in a secure place where unauthorized personnel
                                                                        cannot access it</li>
                                                                    <li>Printed data should be shredded when it is no longer
                                                                        needed</li>
                                                                    <li>Data stored on a computer should be protected by strong
                                                                        passwords that are changed regularly. We encourage all
                                                                        staff to use a <a target="_blank"
                                                                            href="https://cybersecurityzen.com/cybersecurity/keep-data-safe-rise-password-managers-1654/"
                                                                            className="link">password manager</a> to create and
                                                                        store
                                                                        their passwords</li>
                                                                    <li>Data stored on CDs or memory sticks must be locked away securely when they are not being used</li>
                                                                    <li className="">The DPO must approve any cloud used to
                                                                        store data                                                                    </li>
                                                                    <li>Servers containing personal data must be kept in a
                                                                        secure location, away from general office space
                                                                    </li>
                                                                    <li>Data should be regularly backed up in line with the
                                                                        company’s backup procedures</li>
                                                                    <li>Data should never be saved directly to mobile devices
                                                                        such as laptops, tablets or smartphones</li>
                                                                    <li>All servers containing sensitive data must be approved
                                                                        and protected by security software and strong firewall
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Data Retention</p>
                                                                <p className="para-text">We must retain personal data for no longer than is necessary. What is necessary will depend on the circumstances of each case, taking into account the reasons that the personal data was obtained, but should be determined in a manner consistent with our data retention guidelines.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Transferring Data Internationally</p>
                                                                <p className="para-text">There are restrictions on international transfers of personal data. You must not transfer personal data anywhere outside the UK without first consulting the DPO.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-xl-12 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Processing Data in
                                                                    accordance with the Individuals Rights</p>
                                                                <p>You should abide by any request from an
                                                                    individual not to use their personal data for direct
                                                                    marketing purposes and notify the DPO about any such
                                                                    request.</p>

                                                                <p> Do not send direct marketing material to someone
                                                                    electronically (e.g. via email) unless you have an existing
                                                                    business relationship with them in relation to the services
                                                                    being marketed or consent to do so.</p>

                                                                <p className="pb-0"> Please contact the DPO for advice on direct
                                                                    marketing before
                                                                    starting any new direct marketing activity.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-xl-12 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Training</p>
                                                                <p className="text-height">All staff will receive training on this
                                                                    policy. New
                                                                    joiners
                                                                    will receive training as part of the induction process.
                                                                    Further training will be provided at least every two years
                                                                    or whenever there is a substantial change in the law or our
                                                                    policy and procedure.</p>

                                                                <p className="text-height pb-0">Training is provided through an
                                                                    in-house
                                                                    seminar on a regular
                                                                    basis. It will cover:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>The law relating to data protection</li>
                                                                    <li>Our data protection and related policies and procedures
                                                                    </li>
                                                                </ul>
                                                                <p className="pb-0">

                                                                    Completion of training is compulsory.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-6" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">Subject Access Requests
                                                    </p>
                                                    <p>Please note that under the data protection legislation, individuals are
                                                        entitled, subject to certain exceptions, to request access to
                                                        information held about them.</p>
                                                    <p>If you receive a subject access request, you should refer that request
                                                        immediately to the DPO. We may ask you to help us comply with those
                                                        requests. We cannot charge the data subject for requesting this
                                                        information; however, we can charge a ‘reasonable fee’ when a request is
                                                        manifestly unfounded or excessive, particularly if it is repetitive.</p>
                                                    <p>We may also charge a reasonable fee to comply with requests for further
                                                        copies of the same information. This does not mean that we can charge
                                                        for all subsequent access requests.</p>
                                                    <p>The fee must be based on the administrative cost of providing the
                                                        information.</p>
                                                    <p>Please contact the DPO if you would like to correct or request
                                                        information that we hold about you. There are also restrictions on the
                                                        information to which you are entitled under applicable law.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-7" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">GDPR Provisions
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Transparency of Data
                                                                    Protection</p>
                                                                <p className="para-text">Being transparent and providing accessible
                                                                    information to individuals about how we will use their
                                                                    personal data is important for our organisation. </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Conditions for Processing
                                                                </p>
                                                                <p className="para-text">We will ensure any use of personal data is
                                                                    justified using at least one of the conditions for
                                                                    processing and this will be specifically documented. All
                                                                    staff who are responsible for processing personal data will
                                                                    be aware of the conditions for processing. The conditions
                                                                    for processing will be available to data subjects in the
                                                                    form of a privacy notice.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-4 ">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Justification for Personal
                                                                    Data
                                                                </p>
                                                                <p className="text-height">We will process personal data in
                                                                    compliance
                                                                    with all six data protection principles.</p>
                                                                <p className="pb-0">We will document the additional justification
                                                                    for the
                                                                    processing of sensitive data and will ensure any biometric
                                                                    and genetic data is considered sensitive.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Consent
                                                                </p>
                                                                <p className="text-height"> Where any data that we collect or
                                                                    process
                                                                    is based on consent it must have been active consent by the
                                                                    data subject. This consent can be revoked at any time.
                                                                    Legitimate consent consists of:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>
                                                                        Consent means offering individuals genuine choice and
                                                                        control</li>
                                                                    <li>Consent requires a positive opt-in. Do not use
                                                                        pre-ticked boxes or any other method of consent by
                                                                        default</li>
                                                                    <li>Explicit consent requires a very clear and specific
                                                                        statement of consent</li>
                                                                    <li>Keep your consent requests separate from other terms and
                                                                        conditions</li>
                                                                    <li>
                                                                        Be specific and granular. Vague or blanket consent is
                                                                        not enough
                                                                    </li>
                                                                    <li>Be clear and concise</li>
                                                                    <li>Name any third-party controllers who will rely on the
                                                                        consent</li>
                                                                    <li>Make it easy for people to withdraw consent and tell
                                                                        them how</li>
                                                                    <li>Keep evidence of consent – who, when, how, and what you
                                                                        told people</li>
                                                                    <li>Keep consent under review and refresh it if anything
                                                                        changes</li>
                                                                    <li>Avoid making consent a precondition of a service</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Criminal Record Checks</p>
                                                                <p className="para-text">Any criminal record checks need to be
                                                                    justified by law. Criminal record checks cannot be
                                                                    undertaken based solely on the consent of the subject. </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Data Portability
                                                                </p>
                                                                <p className="para-text pb-0">Upon request, a data subject should
                                                                    have
                                                                    the right to receive a copy of their data in a structured
                                                                    format. These requests should be processed within one month,
                                                                    provided there is no undue burden and it does not compromise
                                                                    the privacy of other individuals. A data subject may also
                                                                    request that their data is transferred directly to another
                                                                    system. This must be done for free.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Right to be Forgotten</p>
                                                                <p className="para-text pb-0">A data subject may request that any
                                                                    information held on them is deleted or removed, and any
                                                                    third parties who process or use that data must also comply
                                                                    with the request. An erasure request can only be refused if
                                                                    an exemption applies.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Privacy by Design and
                                                                    Default
                                                                </p>
                                                                <p className="text-height">Privacy by design is an approach to
                                                                    projects that promote privacy and data protection compliance
                                                                    from the start. The DPO will be responsible for conducting
                                                                    Privacy Impact Assessments and ensuring that all IT projects
                                                                    commence with a privacy plan.</p>

                                                                <p className="text-height pb-0"> When relevant, and when it does not
                                                                    have
                                                                    a
                                                                    negative impact
                                                                    on the data subject, privacy settings will be set to the
                                                                    most private by default.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">International Data
                                                                    Transfers</p>
                                                                <p className="para-text pb-0">No data may be transferred outside of
                                                                    the
                                                                    EEA without first discussing it with the DPO. Specific
                                                                    consent from the data subject must be obtained prior to
                                                                    transferring their data outside the EEA.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6 col-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Data Audit and Register
                                                                </p>
                                                                <p className="text-height pb-0">Regular data audits to manage and
                                                                    mitigate risks will inform the data register. This contains
                                                                    information on what data is held, where it is stored, how it
                                                                    is used, who is responsible and any further regulations or
                                                                    retention timescales that may be relevant.</p>


                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Reporting Breaches
                                                                </p>
                                                                <p className="text-height">All members of staff have an obligation
                                                                    to report actual or potential data protection compliance
                                                                    failures within 24 hours of occurrence or discovery. This
                                                                    allows us to:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>
                                                                        Investigate the failure and take remedial steps if
                                                                        necessary</li>
                                                                    <li>Maintain a register of compliance failures</li>

                                                                    <li>Notify the supervisory authority within 72 hours of any
                                                                        compliance failures that are material either in their
                                                                        own right or as part of a pattern of failures</li>

                                                                </ul>
                                                                <p>We only have to notify the relevant supervisory authority of
                                                                    a breach where it is likely to result in a risk to the
                                                                    rights and freedoms of individuals. If unaddressed, such a
                                                                    breach is likely to have a significant detrimental effect on
                                                                    individuals – for example, result in discrimination, damage
                                                                    to reputation, financial loss, loss of confidentiality or
                                                                    any other significant economic or social disadvantage.
                                                                </p>
                                                                <p>Where a breach is likely to result in a high risk to the
                                                                    rights and freedoms of individuals, we must notify those
                                                                    concerned directly.</p>

                                                                <p>A ‘high risk’ means the threshold for notifying individuals
                                                                    is higher than for notifying the relevant supervisory
                                                                    authority.</p>
                                                                <p className="pb-0">Please refer to our ‘Breach reporting policy’
                                                                    for our
                                                                    reporting procedure.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-4">
                                                            <div className="define-card ">
                                                                <p className="font_18 font_600 black_21">Monitoring
                                                                </p>
                                                                <p className="pb-0">Everyone must observe this policy. The DPO has
                                                                    overall
                                                                    responsibility for this policy. They will monitor it
                                                                    regularly to make sure it is being adhered to.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-8" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">Consequences of Failing to Comply
                                                    </p>
                                                    <p>We take compliance with this policy very seriously. Failure to comply
                                                        puts both us and our data subjects at risk.</p>
                                                    <p>The importance of this policy means that failure to comply with any
                                                        requirement may lead to disciplinary action under our procedures which
                                                        may result in dismissal. </p>
                                                    <p className="pb-0">If you have any questions or concerns about anything in this
                                                        policy, do
                                                        not hesitate to contact the DPO.</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}
export default DataProtection;