
export function loginRedirectCall() {
  let path = window.location.protocol + "//" + window.location.host
  //    window.open(path,'mywindow').focus()
  window.location.href = path;
}
export function logOutRedirectCall() {
  // localStorage.removeItem("AisLogin");

  loginRedirectCall()
}

export function loginStoreData(data) {
  // localStorage.setItem("AisLogin", true);

  loginRedirectCall()
}
