import { Link } from "react-router-dom"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const ComplianceMonitoringPlan = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="terms-title-sec">
                    <div className="container">
                        <p className="title mb-0">Compliance Monitoring Plan</p>
                    </div>
                </section>
                <section className="terms-content-sec privacy-sec">
                    <div className="container-fluid">
                        <div className="row mb-4 ms-lg-3 me-lg-3">
                            <div className=" col-xl-4 col-lg-4 col-md-5">
                                <div className="nav custom-tabs rounded flex-column nav-pills me-lg-3" id="v-pills-tab"
                                    role="tablist" aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-tab1" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        <p className="num mb-0">1</p>
                                        <p className="mb-0 text">Objective of the
                                            Compliance Monitoring Plan </p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-2"
                                        type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        <p className="num mb-0">2</p>
                                        <p className="mb-0 text">Responsibility</p>

                                    </button>
                                    <button className="nav-link" id="v-pills-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-3"
                                        type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        <p className="num mb-0">3</p>
                                        <p className="mb-0 text">Review and Ownership
                                            of this Document</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-4"
                                        type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        <p className="num mb-0">4</p>
                                        <p className="mb-0 text">Services</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-5"
                                        type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                        <p className="num mb-0">5</p>
                                        <p className="mb-0 text">Scope of the
                                            Monitoring Program</p>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-7 bg-lightblue">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade active show" id="v-pills-1" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-0 content--main">
                                            <div>
                                                <div className="content-card">

                                                    <ol className="ul-liststyle" type="1">
                                                        <li className="font_26 text-black font_700 pt-2">Objective of the Compliance
                                                            Monitoring Plan</li>
                                                    </ol>
                                                    <p className="text-height">This Compliance Monitoring Plan (CMP) is used to
                                                        monitor the business in respect of its obligations under the regulatory
                                                        regime, pursuant to its authorisation under the Financial Services and
                                                        Markets Act (FSMA), supervised by the Financial Conduct Authority (FCA).
                                                    </p>
                                                    <p className="text-height">It also serves to protect the reputation of the
                                                        company by ensuring compliance with FCA regulatory requirements,
                                                        together with internal policies and procedures.  It confirms to Senior
                                                        Management that regulatory requirements and internal policies and
                                                        procedures are being respected and informs them of significant
                                                        regulatory changes and regulatory risks.
                                                    </p>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-2" role="tabpanel"
                                        aria-labelledby="v-pills-profile-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">

                                                    <ol className="ul-liststyle" type="1" start="2">
                                                        <li className="font_26 text-black font_700 pt-2">Responsibility</li>
                                                    </ol>
                                                    <p className="text-height">Day to day responsibility and oversight of the checks
                                                        contained within this plan has been allocated to the operational team
                                                    </p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="v-pills-3" role="tabpanel"
                                        aria-labelledby="v-pills-messages-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <ol className="ul-liststyle" type="1" start="3">
                                                        <li className="font_26 text-black font_700 pt-2">Review and Ownership of
                                                            this Document</li>
                                                    </ol>
                                                    <p className="text-height">This document will be reviewed annually by Jarvis
                                                        Williams.</p>
                                                    <p className="text-height">To have an effective compliance program, the company
                                                        must establish and
                                                        maintain an organizational culture that encourages ethical conduct and a
                                                        commitment to compliance with the regulatory framework. Consequently,
                                                        the company has developed a comprehensive program designed to help
                                                        employees conduct operations and activities ethically with the highest
                                                        level of integrity, and in compliance with the legal and regulatory
                                                        framework.</p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-4" role="tabpanel"
                                        aria-labelledby="v-pills-settings-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <ol className="ul-liststyle" type="1" start="4">
                                                        <li className="font_26 text-black font_700 pt-2">Services</li>
                                                    </ol>
                                                    <ol className="ul-liststyle" type="1">
                                                        <li>Reception & Transmission of orders in relation to one or more
                                                            financial instruments;</li>
                                                        <li>Execution of orders on behalf of clients.</li>
                                                    </ol>
                                                    <div>
                                                        <ul className="ul-liststyle">
                                                            <li className="font_600">Ancillary Services:</li>
                                                        </ul>

                                                        <ol className="ul-liststyle" type="1">
                                                            <li className="text-height">Safekeeping and administration of financial
                                                                instruments for the
                                                                account of clients, including custodianship and related services
                                                                such as cash/collateral management and excluding maintaining
                                                                securities accounts at the top tier level; and</li>
                                                            <li className="text-height">Foreign exchange services where these are
                                                                connected to the provision
                                                                of investment services.</li>
                                                        </ol>
                                                    </div>
                                                    <div>
                                                        <ul className="ul-liststyle">
                                                            <li className="font_600">Financial Instruments:</li>
                                                        </ul>

                                                        <ol className="ul-liststyle" type="1">
                                                            <li className="text-height">Transferable Securities;</li>
                                                            <li className="text-height">Money market instruments;</li>
                                                            <li className="text-height">Units in Collective investment
                                                                undertakings; </li>
                                                            <li className="text-height">Options, futures, swaps, forward rate
                                                                agreements and any other derivative contracts relating to
                                                                securities, currencies, interest rates or yields, or other
                                                                derivatives instruments, financial indices or financial measures
                                                                which may be settled physically or in cash; </li>
                                                            <li className="text-height">Options, futures, swaps, forwards and any
                                                                other derivative contracts relating to commodities that must be
                                                                settled in cash or may be settled in cash at the option of one
                                                                of the parties other than by reason of default or other
                                                                termination event; </li>
                                                            <li className="text-height">Options, futures, swaps, and any other
                                                                derivative contract relating to commodities that can be
                                                                physically settled provided that they are traded on a regulated
                                                                market, a MTF, or an OTF, except for wholesale energy products
                                                                traded on an OTF that must be physically settled; </li>
                                                            <li className="text-height">
                                                                Options, futures, swaps, forwards and any other derivative
                                                                contracts relating to commodities, that can be physically
                                                                settled not otherwise mentioned in point 6 of this Section and
                                                                not being for commercial purposes, which have the
                                                                characteristics of other derivative financial instruments; </li>
                                                            <li className="text-height">Derivative instruments for the transfer of
                                                                credit risk;</li>
                                                            <li className="text-height">Financial contracts for differences
                                                                (“CFDs”); and</li>
                                                            <li className="text-height">Options, futures, swaps, forward rate
                                                                agreements and any other derivative contracts relating to
                                                                climatic variables, freight rates or inflation rates or other
                                                                official economic statistics that must be settled in cash or may
                                                                be settled in cash at the option of one of the parties other
                                                                than by reason of default or other termination event, as well as
                                                                any other derivative contracts relating to assets, rights,
                                                                obligations, indices and measures not otherwise mentioned in
                                                                this Section, which have the characteristics of other derivative
                                                                financial instruments, having regard to whether, inter alia,
                                                                they are traded on a regulated market, OTF, or an MTF.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-5" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <ol className="ul-liststyle" type="1" start="5">
                                                        <li className="font_26 text-black font_700 pt-2">Scope of the Monitoring
                                                            Program</li>
                                                    </ol>
                                                    <p className="text-height">The purpose of the Program is to inform the company’s Board of Directors
                                                        (the “BoD”) as to the inspections and reviews that will be undertaken
                                                        throughout the year under review and, specifically, to:</p>
                                                    <ol className="ul-liststyle protection-policy mb-3" type="a">
                                                        <li>
                                                            Evaluate whether the company’s operations are conducted in
                                                            compliance with its obligations under the relevant legislation; and
                                                        </li>
                                                        <li>
                                                            Whether the company’s internal guidelines and control measures
                                                            remain effective and appropriate. </li>

                                                    </ol>
                                                    <p className="text-height">The Program is established on a risk-based approach
                                                        and covers all the
                                                        investment services and activities and ancillary services which the
                                                        company is authorized to provide in accordance with its license.</p>
                                                    <p className="text-height">To prepare the monitoring program, the CO considered
                                                        the following:</p>
                                                    <ul className="ul-liststyle">
                                                        <li>The business areas in terms of priority;</li>
                                                        <li>The specific sources of compliance risk to which the company is
                                                            exposed given the nature of its activities;</li>
                                                        <li>
                                                            The frequency of monitoring activities for both desk-based and
                                                            on-site inspections;</li>
                                                        <li>
                                                            The use of risk indicators (e.g. percentage of high-risk clients,
                                                            concentration on clients from high risk jurisdictions, number of
                                                            complaints received etc.);</li>
                                                        <li>Significant events (e.g. corporate acquisition, re-organization
                                                            etc.);</li>
                                                        <li>
                                                            Determining the weight applied on each risk type according to
                                                            probability of occurrence;</li>
                                                        <li>
                                                            The degree of possible mitigation from the risks identified.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="attach-sec">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="mb-0 title">Plan:</p>
                                <p className="text mb-0">FINSAI Compliance Monitoring Plan</p>
                            </div>
                            <div>
                                <Link to="/monitoring-plan"> <img className="arrow-icon"
                                    src="assets/images/icon/white-circle-arrow.png" alt="icon" /> </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}
export default ComplianceMonitoringPlan