import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import { Link, useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "../components/scroll_top"
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from "react-redux";
import { career } from "../api/apiHandler"
import Swal from 'sweetalert2'
import { useState } from "react"


const Careers = () => {

    const countryList = useSelector((state) => {
        return state.countryList;
    })

    const [countryCode, setCountryCode] = useState('+44')
    const handleCountryCode = (e) => {
        setScroll(false)
        setCountryCode(e.target.value)
    }
    const [scroll, setScroll] = useState(true)

    const removeFile = (fileType) => {
        setSelectedFiles({
            ...selectedFiles,
            [fileType]: null,
        });
    };
    const navigate = useNavigate();
    // const supportedFileTypes = { CV: ['pdf', 'docx'] };
    // const maxFileSize = 4 * 1024 * 1024; // 4 MB in bytes

    // function isValidFileType(fileName, fileType) {
    //     return fileName && supportedFileTypes[fileType].indexOf(fileName.split('.').pop()) > -1;
    // }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
            .matches(/^[^@]+@[^@]+\.[^@.]+$/, 'Email must contain at least one dot (.) after the domain'),
        full_name: Yup.string()
            .required('Full Name is required')
            .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, 'Full name should be characters only in the format with a space in between'),
        linkedin: Yup.string()
            .optional()
            .url('Linkedin must be url'),
        mobile_number: Yup.string()
            .required('Mobile Number is required')
            .typeError("That doesn't look like a phone number")
            .matches(/^\d{8,15}$/, 'Mobile Number must be between 8 and 15 digits long')
            .test('is-not-invalid', 'Invalid phone number', (value) => {

                if (value && (value.includes('1234567890') || value.includes('0987654321') || value.includes('9876543210') || value.includes('0123456789'))) {
                    return false;
                }
                return true;
            }),
        position: Yup.string()
            .required('Position is required')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Please enter a valid position'),
        CV: Yup.mixed()
            .test(
                'fileRequired',
                'CV is Required',
                (value) => {
                    if (value && value.length > 0) {
                        return true; // File is provided, the field is valid
                    }
                    return false; // No file provided, the field is required
                }
            )
            .test(
                'fileSize',
                'File size is too large. Maximum file size is 4 mb',
                (value) => {
                    if (value && value.length > 0) {
                        return value[0].size <= 4 * 1024 * 1024;
                    }
                }
            )
            .test(
                'fileType',
                'Invalid file type. Only PDF and DOC files are allowed.',
                (value) => {
                    if (value && value.length > 0) {
                        // console.log(value)
                        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
                        return allowedTypes.includes(value[0].type);
                    }
                }
            ),

        cover_letter: Yup.mixed()
            .test(
                'fileRequired',
                'Cover Letter is Required',
                (value) => {
                    if (value && value.length > 0) {
                        return true; // File is provided, the field is valid
                    }
                    return true; // No file provided, no validation needed
                }
            ).test(
                'fileSize',
                'File size is too large. Maximum file size is 4 mb',
                (value) => {
                    if (value && value.length > 0) {
                        return value[0].size <= 4 * 1024 * 1024;
                    }
                    return true; // No file provided, no validation needed
                }
            ).test(
                'fileType',
                'Invalid file type. Only PDF and DOC files are allowed.',
                (value) => {
                    if (value && value.length > 0) {
                        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
                        return allowedTypes.includes(value[0].type);
                    }
                    return true; // No file provided, no validation needed
                }
            )
    });

    // const [selectedFileName, setSelectedFileName] = useState('No file selected');

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    // const [selectedFile, setSelectedFile] = useState();
    // const changeHandler = (event) => {
    //     setSelectedFile(event.target.files[0]);
    // };

    const [selectedFiles, setSelectedFiles] = useState({
        CV: "",
        cover_letter: "",
    });

    const changeHandler = (event, inputName) => {
        setScroll(false)
        setSelectedFiles({
            ...selectedFiles,
            [inputName]: event.target.files[0],
        });
    };

    // console.log(selectedFiles)
    // console.log("cv-----", selectedFiles.CV.size);
    // console.log('cover_letter', selectedFiles.cover_letter.name)

    const onSubmit = (data) => {
        // console.log(data)
        var careerData = {
            "full_name": data.full_name,
            "linkedin_profile": data.linkedin ? data.linkedin : undefined,
            "country_code": countryCode,
            "mobile_number": data.mobile_number,
            "email": data.email,
            "position": data.position,
            "cv": selectedFiles.CV.name,
            "cover_letter": selectedFiles.cover_letter.name ? selectedFiles.cover_letter.name : undefined
        }
        career(careerData).then((res) => {
            var response = JSON.parse(res)

            if (response.code === 200) {
                // navigate("/");
                Swal.fire({
                    position: 'top',
                    icon: 'success',
                    title: "Success, Your application is submitted",
                    toast: true,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    navigate("/");
                    window.scrollTo({
                        top: 0,
                        behavior: 'instant'
                    });
                }, 4000);
                setScroll(true)

            }
            else {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }
    function convertUnicodeToEmoji(unicodeString) {
        if (!unicodeString) return '';

        const codePoints = unicodeString.split(' ');
        // console.log(parseInt('U+1F1EC', 16))

        const emojiCharacters = codePoints
            .filter((codePoint) => !isNaN(parseInt(codePoint.slice(2), 16)))
            .map((codePoint) => String.fromCodePoint(parseInt(codePoint.slice(2), 16)));

        // console.log(emojiCharacters)

        return emojiCharacters.join('');
    }

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            {/* <ScrollToTop /> */}
            <main className="finsai-about-main">
                <Header />
                <section className="shape_future">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xxl-5 col-xl-6 col-lg-6 col-md-7 col-sm-12 pe-sm-5">
                                <h1 className="font_54 font_700">Shape your future with FINSAI</h1>
                                <p className="font_18 font_500 gray_65 font_inter">Embark on your next career chapter with FINSAI. Be a part of our team and shape your trajectory in a journey marked by growth, innovation, and meaningful influence.</p>
                                <span className="blue_00 font_300 font_30">Welcome to the FINSAI Family!</span>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="our_culture">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                <div className="training_box">
                                    <h5 className="font_20 font_600 title text_lightblue mb-3">Collaboration and<br />Empowerment</h5>
                                    <p className="font_16 font_400 text_lightblue">At FINSAI, we are committed to nurturing a culture focused on collaboration and empowerment. Every team member's viewpoint is important to us. We have cultivated an environment where ideas are encouraged to flow without filters, transcending any hierarchical boundaries. Our channels of open communication and cross-functional teamwork break down barriers, enabling a rich tapestry of diverse viewpoints. </p>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-4 mt-sm-0">
                                <div className="training_box">
                                    <h5 className="font_20 font_600 title text_lightblue mb-3">Training & Professional
                                        Development Opportunities</h5>
                                    <p className="font_16 font_400 text_lightblue ">At FINSAI, we place strong importance on knowledge and prioritizing personal growth for each of our employees. We offer them abundant chances to boost their skills and progress in their careers, ensuring they feel confident and invested in their professional journeys. </p>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-md-0 mt-4">
                                <div className="training_box">
                                    <h5 className="font_20 font_600 title text_lightblue mb-3">Friendly & Diverse<br />
                                        Environment</h5>
                                    <p className="font_16 font_400 text_lightblue ">We are committed to fostering an inclusive and diverse culture where each individual in our company is appreciated for their efforts and achievements. It is important to recognize the uniqueness of backgrounds, viewpoints, and life experiences to nurture an environment rich in innovation and creativity. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="appreciate">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <h2 className="font_64 font_700 text-center text-blue appreciate_heading">We Appreciate</h2>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                                <div className="emabaracing">
                                    <h5 className="font_20 font_600 mb-3 blue_00 text-center">Embracing Innovation and Initiative</h5>
                                    <p className="font_16 font_400 gray_79 text-center">In an ever evolving financial landscape, it is important to stay up-to-date and enhance your knowledge. At FINSAI, we not only encourage continuous learning and growth among our employees but also extend our commitment to our valued customers.</p>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-4 col-md-5 col-sm-6 offset-lg-3 offset-md-2">
                                <div className="emabaracing">
                                    <h5 className="font_20 font_600 mb-3 blue_00 text-center">Customer-Centric Approach</h5>
                                    <p className="mb-0 font_16 font_400 gray_79 text-center">An important facet of our corporate ethos involves our unwavering commitment to our customers. We appreciate team members who have a customer-centric approach in all their endeavors. We also highly value them for actively contributing to establishing and nurturing customer relationships founded on trust and contentment.</p>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                                <div className="emabaracing">
                                    <h5 className="font_20 font_600 mb-3 blue_00 text-center">Team Spirit</h5>
                                    <p className="mb-0 font_16 font_400 gray_79 text-center">We believe team collaboration is key to achieving business success. We work together with our diverse array of skills and viewpoints to generate efficient and innovative solutions tailored to meet our customers' needs. Promoting open communication and teamwork can do wonders!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="awaits mb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12 pe-sm-5">
                                <h2 className="font_54 font_700">Join the FINSAI team!</h2>
                                <p className="font_18 font_500">We invite you to join our dynamic team and upgrade your career path. As we set ambitious standards, collaborate with industry experts, and pave the way for exceptional accomplishments in the world of finance, your contribution is vital to us. </p>
                                <p className="font_18 font_500">Fill out the form and submit your CV today for the role that resonates with your passion. If your qualifications align with the position, we look forward to connecting with you in the near future</p>
                                <p className="font_34 font_500 blue_00">Your Career Awaits at FINSAI!</p>
                            </div>
                            <div className="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                <form className="career_form" onSubmit={handleSubmit(onSubmit)}>
                                    <label className="font_18 font_400 mb-1">Personal Details</label>
                                    <div className="row">
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <div className="custom-modal mb-3 p-0">
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Full Name*" {...register('full_name')} />
                                                <div className="invalid-feedback">{errors.full_name?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <div className="custom-modal mb-3 p-0">
                                                <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="LinkedIn Profile" {...register('linkedin')} />
                                                <div className="invalid-feedback">{errors.linkedin?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="career_form_modal custom-modal p-0 input-group mb-0 gap-3">
                                                {/* <span className="input-group-text" id="basic-addon1">
                                                    <img src="assets/images/icon/flag-for-australia.svg" alt="" />
                                                </span> */}
                                                {/* <span className="input-group-text" id="basic-addon1">
                                            
                                            {countryList[0] ? countryList[0].find((item) => item.dial_code === countryCode)?.emojiU : null}
                                        </span> */}
                                                <span className="input-group-text" id="basic-addon1" style={{ "fontSize": "1.8rem" }}>
                                                    {countryList[0] ? (

                                                        convertUnicodeToEmoji(countryList[0].find((item) => item.dial_code === countryCode)?.emojiU)
                                                        // <img src={convertUnicodeToEmoji(countryList[0].find((item) => item.dial_code === countryCode)?.emojiU)} alt="" />
                                                    ) : null}
                                                </span>
                                                <div className="d-flex flex-1 select-code">
                                                    {/* <select className="form-select" aria-label="Default select example" {...register('country_code')}> */}
                                                    <select className="form-select" aria-label="Default select example" name="country_code" onChange={(event) => handleCountryCode(event)}>
                                                        <option value="+44">+44</option>

                                                        {countryList[0] ? countryList[0].map((item, key) => {
                                                            return (
                                                                <option value={item.dial_code} key={item.id}>{item.dial_code}</option>
                                                            )
                                                        }) : null}
                                                    </select>

                                                    <input type="text" className="form-control border-0 ps-0" placeholder="Phone Number" aria-label="Phone Number" aria-describedby="basic-addon2" {...register('mobile_number')} />
                                                </div>
                                                {/* <div className="invalid-feedback">{errors.country_code?.message}</div> */}
                                            </div>
                                            <div className="invalid-feedback mb-3">{errors.mobile_number?.message}</div>
                                        </div>
                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="custom-modal mb-4 p-0">
                                                <input type="text" className="form-control" id="exampleFormControlInput3" placeholder="Email*" {...register('email')} />
                                                <div className="invalid-feedback">{errors.email?.message}</div>


                                            </div>
                                        </div>
                                        <label className="font_18 font_400 mb-1">Applying for the position of...</label>
                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="custom-modal mb-3 p-0">
                                                <input type="text" className="form-control" id="exampleFormControlInput4" placeholder="Write here ..." {...register('position')} />
                                                <div className="invalid-feedback" >{errors.position?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="input--file">
                                                <img src="assets/images/icon-park-outline_add.svg" className="img-fluid" alt="add" />
                                                <input type="file" {...register('CV')} onChange={(event) => changeHandler(event, 'CV')} />
                                                {selectedFiles.CV ?
                                                    <p className="text-center" style={{ color: "#03519f", wordBreak: "break-word" }}>{selectedFiles.CV.name}</p>
                                                    : <p className="text-center" style={{ color: "#03519f" }}>No file chosen</p>
                                                }
                                                {/* <span onClick={() => removeFile('CV')} className="remove-file">
                                                    &#10006;
                                                </span> */}
                                            </div>
                                            {/* {selectedFiles.CV ?
                                                <p className="text-center" style={{ color: "#03519f", wordBreak: "break-word" }}>{selectedFiles.CV.name}</p>
                                                : <p className="text-center" style={{ color: "#03519f" }}>No file chosen</p>
                                            } */}


                                            {/* <p className="text-center" style={{ color: "#03519f" }}>{
                                                selectedFiles.CV.name
                                                    ? selectedFiles.CV.name
                                                    : selectedFiles.CV.name === undefined
                                                        ? "No file chosen"
                                                        : "No file chosen"}
                                            </p> */}

                                            <h6 className="text-center font_1 font_400">Upload you CV here*</h6>
                                            <p className="mb-5 font_12 font_400 gray_79 text-center">(Docs or PDf file only <br />
                                                File size limit 4 MB)</p>
                                            <div className="invalid-feedback mb-2 text-center" >{errors.CV?.message} </div>
                                        </div>
                                        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="input--file">
                                                <img src="assets/images/icon-park-outline_add.svg" className="img-fluid" alt="add" />
                                                <input type="file" {...register('cover_letter')} onChange={(event) => changeHandler(event, 'cover_letter')} />
                                                {selectedFiles.cover_letter ?
                                                    <p className="text-center" style={{ color: "#03519f", wordBreak: "break-word" }}>{selectedFiles.cover_letter.name}</p>
                                                    : <p className="text-center" style={{ color: "#03519f" }}>No file chosen</p>
                                                }

                                            </div>

                                            {/* <p className="text-center" style={{ color: "#03519f" }}>{
                                                selectedFiles.cover_letter.name
                                                    ? selectedFiles.cover_letter.name
                                                    : selectedFiles.cover_letter.name === undefined
                                                        ? "No file chosen"
                                                        : "No file chosen"}
                                            </p> */}
                                            <h6 className="text-center font_1 font_400">Upload you Cover letter</h6>
                                            <p className="mb-5 font_12 font_400 gray_79 text-center">(Docs or PDf file only <br />
                                                File size limit 4 MB)</p>
                                            <div className="invalid-feedback mb-2 text-center" >{errors.cover_letter?.message}</div>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <button type="submit" onClick={() => setScroll(false)} className="get_width_content get-started font_18 font_600 d-inline-block submit-app-btn" >Submit Application</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </main >
            <Model />
            <HelmetProvider>
                <Helmet>
                    <script>
                        {
                            `

                            //text height
                            var highestBox = 0;
                            $('.prevent-card .text ').each(function () {
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.prevent-card .text ').height(highestBox);
                            //text height
                            var highestBox = 0;
                            $('.training_box .title ').each(function () {
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.training_box .title ').height(highestBox);
                            
                        
                            
                            var image = document.querySelector('#image');
                            
                            function changeImage() {
                                image.src = 'assets/images/phone-relative.png';
                                console.log("click");
                            }
                    `
                        }
                    </script>
                </Helmet>
            </HelmetProvider>
        </>
    )
}
export default Careers