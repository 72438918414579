import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Slider from "react-slick";
import Model from "../components/model/model";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom'
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const InternationalTransfer = () => {
    const [scroll, setScroll] = useState(true)

    var settings = {

        centerMode: true,
        centerPadding: '250px',
        slidesToShow: 1,
        dots: true,
        // dots: true,
        infinite: true,
        arrows: false,
        responsive: [{
            breakpoint: 1440,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '170px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1025,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '120px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 914,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '120px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '20px',
                slidesToShow: 1
            }
        }
        ]

    };

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="sec-pad">
                    <div className="container">
                        <div className="int-banner-sec">

                            <div className="row">
                                <div className="col-md-8 col-lg-7 col-xl-6 col-12">
                                    <p className="font_30  font_600">Go The Global Way!</p>
                                    <p className="font_700 font_54 pt-3">International Money Transfers</p>
                                    <p className="font_18 font_500 text">Experience the advantages of competitive rates, transparent
                                        transactions, and simple
                                        tap-to-send convenience with FINSAI. Send money internationally in more than 20
                                        currencies.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="sec-pad bg-white">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-9 col-xxl-8 col-12">



                                <div className="text-center">
                                    <p className="font_22 text-darkgray">Global Payments and much more</p>
                                    <p className="font_30 font_700 "> Simple and Efficient International Money Transfers</p>
                                </div>
                                <div className="row justify-content-center sec-pad">
                                    <div className="col-xl-9 col-lg-9 col-md-10">
                                        <div className="money-trans-card">
                                            <div className="row justify-content-end">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="text">
                                                        <p className="font_20 font_600 mb-2">Transfer funds to your loved ones</p>
                                                        <p className="gray_65 mb-0 font_16">Support your family and loved ones with ease
                                                            with our
                                                            international money
                                                            transfer
                                                            solutions.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-xl-9 col-lg-9 col-md-10">
                                        <div className="money-trans-card card-2">
                                            <div className="row justify-content-end">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="text">
                                                        <p className="font_20 font_600 mb-2">Manage your bills from anywhere</p>
                                                        <p className="gray_65 mb-0 font_16">Pay your bills and move funds around the world
                                                            at
                                                            your
                                                            own convenience within a few steps.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-xl-9 col-lg-9 col-md-10">
                                        <div className="money-trans-card card-3">
                                            <div className="row justify-content-end">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="text">
                                                        <p className="font_20 font_600 mb-2">Multiple money transfer options</p>
                                                        <p className="gray_65 mb-0 font_16">Experience the ease of sending money via bank
                                                            transfers,
                                                            card transfers, or within the FINSAI platform.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="currency-banner d-none">
                    <div className="container">

                        <div className="row justify-content-center">
                            <p className="font_30 font_700 text-center"> Send Money to the UK and Europe in more than 20 Currencies
                            </p>
                            <div className="col-lg-7">
                                <p className="font_22 gray_65 text-center mb-0">Easily transfer money to the UK and Europe using
                                    over 20
                                    currencies,
                                    including
                                    the GBP, Euro,
                                    Rand, Zloty and many more with seamless and secure transactions</p>
                            </div>
                            <div className="col-xxl-11 col-xl-12 col-lg-12 col-12">
                                <div className="currency-flags d-flex flex-wrap gap-5">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-1.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-2.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-3.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-4.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-5.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-6.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-7.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-8.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-9.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-10.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-11.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-12.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-13.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-14.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-15.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-16.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-17.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-18.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-19.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-20.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="features-sec">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="feature-card">
                                    <p className="font_64 text-white text-center font_700 mb-sm-5">Features</p>
                                    <div className="row">
                                        <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10">
                                            <div className="feature-sub-card">
                                                <p className="font_22 font_500 text-black">Competitive Rate</p>
                                                <p className="font_18 gray_65 mb-0">Make every penny count with FINSAI's competitive
                                                    exchange
                                                    rates and maximize the
                                                    value of your money with each international transfer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10">
                                            <div className="feature-sub-card">
                                                <p className="font_22 font_500 text-black">No Hidden Charges</p>
                                                <p className="font_18 gray_65 mb-0">Conduct transactions in the most transparent way with
                                                    FINSAI.
                                                    With no hidden charges, we ensure to provide you with an easy and seamless
                                                    experience that meets all your unique needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10">
                                            <div className="feature-sub-card">
                                                <p className="font_22 font_500 text-black">Tap-Send-It’s Easy</p>
                                                <p className="font_18 gray_65 mb-0">Experience the ultimate level of convenience with
                                                    transferring money on FINSAI. In just a simple step, you can send money across the
                                                    globe with super ease and efficiency</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="across-network d-none">
                    <div className="container">
                        <p className="font_22 gray_65 text-center"> Bridging Across Countries</p>
                        <p className="font_30 font_700 text-center"> Engage with our interconnected routes network</p>
                        <div className="across-network-card">
                            <p className="font_18 font_600">UK</p>
                            <div className="row">
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-1.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-2.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-3.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-4.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-5.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-6.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-7.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-8.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-9.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-10.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-11.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-12.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-13.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-14.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-15.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-16.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-17.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-18.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-19.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-20.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-22.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-23.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-24.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-25.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-26.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-27.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-28.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-29.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-30.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-31.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-32.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-33.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-34.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-35.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-36.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-37.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-38.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-39.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-40.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-41.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-42.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-43.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="solution-card">
                            <p className="font_22 text-center">International Transfers made simple</p>
                            <p className="font_30 font_700 text-center mb-sm-5">Discover our multiple transfer solutions</p>
                            <div className="testimonial-area">
                                <div className="center banner-slider">
                                    <Slider {...settings}>
                                        <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                            <div className="single-testi">
                                                <div className="banner-card">
                                                    <div>
                                                        <p className="font_34 font_700">FINSAI Transfers</p>
                                                        <p className="font_22 gray_65 font_500 mb-0">Transfer money with ease<br /> to
                                                            your
                                                            family
                                                            and
                                                            friends<br /> within FINSAI<br />
                                                            infrastructure.</p>
                                                    </div>
                                                    <div className="img">
                                                        <img src="assets/images/slider-1.svg" alt="finsai-transfers" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                            <div className="single-testi">
                                                <div className="banner-card">
                                                    <div>
                                                        <p className="font_34 font_700">Bank Transfers</p>
                                                        <p className="font_22 gray_65 font_500 mb-0">Embark on the journey of<br />
                                                            seamless
                                                            international<br /> money transfers with<br /> FINSAI.</p>
                                                    </div>
                                                    <div className="img">
                                                        <img src="assets/images/bank-transfer.svg" alt="finsai-transfers" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                            <div className="single-testi">
                                                <div className="banner-card">
                                                    <div>
                                                        <p className="font_34 font_700">Card Transfers</p>
                                                        <p className="font_22 gray_65 font_500 mb-0">Make international<br /> transfers
                                                            the
                                                            swift
                                                            way<br /> with just card details to<br /> most countries and currencies.</p>
                                                    </div>
                                                    <div className="img">
                                                        <img src="assets/images/card-transfer.svg" alt="finsai-transfers" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                            <div className="single-testi">
                                                <div className="banner-card">
                                                    <div>
                                                        <p className="font_34 font_700">Digital Wallets</p>
                                                        <p className="font_22 gray_65 font_500 mb-0">Store cards, make<br /> purchases,
                                                            and
                                                            manage<br />
                                                            transactions all in one<br /> convenient place.</p>
                                                    </div>
                                                    <div className="img">
                                                        <img src="assets/images/digital-wallets.svg" alt="finsai-transfers" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="finanace-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <p className="font_40 text-white font_700">Hassle Free Global Funds Transfer Your Finances, Your Way
                                </p>
                                <Link to="/select-country" target="_blank" className="sign_gradient_btn font_18 font_600 mb-5"> Discover FINSAI
                                    now</Link>
                                <div className="text-center d-sm-flex align-items-center justify-content-center">
                                    <Link to="/select-country" target="_blank" className="d-block img-fluid">
                                        <img src="assets/images/play-store.svg"
                                            className="me-sm-3 mb-3 mb-sm-0 img-fluid icon-playstore" alt="play store" />
                                    </Link>
                                    <Link to="/select-country" target="_blank" className="d-block img-fluid">
                                        <img src="assets/images/app-store.svg" className="img-fluid icon-playstore" alt="app store" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section className="sec-pad mb_78">
                    <div className="container">
                        <div className="row">
                            <div className="co-12">
                                <div className="finsai-started">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-8">
                                            <p className="font_30 font_700">Open your account with FINSAI today</p>
                                            <p className="font_25 ">Experience seamless sending and swift spending at its best .</p>
                                            <Link to="/select-country" target="_blank" className="sign_gradient_btn font_18 font_600 mt-4"> Get
                                                Started now</Link>
                                        </div>
                                        <div className="col-lg-4 col-md-4 ">
                                            <div className="lock-key">
                                                <img src="assets/images/lock-key.png" alt="lock-key" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-pad">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-120 flex-wrap">
                            <div>
                                <p className="font_64 font_700">Pricing plans</p>
                                <p className="font_20 gray_52" />Discover more, experience better – choose your plan and unlock a
                                world<br /> tailored to you.
                            </div>
                            <div>
                                <Link to="/select-country" target="_blank" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Lite <br />
                                            £ 0.00<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Start your journey with confidence toward meeting your
                                            financial goals. Experience optimized financial transactions and custom-made
                                            solutions for your needs.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--1.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Smart
                                            <br />
                                            £ 4.99<span className="font_14 font_600">/Month</span>

                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Conveniently handle your day-to-day finances with our
                                            Smart
                                            Plan.Unlock a world of convenient money management and personalized perks. Grab your
                                            key to a smarter way of handling your finances.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--2.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Premium
                                            <br /> £ 9.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                      
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Experience the epitome of luxury with our Premium
                                            Debit
                                            Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                            that redefine your financial journey.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--3.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <div className="d-flex mb-3 gap-2">
                                            <img src="assets/images/icon/logo-card.png" alt="logo" />
                                            <p className="vip-tag">VIP</p>
                                        </div>
                                        <p className="font_34 font_600 text-blue  lh-sm">Metal
                                            <br /> £ 14.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Indulge yourself in the ultimate experience with our
                                            Metal
                                            Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                            rewards, top-tier security, and an aura of exclusivity.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--4.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <Footer />
            </main>
            <Model />
            <HelmetProvider>
                <Helmet>
                    <script>
                        {
                            `
                            ////text height////
                            var highestBox = 0;
                            $('.card-plan .text').each(function () {
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.card-plan .text').height(highestBox);
                    `
                        }
                    </script>
                </Helmet>
            </HelmetProvider>
        </>
    )
}

export default InternationalTransfer