import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Slider from "react-slick";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../components/scroll_top";
import Model from "../components/model/model";
import Swal from 'sweetalert2'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendMailSubscription } from "../api/apiHandler";

const Index = () => {

    const [imageSrc, setImageSrc] = useState("../../public/assets/images/icon/v-slider--2.png");
    function changeImage() {
        setImageSrc("../../assets/images/phone-relative.png");
        // console.log("click");
    }

    var settings = {

        dots: false,
        infinite: true,
        arrows: false,
        // speed: 300,

        // slidesToScroll: 3,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 8000,
        pauseOnHover: false,
        vertical: true,
        cssEase: 'linear',

        responsive: [{
            breakpoint: 1440,
            settings: {
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },

        {
            breakpoint: 992,
            settings: {
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        ],

    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        var mailData = {
            "email": data.email
        }
        sendMailSubscription(mailData).then((res) => {

            var response = JSON.parse(res)
            // console.log(response)

            if (response.code === 200) {

                Swal.fire({
                    position: 'top',
                    icon: 'success',
                    title: "You are Subscribed Now",
                    toast: true,
                    showConfirmButton: false,
                    timer: 4000
                })
                // setTimeout(() => {
                //     window.location.reload();
                //     window.scrollTo({
                //         top: 0,
                //         behavior: 'instant'
                //     });
                // }, 4000);
            }
            else {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })



        // var mailData = {
        //     "email": data.email,
        //     "url": "https//abc"
        // }

        // sendMail(mailData).then((res) => {
        //     var response = JSON.parse(res)

        //     if (response.code === 200) {
        //         navigate("/");
        //     }
        //     else {
        //         Swal.fire({
        //             position: 'top',
        //             icon: 'error',
        //             title: response.message,
        //             toast: true,
        //             showConfirmButton: false,
        //             timer: 2000
        //         })
        //     }
        // })

    }

    return (
        <>
            {/* <ScrollToTop /> */}
            <HelmetProvider>
                <Helmet>
                    <script>
                        {
                            `
                            var highestBox = 0;
                            $('.prevent-card .text ').each(function () {
                                if ($(this).height() > highestBox) {
                                    highestBox = $(this).height();
                                }
                            });
                            $('.prevent-card .text ').height(highestBox);
                    //match height
                    
                    /*
                    // vertical slider
                    jQuery(($) => {
                        // grab elememts
                        let $wrapper = $('.card-wrapper')
                        let $list = $wrapper.find('.card-list')
                        let $clonedList = $list.clone()
                        let cardLength = 0
                    
                        // set an offset of 4 pixels
                        let listHeight = 4
                    
                        // add the height of all cards
                        $list.find('.scroll-card').each((_, el) => {
                            cardLength++
                    
                            listHeight += ['height', 'margin-top', 'margin-bottom']
                                .map((key) =>
                                    parseInt(window.getComputedStyle(el).getPropertyValue(key), 10)
                                )
                                .reduce((prev, cur) => prev + cur)
                        })
                    
                        // attach the calculation of the height as a style
                        $list.add($clonedList).css({
                            height: listHeight + 'px'
                        })
                    
                        $clonedList.addClass('cloned').appendTo($wrapper)
                        // execute animations
                        let infinite = new TimelineMax({
                            repeat: -1,
                            paused: true
                        })
                        let time = cardLength * 3
                    
                        // Zoom active
                        function zoomMiddle(list) {
                            const tl = gsap.timeline({
                                repeat: -1
                            });
                            tl
                                // .to(list, {
                                .to(".scroll-card", {
                                    // scale: 1.4,
                                    ease: CustomEase.create(
                                        "custom",
                                        "M0,0 C0,0.45 0.2,1 0.362,1 0.43,1 0.552,1 0.632,1 0.836,1 1,0.436 1,0"
                                    ), // Custom ease that scales and moves down again (add https://s3-us-west-2.amazonaws.com/s.cdpn.io/16327/CustomEase3.min.js)
                                    stagger: {
                                        amount: time * 2, // Should be synced with the amount it takes to to move up
                                        // from: 2 // Start from the second item, but than moves up and down 
                                    }
                                })
                            return tl;
                        }
                    
                        // Main timeline
                        infinite
                            .fromTo(
                                $list,
                                time, {
                                    y: 0
                                }, {
                                    y: '-' + listHeight,
                                    ease: Linear.easeNone
                                },
                                0
                            )
                            .fromTo(
                                $clonedList,
                                time, {
                                    y: listHeight
                                }, {
                                    y: 0,
                                    ease: Linear.easeNone
                                },
                                0
                            )
                            // .add(zoomMiddle($(".scroll-card", $list)), 0)
                            .add(zoomMiddle, 0)
                            .play()
                    
                        $wrapper
                            .on('mouseenter', () => infinite.pause())
                            .on('mouseleave', () => infinite.play())
                    })
                    */
                    //text height
                
                    
                   
                    
                    var image = document.querySelector('#image');
                    
                    function changeImage() {
                        image.src = 'assets/images/phone-relative.png';
                        console.log("click");
                    }

                    `
                        }
                    </script>
                </Helmet>
            </HelmetProvider>

            <Header />
            <section className="sec-pad">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-xl-7 col-md-6 col-12">
                            <p className="font_64 font_700 mb-0 home_title">One Platform,<br />
                                Endless Possibilities</p>
                            <p className="font_24 font_500 mb-5">Instant, Secure and Effortless</p>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6 col-xl-5 col-12">
                                        <div className="d-flex gap-3 mb-3">
                                            <img src="assets/images/icon/check-icon.png" className="object-fit-contain" alt="check-icon" />
                                            <p className="font_18 font_500 flex-1 mb-0 flex-1">Multi-Currency IBANs</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-5 col-12">
                                        <div className="d-flex gap-3 mb-3">
                                            <img src="assets/images/icon/check-icon.png" className="object-fit-contain" alt="check-icon" />
                                            <p className="font_18 font_500 flex-1 mb-0 flex-1">Seamless Transactions</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-5 col-12">
                                        <div className="d-flex gap-3 mb-3">
                                            <img src="assets/images/icon/check-icon.png" className="object-fit-contain" alt="check-icon" />
                                            <p className="font_18 font_500 flex-1 mb-0 flex-1">160+ Local Payment Options</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-5 col-12">
                                        <div className="d-flex gap-3 mb-3">
                                            <img src="assets/images/icon/check-icon.png" className="object-fit-contain" alt="check-icon" />
                                            <p className="font_18 font_500 flex-1 mb-0 flex-1">20+ FX Currencies</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex gap-4 mt-4 mb-3 flex-wrap">
                                    <div>
                                        <Link to="/select-country" target="_blank" className="get-started font_16 font_600">Get Started</Link>
                                    </div>
                                    <div className="d-flex gap-4">
                                        <div className="app-store-img">
                                            <Link to="/select-country" target="_blank"> <img src="assets/images/app-store.png"
                                                alt="app-store" /> </Link>
                                        </div>
                                        <div className="app-store-img">
                                            <Link to="/select-country" target="_blank"> <img src="assets/images/gpay.png" alt="gpay" /></Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-5 col-md-6 col-12">
                            <div className="position-relative d-flex justify-content-center">
                                <div className="phone-relative-img">
                                    <div className="relative-images">
                                        <img src="assets/images/phone-relative.svg" className="phone--img" alt="" />
                                        <div className="flag-card text-start">
                                            <img src="assets/images/uk-flag.png" alt="" />
                                            <p className="text font_12 font_300 mb-0">€1.16 =
                                                <span className="font_500"> £1</span><br />
                                                GBP Account
                                            </p>
                                        </div>
                                        <div className="card-info">
                                            <div className="w-100 d-flex justify-content-between align-items-center">
                                                <div className="d-flex gap-2">
                                                    <img src="assets/images/icon/arrow-green.png" alt="" />
                                                    <div className="flex-1 text-start">
                                                        <p className="font_12 mb-0 text-gray mb-0">Received from</p>
                                                        <p className="font_14 mb-0 font_600">Kristin Watson</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="mb-0 text-green font_14 font_600">£30.50</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-shop">
                                            <img src="assets/images/icon/shopping-bag.png" alt="" />
                                            <p className="mb-0 font_14 font_500">Shopping<br /><span
                                                className="text-gray">-£50.50</span>
                                            </p>
                                        </div>
                                        <div className="shine-card">
                                            <img src="assets/images/card--1.png" alt="" />
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="sec-pad mb-120">
                <div className="container">
                    <div className="sec-body overflow-hidden">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-8 col-xl-8 col-10">
                                <p className="font_64 font_700 text_lightblue text-center">FINSAI Money</p>
                                <p className="font_14 text_lightblue lh-base text-center">Experience the difference with
                                    FINSAI's advanced digital payment services. Our services are designed to help you
                                    efficiently manage your finances, all at your fingertips. Welcome to a new era of
                                    Financial Freedom!</p>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-md-6 col-12">
                                <div className="circle-animate ">
                                    <div className="beside-phone-img">
                                        <img id="image" src="assets/images/v-beside-phone.svg" alt="v-beside-phone" />
                                    </div>
                                    {/* <!-- <div className="circle"></div> --> */}
                                    <div className="bg-circledashed"></div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-5 col-xxl-4 col-md-6 col-10">
                                <div className="shadow-pattern"></div>
                                <div className="testimonial-area">
                                    <div className="testimonial-slider-active justify-content-center ">
                                        <Slider {...settings}>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--1.png" alt="v-slider--1" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Multi-Currency IBANS in 25 Currencies
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        Explore opportunities with our Multi-Currency solution
                                                                        at FINSAI. Usage of IBAN to operate globally in more
                                                                        than 20 currencies. Manage your payments in AUD, CNY,
                                                                        and many more. Enjoy seamless payments in USD, GBP, or
                                                                        EUR.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--2.png" alt="v-slider--2" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Send & Receive Money Globally
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        Facilitate fast transfers globally today. We give you
                                                                        the flexibility to transfer money instantly and securely
                                                                        with our competitive exchange rates. Make real-time
                                                                        transactions quickly and gain access to diverse
                                                                        markets.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--3.png" alt="v-slider--3" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Effortless FX transfers
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        Unlock unlimited possibilities with our Foreign Exchange
                                                                        service. Learn about our diverse foreign currencies
                                                                        while conveniently monitoring real-time currency
                                                                        conversions at competitive rates, all in one place.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--4.png" alt="v-slider--4" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Virtual & Physical Cards
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        Achieve boundless financial flexibility through our
                                                                        Virtual and Prepaid Debit Cards in more than 20
                                                                        currencies. Access your funds, simplifying your
                                                                        purchases, withdrawals, and online money transfers with
                                                                        ease and security.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--4.png" alt="v-slider--4" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Local and Domestic Transfers
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        Save valuable time through our FINSAI Local and Domestic
                                                                        Transfer services. Move money within the UK and Europe
                                                                        using local rails in more than 150 countries. Enjoy the
                                                                        flexibility of ACH, BACS, and CHAPS payment options for
                                                                        secure transactions.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--5.png" alt="v-slider--5" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Streamlined Accounts
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        Boost your account management experience with our
                                                                        streamlined accounts. Easily switching between, viewing,
                                                                        and accessing multiple accounts, all within a matter of
                                                                        minutes. Take control of your financial journey, one
                                                                        account at a time.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--6.png" alt="v-slider--6" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Currency Exchange at its best!
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        FINSAI ensures you can make swift and efficient
                                                                        transactions, making your experience easier than ever.
                                                                        Our wide range of currencies allows you to exchange your
                                                                        currencies anywhere, anytime.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-testi">
                                                    <div className="scrollingCard ">
                                                        <div className="top ">
                                                            <div className="wrapper" onClick={changeImage}>
                                                                <div><img src="assets/images/icon/v-slider--6.png" alt="v-slider--6" /></div>
                                                                <div className="info">
                                                                    <p className="lead font_20 font_500 text-white">
                                                                        Instant Crypto to Fiat Conversions
                                                                    </p>
                                                                    <p className=" font_14 text-white mb-0 font_400">
                                                                        Manage your finances with ease with our instant crypto
                                                                        to fiat conversion. Smoothly transform your crypto
                                                                        assets into traditional currencies, offering you the
                                                                        flexibility to navigate the ever changing financial
                                                                        world.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                                <div className="shadow-pattern-end"></div>
                                {/* <!-- <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                    <div className="single-testi">
                                        <div className="scrollingCard ">
                                            <div className="top ">
                                                <div className="wrapper" onClick="changeImage()">
                                                    <div><img src="assets/images/icon/v-slider--4.png"></div>
                                                    <div className="info">
                                                        <p className="lead font_20 font_500 text-white">
                                                            Multi-Currency IBANS in 25 Currencies
                                                        </p>
                                                        <p className=" font_14 text-white mb-0 font_400">
                                                            Odio euismod lacinia at quis. Amet purus gravida quis
                                                            blandit turpis.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> --> */}
                                {/* <!-- <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                    <div className="single-testi">
                                        <div className="scrollingCard ">
                                            <div className="top ">
                                                <div className="wrapper" onClick="changeImage()">
                                                    <div><img src="assets/images/icon/v-slider--4.png"></div>
                                                    <div className="info">
                                                        <p className="lead font_20 font_500 text-white">
                                                            Multi-Currency IBANS in 25 Currencies
                                                        </p>
                                                        <p className=" font_14 text-white mb-0 font_400">
                                                            Odio euismod lacinia at quis. Amet purus gravida quis
                                                            blandit turpis.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> --> */}
                                {/* <!-- <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                    <div className="single-testi">
                                        <div className="scrollingCard ">
                                            <div className="top ">
                                                <div className="wrapper" onClick="changeImage()">
                                                    <div><img src="assets/images/icon/v-slider--4.png"></div>
                                                    <div className="info">
                                                        <p className="lead font_20 font_500 text-white">
                                                            Multi-Currency IBANS in 25 Currencies
                                                        </p>
                                                        <p className=" font_14 text-white mb-0 font_400">
                                                            Odio euismod lacinia at quis. Amet purus gravida quis
                                                            blandit turpis.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> --> */}
                                {/* <!-- <div className="col-5 wow fadeInUp" data-wow-delay=".2s">
                                    <div className="single-testi">
                                        <div className="scrollingCard ">
                                            <div className="top ">
                                                <div className="wrapper" onClick="changeImage()">
                                                    <div><img src="assets/images/icon/v-slider--4.png"></div>
                                                    <div className="info">
                                                        <p className="lead font_20 font_500 text-white">
                                                            Multi-Currency IBANS in 25 Currencies
                                                        </p>
                                                        <p className=" font_14 text-white mb-0 font_400">
                                                            Odio euismod lacinia at quis. Amet purus gravida quis
                                                            blandit turpis.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> --> */}
                                {/* <!-- <div className="hero">


                                    <div className="card-wrapper ">
                                        <div className="card-list ">

                                            <div className="container scroll-card">
                                                <div className="scrollingCard">
                                                    <div className="top ">
                                                        <div className="wrapper" onClick="changeImage()">
                                                            <div><img src="assets/images/icon/v-slider--1.png"></div>
                                                            <div className="info">
                                                                <p className="lead font_20 font_500 text-white">
                                                                    Send & Receive Money Globally
                                                                </p>
                                                                <p className=" font_14 text-white mb-0 font_400">
                                                                    Odio euismod lacinia at quis. Amet purus gravida quis
                                                                    blandit turpis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="container scroll-card">
                                                <div className="scrollingCard">
                                                    <div className="top ">
                                                        <div className="wrapper" onClick="changeImage()">
                                                            <div><img src="assets/images/icon/v-slider--2.png"></div>
                                                            <div className="info">
                                                                <p className="lead font_20 font_500 text-white">
                                                                    Streamlined Accounts
                                                                </p>
                                                                <p className=" font_14 text-white mb-0 font_400">
                                                                    Odio euismod lacinia at quis. Amet purus gravida quis
                                                                    blandit turpis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="container scroll-card">
                                                <div className="scrollingCard">
                                                    <div className="top">
                                                        <div className="wrapper" onClick="changeImage()">
                                                            <div><img src="assets/images/icon/v-slider--3.png"></div>
                                                            <div className="info">
                                                                <p className="lead font_20 font_500 text-white">
                                                                    Digital Wallets
                                                                </p>
                                                                <p className=" font_14 text-white mb-0 font_400">
                                                                    Odio euismod lacinia at quis. Amet purus gravida quis
                                                                    blandit turpis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="container scroll-card ">
                                                <div className="scrollingCard ">
                                                    <div className="top ">
                                                        <div className="wrapper" onClick="changeImage()">
                                                            <div><img src="assets/images/icon/v-slider--4.png"></div>
                                                            <div className="info">
                                                                <p className="lead font_20 font_500 text-white">
                                                                    Multi-Currency IBANS in 25 Currencies
                                                                </p>
                                                                <p className=" font_14 text-white mb-0 font_400">
                                                                    Odio euismod lacinia at quis. Amet purus gravida quis
                                                                    blandit turpis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="container scroll-card">
                                                <div className="scrollingCard">
                                                    <div className="top">
                                                        <div className="wrapper" onClick="changeImage()">
                                                            <div><img src="assets/images/icon/v-slider--5.png"></div>
                                                            <div className="info">
                                                                <p className="lead font_20 font_500 text-white">
                                                                    Effortless FX transfers
                                                                </p>
                                                                <p className=" font_14 text-white mb-0 font_400">
                                                                    Odio euismod lacinia at quis. Amet purus gravida quis
                                                                    blandit turpis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="container scroll-card">
                                                <div className="scrollingCard">
                                                    <div className="top">
                                                        <div className="wrapper" onClick="changeImage()">
                                                            <div><img src="assets/images/icon/v-slider--1.png"></div>
                                                            <div className="info">
                                                                <p className="lead font_20 font_500 text-white">
                                                                    Virtual & Physical Cards
                                                                </p>
                                                                <p className=" font_14 text-white mb-0 font_400">
                                                                    Odio euismod lacinia at quis. Amet purus gravida quis
                                                                    blandit turpis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div> --> */}

                            </div>
                        </div>


                        <div className="slider scroll-logos">
                            <div className="slide-track">
                                <div className="slide">
                                    <img src="assets/images/icon/send-money.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Send money abroad</p>
                                </div>

                                <div className="slide">
                                    <img src="assets/images/icon/add-money-instant.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Add Money Instantly</p>
                                </div>
                                <div className="slide">
                                    <img src="assets/images/icon/support.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Supports International & Domestic Transfers
                                    </p>
                                </div>
                                <div className="slide">
                                    <img src="assets/images/advertise-icon.png" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Digital Wallets</p>
                                </div>

                                <div className="slide">
                                    <img src="assets/images/icon/personal-business.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Personal & Business Accounts</p>
                                </div>
                                <div className="slide">
                                    <img src="assets/images/icon/send-money.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Send money abroad</p>
                                </div>

                                <div className="slide">
                                    <img src="assets/images/icon/add-money-instant.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Add Money Instantly</p>
                                </div>
                                <div className="slide">
                                    <img src="assets/images/icon/support.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Supports International & Domestic Transfers
                                    </p>
                                </div>
                                <div className="slide">
                                    <img src="assets/images/advertise-icon.png" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Digital Wallets</p>
                                </div>

                                <div className="slide">
                                    <img src="assets/images/icon/personal-business.svg" className="object-fit-contain" alt="" />
                                    <p className="font_20 font_600 flex-1">Personal & Business Accounts</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- </div> --> */}
            </section >
            <section className="mb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6 col-md-6 col-12">
                            <div className="crypto-img">
                                <img src="assets/images/Crypto_to_Fiat-1.png" alt="crypto-flat" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-6 col-12">
                            <div className="crypto-info">
                                <p className="title  font_500">Instant Crypto
                                    to Fiat Conversions</p>
                                <p className="text font_22 font_300">Manage your finances with ease with our instant crypto to
                                    fiat conversion.
                                    Smoothly
                                    transform your crypto assets into traditional currencies, offering you the flexibility
                                    to navigate the ever changing financial world.</p>

                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">

                        <div className="col-lg-6 col-xl-6 col-md-6 col-12 flex-order-2">
                            <div className="crypto-info">
                                <p className="title  font_500">Virtual and Debit Cards</p>
                                <p className="text font_22 font_300">With our Virtual and Prepaid Debit Cards available in more
                                    than 20 currencies, unlock your financial independence with just a tap. These cards
                                    enable you to instantly access your funds, simplify your purchases and withdrawals, and
                                    make online money transfers with ease and security.
                                    Making your business
                                    thrive around
                                    the clock!
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-6 col-12 flex-order-1">
                            <div className="crypto-img">
                                <img src="assets/images/credit-cards.png" alt="crypto-flat" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sec-pad d-none">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center mb-120 flex-wrap">
                        <div>
                            <p className="font_64 font_700">Pricing plans</p>
                            <p className="font_20 text-gray">Discover more, experience better – choose your plan and unlock a
                                world tailored to you.</p>
                        </div>
                        <div>
                            <Link to="" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 col-12">
                            <div className="card-plan">
                                <div className="w-330">
                                    <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo-card" />
                                    <p className="font_34 font_600 text-blue  lh-sm">Lite <br />
                                        {/* <!-- £ 0.00<span
                                        className="font_14 font_600">/Month</span> --> */}
                                    </p>
                                    {/* <!-- <p className="font_34 font_600 text-blue">£ 0.00<span className="font_14 font_600">/Month</span>
                                </p> --> */}
                                    <p className="text-gray font_14 text">Start your journey with confidence toward meeting your
                                        financial goals. Experience optimized financial transactions and custom-made
                                        solutions for your needs.</p>
                                    <div className="d-flex align-items-center gap-4 mt-4">
                                        <Link to="" className="std-btn font_16 font_600">Choose Standard</Link>
                                        <Link to="" className="text-blue font_600" href="">Learn more</Link>
                                    </div>
                                </div>
                                <div className="crcard-img">
                                    <img src="assets/images/plans-card--1.png" alt="plans-card--1" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-12">
                            <div className="card-plan">
                                <div className="w-330">
                                    <img src="assets/images/icon/logo-card.png" className="mb-3" alt="" />
                                    <p className="font_34 font_600 text-blue  lh-sm">Smart
                                        <br />
                                        {/* <!-- £ 4.99<span
                                        className="font_14 font_600">/Month</span> --> */}

                                    </p>
                                    {/* <!-- <p className="font_34 font_600 text-blue">£ 0.00<span className="font_14 font_600">/Month</span>
                                </p> --> */}
                                    <p className="text-gray font_14 text">Conveniently handle your day-to-day finances with our
                                        Smart
                                        Plan.Unlock a world of convenient money management and personalized perks. Grab your
                                        key to a smarter way of handling your finances.</p>
                                    <div className="d-flex align-items-center gap-4 mt-4">
                                        <Link to="" className="std-btn font_16 font_600">Choose Standard</Link>
                                        <Link className="text-blue font_600">Learn more</Link>
                                    </div>
                                </div>
                                <div className="crcard-img">
                                    <img src="assets/images/plans-card--2.png" alt="plans-card--2" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-12">
                            <div className="card-plan">
                                <div className="w-330">
                                    <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo-card" />
                                    <p className="font_34 font_600 text-blue  lh-sm">Premium
                                        {/* <!-- <br> £ 9.99<span
                                        className="font_14 font_600">/Month</span> --> */}
                                    </p>
                                    {/* <!-- <p className="font_34 font_600 text-blue">£ 0.00<span className="font_14 font_600">/Month</span>
                                </p> --> */}
                                    <p className="text-gray font_14 text">Experience the epitome of luxury with our Premium
                                        Debit
                                        Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                        that redefine your financial journey.</p>
                                    <div className="d-flex align-items-center gap-4 mt-4">
                                        <Link to="" className="std-btn font_16 font_600">Choose Standard</Link>
                                        <Link to="" className="text-blue font_600">Learn more</Link>
                                    </div>
                                </div>
                                <div className="crcard-img">
                                    <img src="assets/images/plans-card--3.png" alt="plans-card--3" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-12">
                            <div className="card-plan">
                                <div className="w-330">
                                    <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo-card" />
                                    <p className="font_34 font_600 text-blue  lh-sm">Metal
                                        {/* <!-- <br> £ 14.99<span
                                        className="font_14 font_600">/Month</span> --> */}
                                    </p>
                                    {/* <!-- <p className="font_34 font_600 text-blue">£ 0.00<span className="font_14 font_600">/Month</span>
                                </p> --> */}
                                    <p className="text-gray font_14 text">Indulge yourself in the ultimate experience with our
                                        Metal
                                        Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                        rewards, top-tier security, and an aura of exclusivity.</p>
                                    <div className="d-flex align-items-center gap-4 mt-4">
                                        <Link to="" className="std-btn font_16 font_600">Choose Standard</Link>
                                        <Link to="" className="text-blue font_600">Learn more</Link>
                                    </div>
                                </div>
                                <div className="crcard-img">
                                    <img src="assets/images/plans-card--4.png" alt="plans-card--4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-blue pb-250">
                <div className="container">
                    <div className="title-sec">
                        <p className="title">Our Hub</p>
                        <p className="font_64 font_700 text_lightblue text-center text">Your Portal to Tailored<br />
                            Financial Solutions</p>
                    </div>
                    {/* <!-- <div id="parent-circle">
                    <div className="circleRotate circle-rcard blue">
                        <div className=" ">
                            <div className="d-flex">
                                <img src="assets/images/trade-svg.png">
                                <p className="font_20 text_lightblue font_700">Finsai Trade</p>
                            </div>
                        </div>
                    </div>
                    <div className="circleRotate  circle-rcard pink"></div>
                    <div className="circleRotate  circle-rcard lime"></div>
                    <div className="circleRotate  circle-rcard orange"></div>
                    <div className="circleRotate  circle-rcard teal"></div>
                </div> --> */}
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-10 col-12">
                            <div className="circular-img">
                                <img src="assets/images/circular_image.png" alt="circular-img" />
                            </div>
                            <div className="position-set">
                                <div className="center-logo">
                                    <img src="assets/images/center-logo.png" alt="center-logo" />
                                </div>
                                <div className="d-block objects">
                                    <div id="object2">
                                        <div className="card-rotate">
                                            <div className="d-flex align-items-center gap-3 mb-2">
                                                <img src="assets/images/facial-money-1.png" alt="facial-money-1" />
                                                <p className="font_20 text_lightblue font_700 flex-1 mb-0">Finsai Money</p>
                                            </div>
                                            <p className="text-gray font_14 text-ellipse-4">Discover limitless financial
                                                possibilities.
                                                Designed for your convenience, security, and optimize efficiency.
                                            </p>
                                            <Link to="/select-country" target="_blank" className="d-block learn-btn font_14">Learn More</Link>

                                        </div>
                                    </div>
                                    <div id="object3">
                                        <div className="card-rotate">
                                            <div className="d-flex align-items-center gap-3 mb-2">
                                                <img src="assets/images/trade-svg.png" alt="trade-svg" />
                                                <p className="font_20 text_lightblue font_700 flex-1 mb-0">Finsai Trade</p>
                                            </div>
                                            <p className="text-gray font_14 text-ellipse-4">Trade swift, secure and smart. Start
                                                trading and
                                                make money the smart way.
                                            </p>
                                            <Link to="/select-country" target="_blank" className="d-block learn-btn purple font_14">Learn More</Link>

                                        </div>
                                    </div>
                                    <div id="object4">
                                        <div className="card-rotate">
                                            <div className="d-flex align-items-center gap-3 mb-2">
                                                <img src="assets/images/finsai-wealth-1.png" alt="finsai-wealth-1" />
                                                <p className="font_20 text_lightblue font_700 flex-1 mb-0">Finsai Invest</p>
                                            </div>
                                            <p className="text-gray font_14 text-ellipse-4">Make smart investments, get access
                                                to insights and
                                                unlock your path to financial prosperity.
                                            </p>
                                            <Link to="/select-country" target="_blank" className="d-block learn-btn pink font_14">Learn More</Link>

                                        </div>
                                    </div>
                                    <div id="object5">
                                        <div className="card-rotate">
                                            <div className="d-flex align-items-center gap-3 mb-2">
                                                <img src="assets/images/exchange-img-1.png" alt="exchange-img-1" />
                                                <p className="font_20 text_lightblue font_700 flex-1 mb-0 ">Finsai
                                                    Exchange</p>
                                            </div>
                                            <p className="text-gray font_14 text-ellipse-4">Experience hassle-free currency
                                                exchange, save
                                                valuable time and money with our user-friendly platform
                                            </p>
                                            <Link to="/select-country" target="_blank" className="d-block learn-btn purple font_14">Learn More</Link>

                                        </div>
                                    </div>
                                    <div id="object6">
                                        <div className="card-rotate">
                                            <div className="d-flex align-items-center gap-3 mb-2">
                                                <img src="assets/images/academy.png" alt="academy" />
                                                <p className="font_20 text_lightblue font_700 flex-1 mb-0">Finsai Academy</p>
                                            </div>
                                            <p className="text-gray font_14 text-ellipse-4">Access courses within our
                                                educational hub and
                                                expand your knowledge personally and professionally.
                                            </p>
                                            <Link to="/select-country" target="_blank" className="d-block learn-btn blue font_14">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sec-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                            <div className="prevent-card">
                                <div>
                                    <p className="title font_54">Protect yourself from Fraud</p>
                                    <p className="text font_16">We hold your security in the highest regard and consider it of
                                        the utmost importance. It is imperative to regularly monitor your account activity,
                                        use robust passwords, and refrain from disclosing crucial information. Our advanced
                                        safety features and real-time notifications assist you in staying a step ahead of
                                        potential scammers, allowing you to enjoy interrupted services.
                                    </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                    <div className="img-hacker">
                                        <img src="assets/images/Hacker-internet-1.png" alt="" />
                                    </div>
                                    {/* <!-- <div className="arrow-img">
                                    <img src="assets/images/icon/arrow-right.png" className="object-fit-contain">
                                </div> --> */}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                            <div className="prevent-card">
                                <div>
                                    <p className="title font_54">Safe and <br /> Secure</p>
                                    <p className="text font_16">Ensuring your financial security is our foremost commitment. As
                                        a company, we strictly adhere to the requirements established by the Financial
                                        Conduct Authority (FCA) and other relevant regulatory bodies. We not only fulfill
                                        but also try to exceed these requirements through our stringent auditing processes,
                                        offering complete security for our valued consumers and stakeholders.</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                    <div className="img-hacker">
                                        <img src="assets/images/safe-secure.png" alt="" />
                                    </div>
                                    {/* <!-- <div className="arrow-img">
                                    <img src="assets/images/icon/arrow-right.png" className="object-fit-contain">
                                </div> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-10 col-12">
                            <div className="newletter-card">
                                <div className="row">
                                    <div className="col-md-7 col-lg-6 col-xl-6 col-12">
                                        <div className="d-flex gap-3">
                                            <img src="assets/images/icon/gmail.svg" className="object-fit-contain height-40" alt="" />
                                            <div className="flex-1">
                                                <p className="font_24 font_700 mb-1">Subscribe to Finsai</p>
                                                <p className="text-gray mb-0 font_14">Subscribe and take the first step towards
                                                    smarter finances with FINSAI. We would love to stay connected and update
                                                    you about all the latest news and offers via email. If you ever wish to
                                                    opt-out, you can unsubscribe at any time by clicking the 'unsubscribe'
                                                    link.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-6 col-xl-6 col-12">
                                        <div className="d-flex align-items-center h-100">
                                            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                                                <div className="input-group bottom-border">
                                                    <input type="text" className="form-control bg-transparent border-0 font_24 ps-0 pb-3"
                                                        placeholder="name@example.com" aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2" {...register('email')} />
                                                    <span className="input-group-text bg-transparent border-0 pb-3"
                                                        id="basic-addon2">
                                                        {/* <img src="assets/images/icon/mail-icon.png"
                                                        className="height-40" alt="" /> */}
                                                        <button type="submit" className="image-button">
                                                            <img src="assets/images/icon/mail-icon.png" className="height-40" alt="" />
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />
            <Model />
            {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-modal">
                        <div className="modal-header border-0">
                            <h5 className="modal-title font_24 font_700 text-black" id="staticBackdropLabel">Get in touch</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">


                            </button>
                        </div>
                        <div className="modal-body border-0">
                            <div className="mb-3">
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Full Name" />

                            </div>
                            <div className="input-group mb-3 gap-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <img src="assets/images/icon/flag-for-australia.svg" alt="" />
                                </span>
                                <div className="d-flex select-code">
                                    <select className="form-select" aria-label="Default select example">
                                        <option value="1">+44</option>
                                        <option value="2">+91</option>
                                        <option value="3">+333</option>

                                    </select>
                                    <input type="text" className="form-control border-0" placeholder="Phone Number"
                                        aria-label="Phone Number" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                            <div className="mb-3">
                                <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="Email" />

                            </div>
                            <div className="mb-4">
                                <textarea className="form-control" placeholder="Write here ..." id="floatingTextarea2"
                                    style={{ "height": "100px" }}></textarea>
                            </div>

                        </div> */}

            {/* <!-- <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Understood</button> --> */}
            {/* <div className="row justify-content-center">
                            <div className="col-lg-7"> */}
            {/* <Link to="javascript:;" className="submit-btn font_18 font_600">Submit</Link> */}
            {/* <Link to="" className="submit-btn font_18 font_600">Submit</Link>

                            </div>
                        </div>


                    </div>
                </div>
            </div> */}

        </>
    )
}

export default Index