import axios from "axios";
import CryptoJS from 'crypto-js';
import { logOutRedirectCall } from '../pages/common/RedirectPathMange'

var key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_KEY);
var iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_IV);

const showMessage = (msg) => {
  // console.log(msg)
}
// console.log(CryptoJS.AES.encrypt(process.env.REACT_APP_API_KEY, key, { iv: iv }).toString())

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'api-key': CryptoJS.AES.encrypt(process.env.REACT_APP_API_KEY, key, { iv: iv }).toString(),
    'accept-language': 'en',
    'Content-Type': 'text/plain',
    "is_website": 1
  }
});

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  request.data = bodyEncryption(request.data, true)

  if (localStorage.getItem("token") !== undefined || localStorage.getItem("token") !== null) {
    request.headers['token'] = bodyEncryption(localStorage.getItem("token"))
  }
  // console.log("Final Config",request.data)
  return request;
});

axiosClient.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response.data);

    if (response.code !== process.env.REACT_APP_SUCCESS) {
      showMessage(response.message)
    }
    return response;
  },
  function (error) {
    let res = error.response;
    // console.log("Decrypt Data else response", res)
    if (res.status === process.env.REACT_APP_UNAUTHORIZED) {

      logOutRedirectCall()
    }
    else if (res.status == process.env.REACT_APP_VALIDATION_ERROR) {
      const response = bodyDecryption(res.data);
      return response
    }
    else if (res.status == process.env.REACT_APP_DUPLICATE_VALUE) {
      const response = bodyDecryption(res.data);
      return response
    }
    else {
      // console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }

  }
);

function bodyEncryption(request, isStringify) {
  // console.log("Encryption Request", request)

  var request = (isStringify) ? JSON.stringify(request) : request;
  var encrypted = CryptoJS.AES.encrypt(request, key, { iv: iv });
  return encrypted.toString();
}

function bodyDecryption(request) {
  var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
  // console.log("Decrypt Data", decrypted)

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export { axiosClient };