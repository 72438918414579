import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactUs } from "../../api/apiHandler";
import { useSelector } from "react-redux";
import { useState } from "react";


const Model = () => {
    const countryList = useSelector((state) => {
        return state.countryList;
    })
    const [countryCode, setCountryCode] = useState('+44')
    const handleCountryCode = (e) => {
        setCountryCode(e.target.value)
    }
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
            .matches(/^[^@]+@[^@]+\.[^@.]+$/, 'Email must contain at least one dot (.) after the domain'),
        mobile_number: Yup.string()
            .required('Mobile Number is required')
            .typeError("That doesn't look like a phone number")
            .matches(/^\d{8,15}$/, 'Mobile Number must be between 8 and 15 digits long')
            .test('is-not-invalid', 'Invalid phone number', (value) => {

                if (value && (value.includes('1234567890') || value.includes('0987654321') || value.includes('9876543210') || value.includes('0123456789'))) {
                    return false;
                }
                return true;
            }),
        description: Yup.string()
            .required('Description is required')
            .matches(/^(?!\s*$).+/, 'Please enter a valid description'),

        full_name: Yup.string()
            .required('Full Name is required')
            .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, 'Full name should be characters only in the format with a space in between'),


    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        // console.log(countryCode)
        const full_name = data.full_name;
        const nameParts = full_name.split(' ');

        const first_name = nameParts[0];
        const last_name = nameParts.slice(1).join(' ');
        // console.log(first_name)
        // console.log(last_name)

        var contactData = {
            "first_name": first_name,
            "last_name": last_name ? last_name : undefined,
            "email": data.email,
            "description": data.description,

        }

        contactUs(contactData).then((res) => {
            var response = JSON.parse(res)

            if (response.code === 200) {

                // navigate("/");
                Swal.fire({
                    position: 'top',
                    icon: 'success',
                    title: "Your mail has been sent. We will contact you soon",
                    toast: true,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.reload();
                }, 4000);


            }
            else {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }

    function convertUnicodeToEmoji(unicodeString) {
        if (!unicodeString) return '';

        const codePoints = unicodeString.split(' ');
        // console.log(parseInt('U+1F1EC', 16))

        const emojiCharacters = codePoints
            .filter((codePoint) => !isNaN(parseInt(codePoint.slice(2), 16)))
            .map((codePoint) => String.fromCodePoint(parseInt(codePoint.slice(2), 16)));

        // console.log(emojiCharacters)

        return emojiCharacters.join('');
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content custom-modal">
                            <div className="modal-header border-0">
                                <h5 className="modal-title font_24 font_700 text-black" id="staticBackdropLabel">Get in touch</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div className="modal-body border-0">
                                <div className="mb-3">
                                    <input type="text" {...register('full_name')} className="form-control" id="exampleFormControlInput1" placeholder="Full Name" />

                                    <div className="invalid-feedback">{errors.full_name?.message}</div>
                                </div>

                                <div className="input-group mb-0 gap-2 gap-sm-3">

                                    {/* <span className="input-group-text" id="basic-addon1">
                                            
                                            {countryList[0] ? countryList[0].find((item) => item.dial_code === countryCode)?.emojiU : null}
                                        </span> */}
                                    <span className="input-group-text" id="basic-addon1" style={{ "fontSize": "1.8rem" }}>
                                        {countryList[0] ? (

                                            convertUnicodeToEmoji(countryList[0].find((item) => item.dial_code === countryCode)?.emojiU)
                                            // <img src={convertUnicodeToEmoji(countryList[0].find((item) => item.dial_code === countryCode)?.emojiU)} alt="" />
                                        ) : null}
                                    </span>

                                    <div className="d-flex flex-1 select-code">
                                        {/* <select className="form-select" aria-label="Default select example" {...register('country_code')}> */}
                                        <select className="form-select" aria-label="Default select example" name="country_code" onChange={(event) => handleCountryCode(event)}>

                                            <option value="+44">+44</option>
                                            {countryList[0] ? countryList[0].map((item, key) => {
                                                return (
                                                    <option value={item.dial_code} key={item.id}>{item.dial_code}</option>
                                                )
                                            }) : null}
                                        </select>

                                        <input type="text" className="form-control ps-0 border-0" placeholder="Phone Number" aria-label="Phone Number" aria-describedby="basic-addon1" {...register('mobile_number')} />
                                    </div>
                                    {/* <div className="invalid-feedback">{errors.country_code?.message}</div> */}

                                </div>
                                <div className="invalid-feedback mb-3">{errors.mobile_number?.message}</div>
                                <div className="mb-3">
                                    <input type="text" {...register('email')} className="form-control" id="exampleFormControlInput2" placeholder="Email" />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                                <div className="mb-4">
                                    <textarea {...register('description')} className="form-control" placeholder="Write here ..." id="floatingTextarea2" style={{ "height": "100px" }}></textarea>
                                    <div className="invalid-feedback">{errors.description?.message}</div>
                                </div>

                            </div>

                            {/* <!-- <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Understood</button> --> */}
                            <div className="row justify-content-center">
                                <div className="col-lg-7">
                                    <button type="submit" style={{ "border": "none" }} className="submit-btn font_18 font_600">Submit</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
export default Model
