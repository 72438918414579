import { axiosClient } from "./apiClient";

export function getCountries() {
    return axiosClient.get('auth/country-listing');
}

export function sendMailWaitinglist(data) {
    return axiosClient.post('auth/send-email-waitinglist', data);
}

export function contactUs(data) {
    return axiosClient.post('auth/contact-us', data);
}

export function career(data) {
    return axiosClient.post('auth/user-career', data);
}
export function sendMailSubscription(data) {
    return axiosClient.post('auth/send-email-subscription', data);
}


// export function dashboard(data) {
//     return axiosClient.post('authentication/dashBoard', data);
// }