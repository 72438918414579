import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const AMLPolicyProcedure = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="terms-title-sec">
                    <div className="container">
                        <p className="title mb-0">AML/CTF Policy and Procedure</p>
                    </div>
                </section>
                <section className="terms-content-sec privacy-sec">
                    <div className="container-fluid">


                        <div className="row mb-4 ms-lg-3 me-lg-3">
                            <div className=" col-xl-4 col-lg-4 col-md-5">
                                <div className="nav custom-tabs rounded flex-column nav-pills me-lg-3" id="v-pills-tab"
                                    role="tablist" aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-tab1" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        <p className="num mb-0">1</p>
                                        <p className="mb-0 text">Company Policy </p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-2"
                                        type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        <p className="num mb-0">2</p>
                                        <p className="mb-0 text">Risk-Based Approach</p>

                                    </button>
                                    <button className="nav-link" id="v-pills-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-3"
                                        type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        <p className="num mb-0">3</p>
                                        <p className="mb-0 text">MLRO Role &
                                            Responsibilities</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-4"
                                        type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        <p className="num mb-0">4</p>
                                        <p className="mb-0 text">Training</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-5"
                                        type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                        <p className="num mb-0">5</p>
                                        <p className="mb-0 text">Sanctions &
                                            PEPs Screening</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab6" data-bs-toggle="pill" data-bs-target="#v-pills-6"
                                        type="button" role="tab" aria-controls="v-pills-6" aria-selected="false">
                                        <p className="num mb-0">6</p>
                                        <p className="mb-0 text">Registration</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab7" data-bs-toggle="pill" data-bs-target="#v-pills-7"
                                        type="button" role="tab" aria-controls="v-pills-7" aria-selected="false">
                                        <p className="num mb-0">7</p>
                                        <p className="mb-0 text">Full Due Diligence (FDD)</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab8" data-bs-toggle="pill" data-bs-target="#v-pills-8"
                                        type="button" role="tab" aria-controls="v-pills-8" aria-selected="false">
                                        <p className="num mb-0">8</p>
                                        <p className="mb-0 text">Enhanced Due Diligence
                                            (EDD)</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab9" data-bs-toggle="pill" data-bs-target="#v-pills-9"
                                        type="button" role="tab" aria-controls="v-pills-8" aria-selected="false">
                                        <p className="num mb-0">9</p>
                                        <p className="mb-0 text">Transaction Monitoring</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab10" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-10" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">10</p>
                                        <p className="mb-0 text">Controls Over the Wallet</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab11" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-11" type="button" role="tab" aria-controls="v-pills-11"
                                        aria-selected="false">
                                        <p className="num mb-0">11</p>
                                        <p className="mb-0 text">
                                            Suspicious Activity
                                            Reporting</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab12" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-12" type="button" role="tab" aria-controls="v-pills-12"
                                        aria-selected="false">
                                        <p className="num mb-0">12</p>
                                        <p className="mb-0 text">
                                            Record-Keeping</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab13" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-13" type="button" role="tab" aria-controls="v-pills-13"
                                        aria-selected="false">
                                        <p className="num mb-0">13</p>
                                        <p className="mb-0 text">
                                            Appendices</p>
                                    </button>


                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-7 bg-lightblue">

                                <div className="tab-content" id="v-pills-tabContent">


                                    <div className="tab-pane fade active show" id="v-pills-1" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-0 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 text-black font_700 pt-2">1. Company Policy </p>

                                                    <p className="text-height">Money Laundering is the process of disguising the
                                                        origin of the proceeds of crime. Terrorist financing provides funds for
                                                        terrorist activity. The use of products and services by money launderers
                                                        and terrorists exposes FINSAI Limited to significant criminal,
                                                        regulatory and reputational risk. </p>
                                                    <p className="text-height pb-0">This policy is designed to provide direction to
                                                        staff
                                                        on the approach and
                                                        management of Anti-Money Laundering (AML) and Counter-Terrorist
                                                        Financing (CTF) within FINSAI. This policy supports management’s
                                                        objective of mitigating the following risks:</p>
                                                    <ul className="ul-liststyle protection-policy">
                                                        <li>Money laundering;</li>
                                                        <li>Terrorist financing;</li>
                                                        <li>Sanctions;</li>
                                                        <li>Politically exposed persons (PEPs);</li>
                                                        <li>Legal and regulatory risk.</li>

                                                    </ul>
                                                    <p className="text-height">This policy applies to all individuals working at all
                                                        levels of FINSAI,
                                                        including senior managers, officers, directors, employees, consultants,
                                                        contractors, trainees, homeworkers, part-time and fixed-term workers,
                                                        casual and agency staff, all of whom are collectively referred to as
                                                        ‘staff’ in this document.</p>
                                                    <p className="text-height">Senior management of FINSAI will provide direction
                                                        to, and oversight of, the AML and CTF strategy as well as apply a
                                                        risk-based approach across the business.</p>
                                                    <p className="text-height">FINSAI will enforce a strict anti-money laundering
                                                        policy with zero tolerance for money laundering activities. We define
                                                        money laundering as any activity that is carried out in an attempt to
                                                        misrepresent the source of funds actually acquired through illegal
                                                        processes as funds that were acquired through lawful sources/activities.
                                                    </p>
                                                    <p className="text-height">
                                                        All FINSAI partners will be obligated to comply with FINSAI anti-money
                                                        laundering policy and with all applicable anti-money laundering laws.
                                                        Failure to comply can result in severe consequences such as criminal
                                                        penalties and heavy fines.</p>
                                                    <p className="text-height pb-0">The FINSAI AML/CTF procedures will be in line
                                                        with the requirements of
                                                        the UK:</p>

                                                    <ul className="ul-liststyle protection-policy">
                                                        <li>The Proceeds of Crime Act 2002, </li>
                                                        <li>Terrorism Act 2000 and 2001, </li>
                                                        <li>Money Laundering Regulations 2017, </li>
                                                        <li>Counter-Terrorism Act 2008, </li>
                                                        <li>HM Treasury Sanction Notices</li>
                                                        <li>FCA Handbook,</li>
                                                        <li>Joint Money Laundering Steering Group (JMLSG) guidance</li>
                                                    </ul>
                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <div className="define-card">
                                                                <p className="black_21 font_600">1.1. Money Laundering</p>
                                                                <p className="text-height">There are three broad groups of offences
                                                                    related to money
                                                                    laundering that firms need to avoid committing. These are:
                                                                </p>
                                                                <ul className="ul-liststyle mb-0">
                                                                    <li className="text-height">knowingly assisting (in a number of
                                                                        specified ways) in
                                                                        concealing, or entering into arrangements for the
                                                                        acquisition, use, and/or possession of, criminal
                                                                        property;</li>
                                                                    <li className="text-height">failing to report knowledge,
                                                                        suspicion, or where there
                                                                        are reasonable grounds for knowing or suspecting, that
                                                                        another person is engaged in money laundering; and</li>
                                                                    <li className="text-height">tipping off, or prejudicing an
                                                                        investigation.</li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                        <div className="col-12">
                                                            <div className="define-card">
                                                                <p className="black_21 font_600">1.2. Terrorist Financing</p>
                                                                <p className="text-height">There can be considerable similarities
                                                                    between the movement of terrorist property and the
                                                                    laundering of criminal property: some terrorist groups are
                                                                    known to have well established links with organized criminal
                                                                    activity. However, there are two major differences between
                                                                    terrorist property and criminal property. More generally:
                                                                </p>
                                                                <ul className="ul-liststyle mb-0">
                                                                    <li className="text-height">often only small amounts are
                                                                        required to commit
                                                                        individual terrorist acts, thus increasing the
                                                                        difficulty of tracking the terrorist property; </li>
                                                                    <li className="text-height">terrorists can be funded from
                                                                        legitimately obtained
                                                                        income, including charitable donations, and it is
                                                                        extremely difficult to identify the stage at which
                                                                        legitimate funds become terrorist property.</li>

                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-2" role="tabpanel"
                                        aria-labelledby="v-pills-profile-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">2. Risk-Based Approach
                                                    </p>

                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            {/* <!-- <div className="define-card"> --> */}

                                                            <p className="text-height">FINSAI will adopt a risk-based approach
                                                                to managing the risks
                                                                presented by the business, in line with the current JMLSG
                                                                guidance.</p>
                                                            <div>
                                                                <p className="text-height">
                                                                    FINSAI will maintain a standardized risk rating model to
                                                                    conduct risk assessments related to the exposure to
                                                                    money laundering across all client relationships. The
                                                                    FINSAI Board of Directors will approve this model on an
                                                                    annual basis.

                                                                </p>
                                                                <p className="text-height">

                                                                    The risk-based approach takes the most cost effective
                                                                    and proportionate way to manage and mitigate money
                                                                    laundering and terrorist financing risks.
                                                                </p>
                                                                <p className="pb-0">FINSAI will identify the money laundering
                                                                    and terrorist
                                                                    risks presented by:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Customers;</li>
                                                                    <li>Business type;</li>
                                                                    <li>Beneficial owners;</li>
                                                                    <li>Products;</li>
                                                                    <li>Geographical areas of operation.</li>
                                                                </ul>
                                                                <p>Consequently, FINSAI will:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Design and implement controls to manage and mitigate
                                                                        those risks;</li>
                                                                    <li>Monitor and seek to improve the operation of these
                                                                        controls;</li>
                                                                    <li>Record what has been done.</li>

                                                                </ul>
                                                                <p className="text-height">The risk-based approach will recognize
                                                                    that the money
                                                                    laundering and terrorist financing risks vary according to
                                                                    customers, jurisdictions, products and method of delivery.
                                                                </p>
                                                                <p className="text-height">FINSAI will implement procedures to
                                                                    manage and mitigate those
                                                                    risks most efficiently and cost effectively. The procedures
                                                                    will include, but will not be restricted to:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>identification and verification of customer identity
                                                                    </li>
                                                                    <li>monitoring of transactions</li>
                                                                    <li>review of customers’ data according to the risk level
                                                                        presented by the customer and the business </li>
                                                                    <li>appropriate training of all staff</li>
                                                                    <li>producing appropriate management information</li>

                                                                    <li>reporting upward</li>
                                                                    <li>effective liaison with all parts of the business</li>
                                                                    <li>maintaining up to date knowledge of regulation and
                                                                        legislation.</li>
                                                                </ul>
                                                                <p className="text-height">FINSAI recognizes that risks change over
                                                                    time and will
                                                                    continually and regularly update its risk management
                                                                    procedures.</p>
                                                            </div>

                                                            {/* <!-- </div> --> */}
                                                        </div>









                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="v-pills-3" role="tabpanel"
                                        aria-labelledby="v-pills-messages-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">3. MLRO Role and Responsibilities
                                                    </p>
                                                    <p className="text-height">FINSAI senior management will allocate a senior
                                                        individual to be the
                                                        Money Laundering Reporting Officer (MLRO) as required by the Money
                                                        Laundering Regulations (MLR). The MLRO will have overall responsibility
                                                        for the establishment and maintenance of FINSAI AML/CTF systems and
                                                        controls and will report to the FINSAI Board.</p>
                                                    <p className="text-height">
                                                        FINSAI has an appointed MLRO who reports directly to the Board of
                                                        Directors. The MLRO, with the support of the Board, is responsible for
                                                        ensuring that the Company meets the AML compliance requirements set by
                                                        the FCA, in adherence to the MLRs. The MLRO oversees the AML systems and
                                                        controls. The main activities of the MLRO comprise, but are not limited
                                                        to, the following:</p>
                                                    <ul className="ul-liststyle ">
                                                        <li>
                                                            oversight of all aspects of the company’s AML and CTF activities;
                                                        </li>
                                                        <li>focal point for all activities within the company relating to AML
                                                            and CTF;</li>
                                                        <li>establishing the basis on which a risk-based approach to the
                                                            prevention of money laundering and terrorist financing is put into
                                                            practice;</li>
                                                        <li>supporting and co-ordinating senior management focus on managing the
                                                            money laundering/terrorist financing risk in individual business
                                                            areas; and</li>
                                                        <li>
                                                            ensuring that the Company’s wider responsibility for AML/CTF is
                                                            addressed centrally through appropriate training activities. </li>
                                                    </ul>
                                                    <p>The MLRO is also required to produce reports for Board meetings
                                                        including, but not limited to, the following items:</p>
                                                    <div>
                                                        <ul className="ul-liststyle">
                                                            <li>Confirmation that adequate customer due
                                                                diligence
                                                                information is being collected and that ongoing monitoring is
                                                                taking
                                                                place;.
                                                            </li>
                                                            <li>Summary data relating to complex or unusual transactions;  </li>
                                                            <li>Number of internal consents / Suspicious Activity Reports (SARs)
                                                                received from staff members;</li>
                                                            <li>Number of SARs made to the National Crime Agency (NCA);</li>
                                                            <li>Information on status of staff training within the company;
                                                            </li>
                                                            <li>Confirmation that all business records have been properly stored
                                                                and are retained according to regulatory requirements;</li>
                                                            <li>Changes in the law/operating environment which do or might
                                                                impact the business;</li>
                                                            <li>Changes in the risk matrix affecting the business; and</li>
                                                            <li>Contacts with the regulator.</li>
                                                        </ul>
                                                        <p className="text-height">
                                                            AML compliance monitoring and periodic audit will be performed
                                                            internally by the MLRO (who is also the compliance officer) via a
                                                            consolidated compliance monitoring plan.</p>

                                                    </div>





                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-4" role="tabpanel"
                                        aria-labelledby="v-pills-settings-tab">
                                        <div className="pt-3 content--main">


                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">4. Training
                                                </p>
                                                <p className="text-height">All employees will be made aware, through the annual
                                                    compulsory training
                                                    programme, of:</p>

                                                <ul className="ul-liststyle ">
                                                    <li className="text-height">
                                                        The risks of money laundering and terrorist financing, the relevant
                                                        legislation, and their obligations under that legislation;</li>

                                                    <li className="text-height"> The identity and responsibilities of FINSAI
                                                        nominated officer
                                                        (MLRO);</li>
                                                    <li className="text-height">
                                                        FINSAI procedures in how to recognize and deal with potential money
                                                        laundering or terrorist financing transactions or activity. </li>

                                                </ul>
                                                <p className="text-height">Staff training on anti-money laundering and counter
                                                    terrorist financing
                                                    will be carried out annually for all staff, and details will be
                                                    recorded. Anti-money laundering training is also delivered for new staff
                                                    members as part of the induction programme. </p>

                                                <p className="text-height">The MLRO is responsible for oversight of FINSAI
                                                    compliance with its
                                                    requirements in respect of staff training, and has overall
                                                    responsibility for the establishment and maintenance of effective
                                                    training arrangements.</p>

                                            </div>
                                        </div>


                                    </div>
                                    <div className="tab-pane fade" id="v-pills-5" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">5. Sanctions and PEPs Screening
                                                </p>
                                                <p className="text-height">FINSAI will make use of an external service provider to
                                                    screen applicants against recognised Sanctions Lists and Politically Exposed
                                                    Persons (PEPs) lists. Individuals will be screened on on-going basis as well
                                                    as on initial sign up.</p>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="font_18 font_600 black_21">5.1. Sanctions Lists</p>
                                                            <p>FINSAI will take all required steps to ensure that all customers
                                                                with whom a business relationship is established are screened
                                                                against relevant notices published by:</p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>the Office of Foreign Assets Control (OFAC)</li>
                                                                <li>Her Majesty’s Treasury Department – UK (HMT)</li>
                                                                <li>European Union sanctions (EU)</li>
                                                                <li>United Nations sanctions (UN)</li>
                                                            </ul>
                                                            <p className="text-height">
                                                                Information leading to “fuzzy matches” will be investigated
                                                                further, for example where the match was related to a name which
                                                                can be deemed as popular, and this will be compared against the
                                                                other information that is collected at point of registration.
                                                                The full evaluation of the customers data will provide a result.
                                                            </p>
                                                            <p className="text-height">Any confirmed matches to sanctions lists will
                                                                be declined or
                                                                closed, and the necessary reports will be made to the Office of
                                                                Financial Sanctions Implementation (OFSI).</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="define-card">
                                                            <p className="font_18 font_600 black_21">5.2. PEPs Screening</p>
                                                            <p>FINSAI will screen customers against PEP lists that will help to
                                                                structure information about PEPs.</p>
                                                            <p className="text-height pb-0">
                                                                The definition of a 'PEP' is an individual that: </p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>is or has, at any time in the preceding year, been entrusted
                                                                    with prominent public functions;</li>
                                                                <li>is an immediate family member of such a person;</li>
                                                                <li>is a known associate of such a person;</li>
                                                                <li>is or has, at any time in the preceding year, been entrusted
                                                                    with a prominent public function by –</li>
                                                                <ul>
                                                                    <li>a state other than the UK;</li>
                                                                    <li>the European Community; or</li>
                                                                    <li>an international body; or</li>
                                                                </ul>
                                                                <li>is an immediate family member or a known close associate of
                                                                    a person referred to in the paragraph immediately above.
                                                                </li>
                                                            </ul>
                                                            <p className="text-height">If a PEP were identified and it is validated,
                                                                the customer’s
                                                                account would automatically fall under the requirement of
                                                                Enhanced Due Diligence. Senior management will decide on a
                                                                case-by-case basis whether to open PEP accounts based on the
                                                                risk to the business. PEP accounts will not be declassNameified once
                                                                their tenure in a public office has expired. The MLRO will
                                                                include within the annual MLRO report to the board any customers
                                                                identified as PEPs during the previous year.</p>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-6" role="tabpanel">
                                        <div className="pt-3 content--main">

                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">6. Registration
                                                </p>
                                                <p className="text-height">The first requirement of ‘knowing your
                                                    customer/businesses for money
                                                    laundering purposes is to be satisfied that a prospective
                                                    customer/business is who they claim to be.</p>
                                                <p className="text-height">
                                                    FINSAI will carry out appropriate KYC/KYB procedures for all customers.
                                                    The objective of the KYC/KYB process is to ensure FINSAI holds
                                                    appropriate information to be able to satisfactorily know who FINSAI is
                                                    dealing with. </p>
                                                <p className="text-height">As the very nature of e-money is non-face-to-face,
                                                    this policy indicates
                                                    the standards required in identifying customers is such a way. FINSAI
                                                    will utilize customer identification procedures to validate the true
                                                    identity of its clients. Documenting and maintaining these
                                                    identification procedures enhances FINSAI ability to prevent money
                                                    laundering activities. If FINSAI fails to verify a client’s identity
                                                    within an acceptable time period, all services will be suspended for
                                                    that client and any accounts opened will be terminated. In addition, the
                                                    account and services attached to that client will be under stricter
                                                    scrutiny until the suspension and/or closure is carried out.</p>

                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="font_18 font_600 black_21">6.1. Individual customers
                                                            </p>
                                                            <p>At point of registration within the website, FINSAI will
                                                                capture
                                                                the following customer information:</p>

                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>Name;</li>
                                                                <li>Email address;</li>
                                                                <li>Date of birth;</li>
                                                                <li>Country </li>
                                                                <li>Proof of ID (Valid Passport/Driving License)</li>
                                                                <li>Proof of Address (Council Tax/ Utility Bill/Bank
                                                                    Statement)
                                                                </li>

                                                            </ul>
                                                            <p className="text-height">The above information is used to verify
                                                                the
                                                                email address via email sent to the registered email address
                                                                and
                                                                to verify the country via IP geo-location using an external
                                                                service provider. </p>
                                                            <p>IP Geo-Location will be used to detect the location from
                                                                where
                                                                the customer signs-up to make sure that only customers from
                                                                the
                                                                EEA are onboarded.</p>
                                                            <p className="pb-0">In addition, the system will automatically
                                                                detect if
                                                                the email
                                                                address entered is already assigned to another e-wallet
                                                                holder.
                                                                FINSAI will only permit one e-wallet per person and so the
                                                                details provided will be checked against the existing
                                                                customer
                                                                database to confirm that they do not already hold an
                                                                account,
                                                                and they were not previously unsuccessful.

                                                            </p>
                                                            <br />
                                                            <p>
                                                                At this stage Sanctions and PEPs screening are run on
                                                                applicants
                                                                using an external service provider.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="define-card">
                                                            <p className="font_18 font_600 black_21">6.2. Corporate customers
                                                            </p>
                                                            <p>The Merchant Form collects mandatory information based on the
                                                                company type, as outlined below.</p>
                                                            <p>The industry in which the corporate customer operates is also
                                                                assessed via the Merchant Form, therefore any corporate
                                                                customer operating in any of the banned industries, as per
                                                                company policy (Appendix 13.3.), will be rejected. </p>
                                                            <p>In addition, the system will automatically detect if the
                                                                details entered are already assigned to another e-wallet
                                                                holder. FINSAI will only permit one e-wallet per corporate
                                                                customer and so the details provided will be checked against
                                                                the existing customer database to confirm that they do not
                                                                already hold an account, and they were not previously
                                                                unsuccessful.
                                                            </p>
                                                            <p>
                                                                At this stage Sanctions and PEPs screening are run on
                                                                directors and beneficial owners using an external service
                                                                provider.</p>
                                                            <div className="define-sub-card">
                                                                <p className="black_21 font_500">6.2.1. Corporate customers</p>
                                                                <p>The following information will be gathered:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Full name</li>
                                                                    <li>Residential address (if different to the business
                                                                        address)</li>
                                                                    <li>Date of Birth</li>
                                                                    <li>Business trading name</li>
                                                                    <li>Business address</li>
                                                                    <li>Purpose and nature of the business relationship</li>
                                                                </ul>
                                                            </div>
                                                            <div className="define-sub-card">
                                                                <p className="black_21 font_500">6.2.2.  Partnerships and
                                                                    unincorporated businesses</p>
                                                                <p>The following information will be gathered:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Full name of individual applying</li>
                                                                    <li>Business address</li>
                                                                    <li>Name of all partners or principals (identifying
                                                                        those who exercise control over the management of
                                                                        the partnership)</li>
                                                                    <li>Name of individuals who own or control more than 10%
                                                                        of its capital or profit, or voting rights.</li>
                                                                    <li>Business trading name (if different)</li>
                                                                    <li>Purpose and nature of the business relationship</li>
                                                                </ul>
                                                            </div>
                                                            <div className="define-sub-card">
                                                                <p className="black_21 font_500">6.2.3. Private companies</p>
                                                                <p>The following information will be gathered:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Full name of company</li>
                                                                    <li>Registered number</li>
                                                                    <li>Registered office address in country of
                                                                        incorporation</li>
                                                                    <li>Business address (if different from registered
                                                                        address)</li>
                                                                    <li>Names of all directors (identifying those who
                                                                        exercise control over the management of the
                                                                        company)</li>
                                                                    <li>Names of all beneficial owners who own or control
                                                                        more than 10% of its shares or voting rights</li>
                                                                    <li>Business trading name (if different)</li>
                                                                    <li>Purpose and nature of the business relationship</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-7" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">7. Full Due Diligence (FDD)
                                                </p>

                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">7.1. Individual customers</p>
                                                            <p>In order to become verified, individual customers will be
                                                                screened electronically using an e-KYC provider. This will be
                                                                achieved by requiring details that allow a 2+2 KYC check in
                                                                accordance with the JMLSG guidance notes as follows:</p>
                                                            <ul className="ul-liststyle">
                                                                <li>one match on an individual’s full name and current address,
                                                                    and </li>
                                                                <li>
                                                                    a second match on an individual’s full name and either his
                                                                    current address or his date of birth.</li>
                                                            </ul>
                                                            <p className="text-height">In the event that the customer’s identity
                                                                cannot be verified
                                                                electronically, there is a manual process utilizing physical
                                                                documents which the customer must submit for review. </p>
                                                            <p className="text-height">
                                                                Where FINSAI needs to verify the customer manually, applicants
                                                                will be required to provide a proof of identity as well as a
                                                                proof of address.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">7.2. Corporate customers</p>
                                                            <p>All corporate customers will be manually verified and required to
                                                                provide the documentation outlined below.</p>
                                                            <div className="define-sub-card">
                                                                <p className="font_500 black_21">7.2.1. Sole traders</p>
                                                                <p className="text-height">Merchant’s identity will be
                                                                    electronically verified using the
                                                                    procedure described in ‘7.1. Individual customers.</p>
                                                            </div>
                                                            <div className="define-sub-card">
                                                                <p className="font_500 black_21">7.2.2. Partnerships and
                                                                    unincorporated businesses</p>
                                                                <p className="text-height">The customer’s identity will be verified
                                                                    by production of a copy of one of the following, valid,
                                                                    documents:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Partnership Deed</li>
                                                                    <li>Membership of a relevant professional or trade
                                                                        association</li>
                                                                </ul>
                                                                <p>And:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Verify the identity of at least 2 partners or principals
                                                                        (as per the requirements set out above in ‘7.1.
                                                                        Individual customers’)</li>
                                                                    <li>Where a formal partnership agreement exists, a mandate
                                                                        from the partnership authorising the opening of an
                                                                        account and conferring authority on those who will
                                                                        operate it should be obtained.</li>
                                                                </ul>
                                                                <p>And:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Verify the identity of all individuals who own or
                                                                        control more than 10% of its capital or profit, or
                                                                        voting rights</li>

                                                                </ul>
                                                                <p className="text-height">
                                                                    The business address will be verified, in addition, by
                                                                    production of a copy of a valid bank statement relating to
                                                                    the business account and a utility bill dated within the
                                                                    last 3 months.</p>
                                                            </div>
                                                            <div className="define-sub-card">
                                                                <p className="font_500 black_21">7.2.3. Private companies</p>
                                                                <p className="text-height">The customer’s identity will be verified
                                                                    by production of a
                                                                    copy of one of the following, valid, documents:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Certificate of Incorporation (certified copy)</li>
                                                                    <li>Memorandum and Articles of Association</li>
                                                                    <li>Shareholder register</li>
                                                                    <li>Audited accounts (most recent)</li>
                                                                </ul>
                                                                <p className="text-height">And:</p>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Verify the identity of at least 2 directors, including 1
                                                                        controlling director (as per the requirements set out
                                                                        above in ‘7.1. Individual customers’)</li>
                                                                    <li>Verify the identity of all beneficial owners and / or
                                                                        shareholders (as per the relevant ownership type)</li>

                                                                </ul>
                                                                <p className="text-height">In addition, a copy of a valid bank
                                                                    statement relating to the
                                                                    business account dated within the last 3 months will be
                                                                    requested.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-8" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">8. Enhanced Due Diligence (EDD)
                                                    </p>
                                                    <p className="text-height">Enhanced due diligence is required in circumstances
                                                        giving rise to an
                                                        overall higher risk.
                                                        When assessing the ML/TF risks the following factors will be considered:
                                                    </p>
                                                    <ul className="ul-liststyle protection-policy">
                                                        <li>
                                                            types of customer (PEPs)
                                                        </li>
                                                        <li>countries or geographic areas (countries identified by credible
                                                            sources as not having adequate AML/CTF approaches) </li>
                                                        <li>transactions (discrepancies between submitted and detected
                                                            information)</li>
                                                        <li>delivery channels (businesses introduced by third parties)</li>
                                                    </ul>
                                                    <p className="text-height">Customers subject to EDD are required to provide
                                                        documentary evidence
                                                        regarding the legal origin of funds. Failure to provide such may result
                                                        in a transaction being held. </p>
                                                    <p className="text-height">As a result, senior management will take a decision
                                                        to continue or terminate business relations with the respective client
                                                        as well as to continue or terminate business relations with other
                                                        clients who have the same beneficiaries or who conduct transactions on
                                                        behalf of the same third persons.</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-9" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">9. Transaction Monitoring
                                                </p>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="font_600 black_21">9.1. Individual customers</p>
                                                            <p className="text-height">FINSAI will use a combination of system
                                                                alerts and blocks in
                                                                order to facilitate effective transaction monitoring. FINSAI
                                                                will regularly review the parameters to ensure they remain
                                                                relevant. FINSAI will conduct ongoing monitoring of the business
                                                                relationship which includes:</p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>Scrutinising transactions, including source of funds;</li>
                                                                <li>Keeping up to date documents held by FINSAI</li>
                                                            </ul>
                                                            <p className="text-height">Monitoring is designed to identify unusual
                                                                activity which may be
                                                                indicative of money laundering or terrorist activity. Monitoring
                                                                undertaken by FINSAI will:</p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>Flag up transactions for further examination;</li>
                                                                <li>Be reviewed promptly;</li>
                                                                <li>Lead to appropriate action.</li>
                                                                <li>Where monitoring is undertaken by a third party, the process
                                                                    is to be checked and approved by FINSAI .</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="define-card">
                                                            <p className="font_600 black_21">9.2. Corporate customers</p>
                                                            <p className="text-height">After a corporate customer has been approved
                                                                and brought on board, FINSAI performs a comprehensive process of
                                                                transaction monitoring.</p>
                                                            <p className="text-height">FINSAI has developed and utilizes an
                                                                internally developed
                                                                automated fraud prevention and risk management system.
                                                                Parameters are set so that when business statistics exceed these
                                                                parameters, red-flags are raised and the business customer is
                                                                informed and must respond with acceptable reasons as to why this
                                                                has occurred. </p>
                                                            <p>Key transaction monitoring data points include trend analysis:</p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>abnormal transaction volume growth or decline; </li>
                                                                <li>growth or decline in relevant ratios such as returns and
                                                                    credits; </li>
                                                                <li>growth or decline in transaction average size; </li>

                                                            </ul>
                                                            <p className="text-height">
                                                                FINSAI rule set and entire fraud prevention and risk management
                                                                system is constantly enhanced and updated to meet emerging
                                                                threats and to properly evaluate every transaction.</p>
                                                            <p className="text-height">
                                                                In addition to the above-described automated processes, FINSAI
                                                                performs a periodic re-underwriting of each business customer.
                                                                This re-underwriting process can be triggered simply by the
                                                                passage of time, by any of the key transaction monitoring events
                                                                described above, by any change in management or ownership of the
                                                                business or by any external information that might raise risk
                                                                related red-flags.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-10" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">10. Controls Over the Wallet
                                                </p>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="font_600 black_21">10.1. Loading Instrument</p>
                                                            <p className="text-height">FINSAI will carry out the following checks:
                                                            </p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li className="pb-3">Customer's bank account loads – at the
                                                                    minimum, incoming
                                                                    transfer from a bank account registered under the customers'
                                                                    name. FINSAI will make sure that there is a full match
                                                                    between name registered with the company and bank account's
                                                                    holder. </li>
                                                                <li className="pb-3">Third party's bank account loads –
                                                                    incoming transfer from a
                                                                    bank account belonging to a third party. FINSAI will
                                                                    require copy of the bank statement from the third party and
                                                                    will run Sanctions and PEPs screening on the sender. </li>
                                                                <li className="pb-3">

                                                                    P2P loads – subject to internal transaction monitoring
                                                                    rules
                                                                </li>
                                                                <li>Customer's credit/debit card's loads -
                                                                    incoming transfer
                                                                    from a debit/credit registered under the customers' name. In
                                                                    order to make sure that the credit/debit card belongs to the
                                                                    customer, FINSAI will require a selfie of the customer
                                                                    holding a copy of the credit/debit card used to load funds
                                                                    into the wallet.</li>
                                                            </ul>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="define-card">
                                                            <p className="font_600 black_21">10.2. Withdrawals</p>
                                                            <p className="text-height">FINSAI will carry out the following checks:
                                                            </p>


                                                            <ul className="ul-liststyle protection-policy">
                                                                <li className="pb-3">Transfers to customer's bank account -
                                                                    at the minimum,
                                                                    outgoing transfer to a bank account registered under the
                                                                    customers' name. FINSAI will make sure that there is a full
                                                                    match between name registered with the company and bank
                                                                    account's holder.  </li>
                                                                <li className="pb-3">Transfers to third party's bank account
                                                                    - outgoing transfer
                                                                    to a bank account registered under third party's name.
                                                                    FINSAI will require copy of the bank statement from the
                                                                    third party and will run Sanctions and PEPs screening on the
                                                                    recipient.  </li>
                                                                <li className="pb-3">Transfer to customer's credit/debit
                                                                    cards - outgoing
                                                                    transfer to a debit/credit registered under the customers'
                                                                    name. In order to make sure that the credit/debit card
                                                                    belongs to the customer, FINSAI will require a selfie of the
                                                                    customer holding a copy of the credit/debit card used to
                                                                    withdraw funds from the wallet. </li>

                                                            </ul>
                                                            <p className="text-height">
                                                                In the case of refund requests due to account closure, funds
                                                                will only be returned to a bank account in the customer’s name.
                                                                In order to validate that the customer is in fact the bank
                                                                account holder, FINSAI requires the customer to submit a copy of
                                                                their current bank statement (dated within the past 3 months).
                                                                Where suspicious grounds are established, a suspicious
                                                                transaction will be reported to the NCA, as a SAR.</p>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-11" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="font_26 black_21 font_700">11. Suspicious Activity Reporting
                                                </p>
                                                <p className="text-height">When any member of staff either knows, suspects or has
                                                    reasonable grounds for
                                                    knowing or suspecting that a money laundering offence has been or is being
                                                    committed they must make a suspicious activity report (SAR) to the MLRO.
                                                </p>
                                                <p className="text-height">Staff are not required to actively search for indications
                                                    that money
                                                    laundering offences are occurring. However, if they become aware of, or
                                                    suspect that, such offences are occurring during the course of their normal
                                                    duties then they shall make a SAR to the MLRO.</p>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">11.1. Internal Reporting</p>
                                                            <p className="text-height">All staff must report any activity that they
                                                                see in the course of
                                                                their duties and that they think may be suspicious to the MLRO;
                                                                all such reports must be documented by the member of staff
                                                                making the report. FINSAI will provide all necessary training
                                                                and guidance to ensure that staff are aware of; </p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>Their obligations in this area</li>
                                                                <li>Possible offences and penalties</li>
                                                                <li>Any internal disciplinary sanctions that may apply for not
                                                                    reporting</li>
                                                                <li>Procedures to be followed</li>
                                                                <li>Documentation to be used to make reports </li>
                                                                <li>Where to access further advice and guidance.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">11.2. Suspicious Activity Reports –
                                                                Internal Procedure</p>
                                                            <p className="text-height">FINSAI has made a SAR template (see Appendix
                                                                13.1.) available to staff, all reports must be made using this
                                                                template to ensure consistency. All reports must be fully
                                                                documented and must include at a minimum the following
                                                                information: </p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>Name and contact details of person making the report</li>
                                                                <li>The customers personal details</li>
                                                                <li>The suspicious transaction details; date, amount and
                                                                    transaction identifier.</li>
                                                                <li>Details of any related transactions (as above)</li>
                                                                <li>The reasons for the suspicions.</li>

                                                            </ul>
                                                            <p className="text-height">The fact that a report has been made and the
                                                                content of the
                                                                report must remain confidential at all times. A member of staff
                                                                who forms or is aware of a suspicion of money laundering shall
                                                                not discuss it with any outside party or any other member of
                                                                staff unless directly involved in the matter causing suspicion.
                                                            </p>
                                                            <p className="text-height">No member of staff shall at any time disclose
                                                                a money laundering
                                                                suspicion to the person suspected. If circumstances arise that
                                                                may cause difficulties with a client contact, the member of
                                                                staff must seek and follow the instructions of the MLRO. </p>
                                                            <p className="text-height">No copies or records of money laundering
                                                                suspicion reports are to
                                                                be made, except by the MLRO who will keep such records secure,
                                                                and separate.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">11.3. MLRO Acknowledgement and
                                                                Evaluation</p>
                                                            <p className="text-height">All members of staff, anywhere within FINSAI
                                                                , shall respond in full to all enquiries made by the MLRO for
                                                                the purposes of evaluating a SAR. Information provided to the
                                                                MLRO in response to such enquiries does not breach client
                                                                confidentiality or professional privilege, and no member of
                                                                staff shall withhold information on those grounds. </p>
                                                            <p className="text-height">The MLRO will acknowledge every report made. This confirms to the
                                                                member of staff who raised the matter that their legal
                                                                obligation to report has been fulfilled. This response will:</p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>Acknowledge receipt of the report</li>
                                                                <li>Remind the initiator of the report of their obligation to do
                                                                    nothing that might prejudice an investigation or tip off the
                                                                    client</li>
                                                                <li>Provide feedback on the quality of the report and suggest
                                                                    areas of improvement for future reports.</li>


                                                            </ul>
                                                            <p className="text-height">The MLRO will then conduct his own evaluation
                                                                of the information provided within the report and decide whether
                                                                or not to make a disclosure to the NCA. The MLRO’s review,
                                                                evaluation and decision will be recorded.
                                                            </p>
                                                            <p className="text-height">Any internal enquiries made in relation to
                                                                the report will be documented.</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">11.4. Tipping-Off</p>
                                                            <p className="text-height">In some circumstances it may be necessary to
                                                                obtain information from the customer before deciding whether or
                                                                not to make a disclosure to the NCA. In these cases the MLRO may
                                                                request an appropriate person (such as a Fraud and Security,
                                                                Marketing or Customer Services Supervisor) to make discreet
                                                                enquiries of a customer. In such circumstances care will be
                                                                taken to ensure that the offence of ‘tipping-off’ will be
                                                                avoided. </p>
                                                            <p className="text-height">Under PART 3 - CHAPTER 3 - Data subject's
                                                                right of access-
                                                                Section 45 of the Data Protection Act 2018 (DPA 2018) a customer
                                                                can request information regarding any of their personal
                                                                information held or processed by an organisation – a Subject
                                                                Access Request.</p>
                                                            <p className="text-height">
                                                                SCHEDULE 11 of the DPA 2018 provides some exemption from
                                                                responding to such a request when a SAR has been made, whether
                                                                or not it has been reported to the NCA.</p>
                                                            <p className="text-height">
                                                                Where a Subject Access Request is received and it is established
                                                                that a SAR has been issued then the authority of the MLRO must
                                                                be sought before any information relating to the SAR is released
                                                                to the customer. The MLRO will keep a record of all such
                                                                referrals.</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">11.5. External Reporting</p>
                                                            <p className="text-height">The MLRO (or, if absent, the named deputy)
                                                                shall receive and evaluate internal suspicion reports and decide
                                                                whether a formal disclosure is to be made to the authorities. If
                                                                so deciding, the MLRO will make the formal disclosure on behalf
                                                                of FINSAI using the Suspicious Activity Report Form (see
                                                                Appendix 13.2.). </p>
                                                            <p className="text-height">Prior to making any such report the MLRO will
                                                                undertake internal enquiries to satisfy themselves that, based
                                                                on the information in the report and the result of their
                                                                enquiries, they know or suspect, or have reasonable grounds to
                                                                know or suspect, an offence of money laundering. External
                                                                reports will not be made until all available information has
                                                                been considered by the MLRO, unless this would render such
                                                                reports untimely. Such information includes:</p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>
                                                                    The financial circumstances of a customer or any person on
                                                                    whose behalf the customer has been acting</li>
                                                                <li>The features of all transactions that the firm has entered
                                                                    into with or for the customer or any person on whose behalf
                                                                    the customer has been acting.</li>
                                                            </ul>
                                                            <p className="text-height">Where the MLRO does not have immediate access
                                                                to such information
                                                                it will be provided as soon as is practicable by any relevant
                                                                employee of the firm or any relevant employee of any
                                                                organisation that provides outsourced services on behalf of
                                                                FINSAI . The need to examine any additional information will not
                                                                unnecessarily delay any report.</p>
                                                            <p className="text-height">The decision whether or not there is
                                                                knowledge, suspicion or
                                                                reasonable grounds for knowledge or suspicion of a money
                                                                laundering offence will rest with the MLRO or their delegated
                                                                representative alone. Such a decision will not be subject to the
                                                                consent of any other person within FINSAI .</p>
                                                            <p className="text-height">From the moment a suspicion of money
                                                                laundering arises, no
                                                                further work will be carried out on the matter that gave rise to
                                                                the suspicion. Neither commercial considerations nor the
                                                                difficulty in responding to the client’s enquiries on the matter
                                                                shall be permitted to take precedence over FINSAI legal
                                                                obligations in this regard.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">11.6. National Crime Agency (NCA)</p>
                                                            <p className="text-height">The disclosure regime for money laundering
                                                                and terrorist financing is run by the United Kingdom Financial
                                                                Intelligence Unit (UKFIU), within the NCA. </p>
                                                            <p className="text-height">A SAR is the name given to the making of a
                                                                disclosure to the NCA under either the Proceeds of Crime Act
                                                                (POCA) or the Terrorism Act. The NCA has issued a preferred form
                                                                which can be found online (www.nationalcrimeagency.gov.uk). This
                                                                securely encrypted system provided by the NCA allows firms to:
                                                            </p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>
                                                                    register the firm and relevant contact persons </li>
                                                                <li>submit a SAR at any time of day </li>
                                                                <li>receive e-mail confirmations of each SAR submitted </li>
                                                            </ul>
                                                            <p className="text-height">SARs can still be submitted in hard copy,
                                                                although they should be typed and on the preferred form. Firms
                                                                will not receive acknowledgement of any SARs sent this way. </p>
                                                            <p className="text-height">
                                                                The national reception point for disclosure of suspicions is the
                                                                UKFIU within the NCA. The UKFIU address is PO Box 8000, London,
                                                                SE11 5EN. </p>
                                                            <p className="text-height">The Financial Intelligence Helpdesk can be
                                                                contacted on 020 7238 8282. Firms can contact NCA on this number
                                                                for:</p>
                                                            <ul className="ul-liststyle protection-policy">
                                                                <li>
                                                                    help in submitting a SAR or with the SARs online system
                                                                </li>
                                                                <li>help on consent issues </li>
                                                                <li>assessing the risk of tipping off so you know whether
                                                                    disclosing information about a particular SAR would
                                                                    prejudice an investigation </li>
                                                            </ul>
                                                            <p className="text-height pb-0">The NCA is required to treat any SARs
                                                                confidentially. Where
                                                                information from a SAR is disclosed for the purposes of law
                                                                enforcement, care is taken to ensure that the identity of the
                                                                reporter and their firm is not disclosed to other persons.</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600">11.7. Seek ‘Consent’</p>
                                                            <p className="text-height">Where a report is received prior to a
                                                                transaction or activity
                                                                taking place then the MLRO will make the appropriate report to
                                                                the NCA and seek consent to proceed with that transaction or
                                                                activity.</p>
                                                            <p className="text-height">The NCA has up to seven (7) days to confirm
                                                                whether or not the transaction, for which a consent has been
                                                                requested, can proceed – until the NCA gives consent, the
                                                                transaction cannot proceed – it is frozen. In these
                                                                circumstances, the staff member must be very careful that they
                                                                do not ‘tip off’ the customer about the reason for the delay in
                                                                processing the transaction. </p>
                                                            <p className="text-height">Where the NCA gives notice that consent to a
                                                                transaction is refused, a further thirty-one (31) day period
                                                                (the “Moratorium”) commences on the day that notice is given.
                                                                The thirty-one (31) days include Saturdays, Sundays and public
                                                                holidays. It is an offence to undertake the transaction during
                                                                this period as the participant would not have the appropriate
                                                                consent. The Moratorium period enables the NCA to further their
                                                                investigation into the reported matter using the powers within
                                                                the POCA in relation to the criminal property (e.g. imposing a
                                                                restraint order). If the Moratorium period expires and no such
                                                                action has been taken, the reporter is free to proceed with the
                                                                act(s) detailed in the initial disclosure. </p>
                                                            <p className="text-height">It is FINSAI policy that all requests for consent must be
                                                                processed through the MLRO – it is expressly forbidden for
                                                                employees to make a ‘consent’ request direct to the NCA.</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-12" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="text-black font_26 font_700 pt-2">12. Record-Keeping</p>
                                                <p className="text-height">FINSAI will retain the following records:</p>
                                                <ul className="ul-liststyle protection-policy">
                                                    <li>Copies of, or references to, the evidence obtained of a customer’s
                                                        identity for 6 (six) years after the end of the customer relationship;
                                                    </li>
                                                    <li>Details of customer transactions for five years from the date of the
                                                        relevant transaction.</li>
                                                    <li>Records of all AML/CTF training delivered </li>
                                                    <li>Details of actions taken in respect of internal and external suspicion
                                                        reports;</li>
                                                    <li>Details of information considered by the MLRO or his nominee in respect
                                                        of an internal report where no external report is made.</li>
                                                </ul>
                                                <p className="text-height">
                                                    FINSAI will ensure that the above requirements are adhered to, and that the
                                                    documents are able to be produced on request.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade " id="v-pills-13" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div className="content-card">
                                                <p className="text-black font_26 font_700 pt-2">13. Appendices</p>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600 text-height">13.1. Internal Suspicious
                                                                Activity Report Form</p>
                                                            <p>To: Money Laundering Reporting Officer</p>
                                                            <div className="row">
                                                                <div className="col-lg-10 col-xl-8 col-12">
                                                                    <div className="mb-3 d-flex align-items-end">
                                                                        <p className="pb-0">From:</p>
                                                                        <input type="email"
                                                                            className="form-control define-input flex-1"
                                                                            id="exampleFormControlInput1" placeholder=""
                                                                            disabled />
                                                                        <p className="pb-0">(name of employee)</p>
                                                                    </div>
                                                                    <div className="mb-3 d-flex align-items-end">
                                                                        <p className="pb-0">Date:</p>
                                                                        <input type="email"
                                                                            className="form-control define-input border-0 flex-1"
                                                                            id="exampleFormControlInput1" placeholder=""
                                                                            disabled />

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 col-xl-10 col-12">

                                                                    <p className="text-height">This SAR is (circle which applies):
                                                                    </p>
                                                                    <p className="text-height">1. A request for consent for a
                                                                        transaction which is not
                                                                        yet completed </p>
                                                                    <p className="text-height">2. A report on a transaction which has taken place which I consider suspicious</p>
                                                                    <p className="text-height">3. Report on other business-related
                                                                        activity which I
                                                                        consider suspicious</p>
                                                                    <p className="text-height">I consider the following transaction
                                                                        suspicious and
                                                                        report to you under the internal reporting procedure:
                                                                    </p>
                                                                </div>
                                                                <div className="col-lg-10 col-xl-9 col-xxl-8 col-12">
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">1) Date of transaction:
                                                                            </p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">2) Amount:</p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">3) Customer name/ID:</p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">4) Transaction number:
                                                                            </p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-5 g-0 align-items-end ">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">5) Reason for suspicion:
                                                                            </p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>





                                                                    <div className="mb-5 d-flex align-items-end gap-3">
                                                                        <p className="pb-0 text-height">Signature of reporting staf
                                                                        </p>
                                                                        <input type="email"
                                                                            className="form-control define-input bottom-border flex-1"
                                                                            id="exampleFormControlInput1" placeholder=""
                                                                            disabled />

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600 text-height">13.2. Suspicious Activity
                                                                Report Form- MLRO Resolution</p>
                                                            <p className="text-height">Private and Confidential</p>
                                                            <p className="text-height">From: Money Laundering Reporting Officer</p>
                                                            <div className="row">
                                                                <div className="col-lg-10 col-xl-9 col-xxl-8 col-12">
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">Transaction number:
                                                                            </p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">Customer:</p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">Amount:</p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">Internal SAR received
                                                                                from:
                                                                            </p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 g-0 align-items-end">
                                                                        <div className="col-lg-4 col-xl-5 col-xxl-4">
                                                                            <p className="pb-0 text-height">Date SAR received:
                                                                            </p>

                                                                        </div>
                                                                        <div className="col-lg-8 col-xl-7 col-xxl-8">
                                                                            <input type="email"
                                                                                className="form-control define-input bottom-border flex-1"
                                                                                id="exampleFormControlInput1" placeholder=""
                                                                                disabled />
                                                                        </div>
                                                                    </div>

                                                                    <p className="text-height">I confirm that I have reviewed the
                                                                        internal SAR and
                                                                        customer ID for this customer plus transaction history
                                                                        (all documents attached)</p>

                                                                    <p className="text-height">
                                                                        I confirm that, based on the information received and
                                                                        reviewed, I have:</p>
                                                                    <ol className="ul-liststyle" type="1">
                                                                        <li className="li-height">submitted a Consent request to NCA
                                                                        </li>
                                                                        <li className="li-height">submitted a post transaction SAR
                                                                            to NCA</li>
                                                                        <li className="li-height">decided not to submit any kind of
                                                                            SAR to NCA for the
                                                                            following reason:</li>
                                                                    </ol>
                                                                    <p className="text-height mb-3">Signature of MLRO:</p>
                                                                    <p className="text-height mb-3">Date:</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="define-card">
                                                            <p className="black_21 font_600 text-height">13.3. List of Banned
                                                                Products and
                                                                Services</p>
                                                            <ol className="ul-liststyle" type="1">
                                                                <li>Body parts: - which includes organs or other body parts.
                                                                </li>
                                                                <li>Bulk marketing tools: - which includes email lists,
                                                                    software, or other Products enabling unsolicited email
                                                                    messages (i.e. Spam)</li>
                                                                <li>Cable descramblers and black boxes: - which includes devices
                                                                    intended to obtain cable and satellite signals for free</li>
                                                                <li>Child pornography: - which includes pornographic materials
                                                                    involving minors.</li>
                                                                <li>Copyright unlocking devices: - which includes Mod-chips or
                                                                    other devices designed to circumvent copyright protection
                                                                </li>
                                                                <li>Copyrighted media: - which includes unauthorized copies of
                                                                    books, music, movies, and other licensed or protected
                                                                    materials.</li>
                                                                <li>Copyrighted software: - which includes unauthorized copies
                                                                    of software, video games and other licensed or protected
                                                                    materials, including OEM or bundled software.</li>
                                                                <li>Counterfeit and unauthorized goods: - which includes
                                                                    replicas or imitations of designer goods; items without a
                                                                    celebrity endorsement that would normally require such an
                                                                    association; fake autographs, counterfeit stamps, and other
                                                                    potentially unauthorized goods.</li>
                                                                <li>Drugs and drug paraphernalia: - which include illegal drugs
                                                                    and drug accessories, including herbal drugs like salvia and
                                                                    magic mushrooms.</li>
                                                                <li>Drug test circumvention aids: - which includes drug
                                                                    cleansing materials, urine test additives, and related
                                                                    items.</li>
                                                                <li>Endangered species: - which includes plants, animals or
                                                                    other organisms (including Product derivatives) in danger of
                                                                    extinction.</li>
                                                                <li>
                                                                    Government IDs or documents: - which includes fake IDs,
                                                                    passports, diplomas, and noble titles.</li>
                                                                <li>
                                                                    Hacking and cracking materials: - which include manuals,
                                                                    how-to guides, information, or equipment enabling illegal
                                                                    access to software, servers, websites, or other protected
                                                                    property.</li>
                                                                <li>
                                                                    Illegal goods: - which includes materials, products, or
                                                                    information promoting illegal goods or facilitating illegal
                                                                    acts.
                                                                </li>
                                                                <li>
                                                                    Miracle cures: - which includes unsubstantiated cures,
                                                                    remedies or other items marketed as quick health fixes.
                                                                </li>
                                                                <li className="pb-0 mb-0">Offensive goods: - which includes
                                                                    literature,
                                                                    products, or
                                                                    other materials that:</li>
                                                                <ul className="ul-liststyle protection-policy">
                                                                    <li>Defame or slander any person or groups of people based
                                                                        on race, caste, ethnicity/colour, national origin,
                                                                        religion, sex, political opinions or other factors.</li>
                                                                    <li>Encourage or incite violent acts.</li>
                                                                    <li>Promote intolerance or hatred.</li>
                                                                    <li>May incite crime, which includes crime scene photos, or
                                                                        items such as personal </li>
                                                                    <li>May incite crime, which includes crime scene photos, or
                                                                        items such as personal belongings associated with
                                                                        criminals. </li>
                                                                </ul>
                                                                <li>Drugs or herbal drugs or any kind requiring prescription
                                                                    from online pharmacies: - which includes drugs or other
                                                                    Products requiring prescription by a licensed medical
                                                                    practitioner.</li>
                                                                <li>Pyrotechnic devices, combustibles, corrosives and hazardous
                                                                    materials: - which includes explosives, fireworks and
                                                                    related goods; toxic, flammable, and radioactive materials
                                                                    and substances.</li>
                                                                <li>Regulated goods: - which includes air bags; batteries
                                                                    containing Mercury; Freon or similar refrigerants; chemical
                                                                    and industrial solvents; government uniforms; car titles;
                                                                    license plates; police badges and law enforcement equipment;
                                                                    lock-picking devices; pesticides; postage meters; recalled
                                                                    items, slot machines; surveillance equipment; and Products
                                                                    and Services regulated by governments or regulatory
                                                                    agencies.</li>
                                                                <li>
                                                                    Traffic devices: - which includes radar detectors, jammers,
                                                                    license plate covers, traffic signal changers, and related
                                                                    Products.</li>
                                                                <li>Weapons: - which includes firearms, ammunition, knives,
                                                                    brass knuckles, gun parts, and other armaments.</li>
                                                                <li>
                                                                    Live animals or hides, skins, teeth, nails and other parts
                                                                    of animals.</li>
                                                                <li>
                                                                    Multi-level marketing and network marketing collection fees.
                                                                </li>
                                                                <li>Matrix sites or sites using a matrix scheme approach</li>
                                                                <li>Work-at-home approach and work-at-home information.</li>
                                                                <li>Any Product or Service that is not in compliance with all
                                                                    applicable laws and regulations whether federal, state,
                                                                    local or international including the laws of London.</li>
                                                                <li>Products and Services outrightly banned by law (e.g. betting
                                                                    and gambling/publications or content that is likely to be
                                                                    interpreted by the authorities as leading to moral turpitude
                                                                    or decadence, or incite caste/communal tensions, lotteries,
                                                                    sweepstakes and games of chance.</li>
                                                                <li>Intangible products and services (e.g. software downloads,
                                                                    self help and education materials).</li>
                                                                <li>Pyramid marketing schemes or any type of get-rich-quick
                                                                    schemes. </li>
                                                                <li>Voice process, knowledge process services.</li>
                                                                <li>Mailing lists.</li>
                                                                <li>Computer software (e.g. anti-virus) sold via inaccurate
                                                                    advertisements.</li>
                                                                <li>Trafficking in persons (TIPS).</li>
                                                                <li>Hawking Likes and Followers; selling fake accounts; fake
                                                                    reviews, fake ratings, backlinks and other manipulative
                                                                    marketing strategies in order to boost traffic.</li>
                                                                <li>
                                                                    Advanced payments greater than 1 year (any product or
                                                                    service that will be delivered partially or fully one year
                                                                    or more from the initial payment).</li>
                                                                <li>Bestiality.</li>
                                                                <li>Payday loans – Non-licensed by an EU authority.</li>
                                                                <li>Unauthorized ticket sellers.</li>
                                                                <li>Spy products and services are not allowed, excluding parent
                                                                    control related products or services.</li>
                                                                <li>Merchants engaged in activity prohibited by the Card Brands.
                                                                </li>
                                                                <li>Non-consensual sexual behavior of any kind, whether real or
                                                                    simulated.</li>
                                                                <li>Any other Product or Service, which in the sole opinion of
                                                                    FINSAI is detrimental to the image and interests of any of
                                                                    them, as communicated by any of them to Merchant from time
                                                                    to time.</li>
                                                            </ol>
                                                            <br />
                                                            <p>Engagement with industries deemed illegal or unethical will be prohibited this is a non-
                                                                exhaustive list.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer />
                <Model />
            </main>
        </>
    )
}

export default AMLPolicyProcedure