import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"


const TermsConditions = () => {
    const [scroll, setScroll] = useState(true)

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="terms-title-sec">
                    <div className="container">
                        <p className="title mb-0">Terms & Conditions</p>
                    </div>
                </section>
                <section className="terms-content-sec">
                    <div className="container-fluid">
                        <div className="row content-box">
                            <div className=" col-xl-4 col-lg-4 col-md-5">
                                <div className="nav custom-tabs flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <button className="nav-link " id="v-pills-tab1" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        <p className="num mb-0">1</p>
                                        <p className="mb-0 text">Application scope</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-2"
                                        type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        <p className="num mb-0">2</p>
                                        <p className="mb-0 text"> Definitions</p>

                                    </button>
                                    <button className="nav-link" id="v-pills-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-3"
                                        type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        <p className="num mb-0">3</p>
                                        <p className="mb-0 text">Services</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-4"
                                        type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        <p className="num mb-0">4</p>
                                        <p className="mb-0 text">Eligibilty</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-5"
                                        type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                        <p className="num mb-0">5</p>
                                        <p className="mb-0 text">Account establishment</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab6" data-bs-toggle="pill" data-bs-target="#v-pills-6"
                                        type="button" role="tab" aria-controls="v-pills-6" aria-selected="false">
                                        <p className="num mb-0">6</p>
                                        <p className="mb-0 text">Use of the services</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab7" data-bs-toggle="pill" data-bs-target="#v-pills-7"
                                        type="button" role="tab" aria-controls="v-pills-7" aria-selected="false">
                                        <p className="num mb-0">7</p>
                                        <p className="mb-0 text">Commission</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab8" data-bs-toggle="pill" data-bs-target="#v-pills-8"
                                        type="button" role="tab" aria-controls="v-pills-8" aria-selected="false">
                                        <p className="num mb-0">8</p>
                                        <p className="mb-0 text">Customer verification/ Due diligence</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab9" data-bs-toggle="pill" data-bs-target="#v-pills-9"
                                        type="button" role="tab" aria-controls="v-pills-9" aria-selected="false">
                                        <p className="num mb-0">9</p>
                                        <p className="mb-0 text">Account security</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab9" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-10" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">10</p>
                                        <p className="mb-0 text">Unauthorised transactions</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab11" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-11" type="button" role="tab" aria-controls="v-pills-11"
                                        aria-selected="false">
                                        <p className="num mb-0">11</p>
                                        <p className="mb-0 text">Fraud liability</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab12" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-12" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">12</p>
                                        <p className="mb-0 text">Privacy</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab13" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-13" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">13</p>
                                        <p className="mb-0 text">Risk disclosure</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab14" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-14" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">14</p>
                                        <p className="mb-0 text">Taxation</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab15" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-15" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">15</p>
                                        <p className="mb-0 text">Disclaimer of warranties</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab16" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-16" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">16</p>
                                        <p className="mb-0 text">Limitation of liability; waivers</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab17" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-17" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">17</p>
                                        <p className="mb-0 text">Intellectual property and Third-party content</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab18" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-18" type="button" role="tab" aria-controls="v-pills-18"
                                        aria-selected="false">
                                        <p className="num mb-0">18</p>
                                        <p className="mb-0 text">License</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab19" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-19" type="button" role="tab" aria-controls="v-pills-19"
                                        aria-selected="false">
                                        <p className="num mb-0">19</p>
                                        <p className="mb-0 text">Dormant accounts</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab20" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-20" type="button" role="tab" aria-controls="v-pills-20"
                                        aria-selected="false">
                                        <p className="num mb-0">20</p>
                                        <p className="mb-0 text">Suspension, Closure and Termination</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab21" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-21" type="button" role="tab" aria-controls="v-pills-20"
                                        aria-selected="false">
                                        <p className="num mb-0">21</p>
                                        <p className="mb-0 text">Governing Law and Jurisdiction</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab21" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-22" type="button" role="tab" aria-controls="v-pills-20"
                                        aria-selected="false">
                                        <p className="num mb-0">22</p>
                                        <p className="mb-0 text">Miscellaneous</p>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-7 bg-lightblue d-flex" >

                                <div className="tab-content" id="v-pills-tabContent">

                                    <div className="tab-pane fade active show h-100" id="v-pills-default" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="terms-para">

                                                    <p className="mb-0">PLEASE READ THE TERMS & CONDITIONS SET OUT HEREIN (THIS
                                                        “AGREEMENT”)
                                                        CAREFULLY BEFORE
                                                        ACCEPTING THEM AND USING THE SERVICES PROVIDED BY US. YOU AGREE THAT ANY
                                                        USE OF OUR
                                                        SERVICES BY YOU SHALL CONSTITUTE YOUR ACCEPTANCE OF THIS AGREEMENT. IF
                                                        YOU DO NOT
                                                        ACCEPT
                                                        THE TERMS AND CONDITIONS OUTLINED IN THIS AGREEMENT, DO NOT ACCESS OR
                                                        USE FINSAI
                                                        SERVICES.</p>

                                                </div>
                                                <div className="content-card">
                                                    {/* <p className="mb-0">ALLOY PAY Limited is a legal entity (13858479) trading as
                                                        FINSAI. The
                                                        activity of Finsai is regulated by the Payment Services Regulation 2017
                                                        and the
                                                        Electronic Money Regulation 2011 (SI 2011/99). This Agreement is an
                                                        electronic
                                                        agreement between you and FINSAI . This Agreement emphasizes certain
                                                        risks
                                                        associated with the use of our services. This Agreement is made in
                                                        English and the
                                                        English version of the Agreement prevails over any other version
                                                        translated into any
                                                        other language, which may be provided by us to you for convenience
                                                        purposes.</p> */}
                                                    <p className="mb-0">The
                                                        activity of Finsai is regulated by the Payment Services Regulation 2017
                                                        and the
                                                        Electronic Money Regulation 2011 (SI 2011/99). This Agreement is an
                                                        electronic
                                                        agreement between you and FINSAI . This Agreement emphasizes certain
                                                        risks
                                                        associated with the use of our services. This Agreement is made in
                                                        English and the
                                                        English version of the Agreement prevails over any other version
                                                        translated into any
                                                        other language, which may be provided by us to you for convenience
                                                        purposes.</p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-1" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Application Scope</p>
                                                <div className="content-card">
                                                    <p className="mb-0">This Agreement and our products and services are not
                                                        directed at
                                                        residents of
                                                        Afghanistan, Albania, Bahamas, Barbados, Botswana, Cambodia, Democratic
                                                        Republic of Korea (North Korea), Ghana, Iran, Iraq, Jamaica, Mauritius,
                                                        Mongolia, Myanmar, Nicaragua, Pakistan, Panama, Syria, Trinidad &
                                                        Tobago,
                                                        Uganda, Vanuatu, Yemen, Zimbabwe (hereinafter: “Restricted Countries”),
                                                        and
                                                        is not intended for distribution to, or use by, any person in any
                                                        country or
                                                        jurisdiction where such distribution or use would be contrary to local
                                                        law
                                                        or regulation. It is the responsibility of users of our Services to
                                                        ascertain the terms of, and comply with, any local law or regulation to
                                                        which they are subject. Certain partial restrictions may apply to
                                                        additional
                                                        geographic areas, as shall be notified by us from time to time. The
                                                        Restricted Countries list may change from time to time for reasons which
                                                        include but are not limited to licensing requirements and any other
                                                        legal
                                                        and regulatory changes.</p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-2" role="tabpanel"
                                        aria-labelledby="v-pills-profile-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Definitions</p>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Account</p>
                                                    <p className="mb-0">“Account” shall mean a record created and maintained by the
                                                        Software that
                                                        accumulates and stores all information about the Customer, including
                                                        without limitation Transactional history, e-Money balance, and the
                                                        Customer personal information.</p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Commission</p>
                                                    <p className="mb-0">“Commission” shall mean a percentage of the Transaction
                                                        Price, which is payable by you to FINSAI as a fee for the Services.</p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Customer</p>
                                                    <p className="mb-0">“Customer” shall mean a person using the Services, including
                                                        you (hereinafter: “you” or “your”).</p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">e-Money</p>
                                                    <p className="mb-0">“e-Money” shall have the meaning prescribed to it by the
                                                        Electronic Money Regulation 2011 (SI 2011/99).</p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Force Majeure</p>
                                                    <p className="mb-0">“Force Majeure” shall mean any act or event beyond FINSAI's
                                                        reasonable control, including without limitation non-delivery or
                                                        defective delivery of third party services necessary to provide the
                                                        Services (including but not limited to those of our partners, vendors
                                                        and suppliers), strike, lock-out or other industrial action by third
                                                        parties, civil commotion, riot, invasion, terrorist activity or threat
                                                        thereof, war (whether declared or not) or threat or preparation for war,
                                                        fire, explosion, lightning storm, flood, earthquake, subsidence,
                                                        epidemic or other natural disaster, failure of public or private
                                                        telecommunications networks, power failure, and acts of government or
                                                        other competent authorities.</p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Funding Source</p>
                                                    <p className="mb-0">“Funding Source” shall mean your bank card or a bank
                                                        account, or an account open with a payment services provider integrated
                                                        with FINSAI . The list of such providers is available at&nbsp;
                                                        <a className="link" href="www.FINSAI.co.uk">www.FINSAI.co.uk</a>
                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Website</p>
                                                    <p className="mb-0">“Website” shall mean the website <a className="link"
                                                        href="www.FINSAI.co.uk">www.FINSAI.co.uk</a> and/or the
                                                        FINSAI mobile application, including their data and content.

                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Services</p>
                                                    <p className="mb-0">“Services” shall have the meaning given in Clause 3 of this
                                                        Agreement.

                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Software</p>
                                                    <p className="mb-0">“Software” shall mean a software owned or licensed by FINSAI
                                                        and enabling Transactions’ processing.

                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Transaction</p>
                                                    <p className="mb-0">“Transaction” shall mean any of the following:
                                                        (i) Purchase of our e-Money by the Customer;
                                                        (ii) Redemption of our e-Money by the Customer;
                                                        (iii)Foreign exchange.

                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="text-black font_20 font_600 mb-2">Transaction Price</p>
                                                    <p className="mb-0">“Transaction Price” shall mean the total amount paid by the
                                                        Customer for any Transaction performed through the Services denominated
                                                        in any currency.

                                                    </p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-3" role="tabpanel"
                                        aria-labelledby="v-pills-messages-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Services</p>
                                                <div className="content-card">
                                                    <p>FINSAI provides you with access to a web-platform for buying FINSAI e-Money,
                                                        transferring e-Money to other Customers or to a bank account, using
                                                        e-money to pay merchants for goods and services, and convert between
                                                        various currencies.</p>
                                                    <p>Your currency used or generated in the course of the Transactions will be
                                                        stored on a segregated bank account open and maintained by FINSAI .</p>
                                                    <p>Your Account is not a bank account and it does not generate interest.</p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-4" role="tabpanel"
                                        aria-labelledby="v-pills-settings-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Eligibility</p>
                                                <div className="content-card">
                                                    <p className="black_21 mb-2 gap-2 d-flex align-items-center font_600">
                                                        <img className="icon" src="assets/images/icon/star-icon.png" alt="icon" />
                                                        To use the Services, you:
                                                    </p>
                                                    <div className="d-flex gap-2">
                                                        <p>1.</p>
                                                        <p>must be at least 18 years old (and at least the legal age in your
                                                            jurisdiction), be of sound mind and capable of taking responsibility
                                                            for your own actions;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>2.</p>
                                                        <p>must establish the Account in accordance with the instructions given
                                                            in the Website, including indicating your consent to be bound by
                                                            this Agreement;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>3.</p>
                                                        <p>must not be in violation of any of the provisions of this Agreement
                                                            or otherwise have a limited access to your Account, or have your
                                                            Account closed by FINSAI;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>4.</p>
                                                        <p>must not be a resident of any of the Restricted Countries, specified
                                                            in Clause 1 above;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>5.</p>
                                                        <p>confirm that all details provided by you to us, either during the
                                                            registration process or at any time afterwards (including as part of
                                                            any use of the Services), are true, current, accurate, complete and
                                                            not misleading and, as appropriate, match the name(s) on the
                                                            credit/debit card(s) or other payment accounts to be used to
                                                            transfer or receive currency. Any attempt to use other means of
                                                            payment shall be considered fraudulent;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>6.</p>
                                                        <p>confirm that your Account with FINSAI is solely for your benefit and
                                                            that your use of the Services and the Website is at your option,
                                                            discretion and risk;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>7.</p>
                                                        <p>confirm that you are responsible for the security of your private
                                                            key, username and password on your own PC or internet access
                                                            location. If this username password combination is “hacked” from
                                                            your computer due to any viruses or malware that is present on the
                                                            computer you use to access your account with, this is your sole
                                                            responsibility. You should report this incident immediately to
                                                            FINSAI any possible hacking attempts or security breaches from your
                                                            computer terminal;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>8.</p>
                                                        <p>confirm that you are solely responsible for recording, paying and
                                                            accounting to any relevant governmental, taxation or other authority
                                                            for any tax or other levy that may be payable on any amounts
                                                            transferred to you or which you receive with respect to your use of
                                                            the Services;</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>9.</p>
                                                        <p>

                                                            confirm that you are responsible for obtaining access for your
                                                            device to telecommunications networks and the Internet and for
                                                            acquiring any other consents and permissions required for your
                                                            device to connect with the Website and receive the Services; and
                                                        </p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>10.</p>
                                                        <p>

                                                            confirm that you may not use the Website or the Services for any
                                                            unlawful, criminal or fraudulent activity or any prohibited
                                                            transaction (including money laundering) under the laws of any
                                                            applicable jurisdiction.
                                                        </p>
                                                    </div>


                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 mb-2 gap-2 d-flex align-items-center font_600">
                                                        <img className="icon" src="assets/images/icon/star-icon.png" alt="icon" />
                                                        Complaints Handling
                                                    </p>
                                                    <div className="d-flex gap-2">
                                                        <p>1.</p>
                                                        <p>
                                                            If you wish to make a complaint about the Services, you can email us
                                                            at <a className="link" href="mailto: cs@FINSAI .co.uk?subject=Complaint about the services&body=Description">cs@FINSAI .co.uk</a>,
                                                            with brief details of your complaint and the
                                                            phone number and email address associated with your Account.
                                                        </p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>2.</p>
                                                        <p>
                                                            If the complaint is not resolved by the end of 3 (three) business
                                                            days after received, we will initiate a formal complaint handling
                                                            procedure. We will acknowledge your complaint by email within 5
                                                            (five) business days. The email will contain details of the
                                                            complaints handling procedure and your right to refer the complaint
                                                            to the Financial Ombudsman Service (“FOS”) if you are dissatisfied
                                                            with our assessment and ruling.
                                                        </p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>3.</p>
                                                        <p>
                                                            We will send you a final response to your complaint within 15
                                                            (fifteen) days from receipt of complaints about rights and
                                                            obligations arising under Parts 6 and 7 of the Payment Services
                                                            Regulations 2017 or within 8 (eight) weeks from receipt of
                                                            complaints not related to rights and obligations arising under Parts
                                                            6 and 7 of the Payment Services Regulations 2017.
                                                        </p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>4.</p>
                                                        <p>
                                                            A complaint about any regulated aspect of the Services that we
                                                            cannot resolve within the terms specified in Clause 4.2(iii), such
                                                            complaint may be referred to the FOS at The Financial Ombudsman
                                                            Service, South Quay Plaza, 183 Marsh Wall, London E14 9SR, United
                                                            Kingdom (further details can be found at&nbsp;
                                                            <a className="link"
                                                                href="www.financial-ombudsman.org.uk">www.financial-ombudsman.org.uk</a>).
                                                        </p>
                                                    </div>



                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br />
                                                terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-5" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Account establishment</p>
                                                <div className="content-card">
                                                    <p className="mb-3">To use the Services, you must apply for an Account and
                                                        provide the information requested on the registration page of the
                                                        Website. You must not provide any false, inaccurate, incomplete or
                                                        misleading information. You must notify FINSAI immediately upon any
                                                        change of the information provided for the Account registration by
                                                        sending an email at <a href="mailto: support@FINSAI .co.uk?subject=Account establishment&body=Description"
                                                            className="link">support@FINSAI .co.uk.</a></p>
                                                    <p className="black_21">By applying for an Account and using the Services, you:
                                                    </p>
                                                    <div className="d-flex gap-2">
                                                        <p>1.</p>
                                                        <p>agree and accept without any reservation the terms and conditions of
                                                            this Agreement (as may be amended from time to time), the FINSAI
                                                            privacy policy and any other applicable documents, requirements,
                                                            instructions or procedures prescribed by the Website; and</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <p>2.</p>
                                                        <p>You declare and confirm that you are eligible to use the Services in
                                                            accordance with Clause 4 above and you are authorized to accept this
                                                            Agreement and be bound by its terms.</p>
                                                    </div>
                                                    <p>It remains in our sole discretion whether we open an Account to you. If
                                                        your application is approved, we will advise you as soon as possible.
                                                    </p>
                                                    <p>You may only have one Account. Any attempt to create more than one
                                                        Account will be considered as a fraudulent behaviour and will entail
                                                        consequences described in Clause 20 of this Agreement.</p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-6" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Use of the services</p>
                                                <div className="content-card">
                                                    <p>You will be able to use our Services immediately upon your Account
                                                        activation.</p>
                                                    <p>UNLESS OTHERWISE PROVIDED BY THIS AGREEMENT, ALL TRANSACTIONS ARE
                                                        NON-REFUNDABLE, IRREVOCABLE AND CANNOT BE CHANGED ONCE COMPLETED. You
                                                        have to ensure to provide FINSAI with the precise and correct
                                                        instructions related to a Transaction initiated by you. FINSAI is not
                                                        liable for an outcome of any Transaction performed under incorrect or
                                                        misleading information provided by you.</p>

                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">1. Types of Transactions</p>
                                                    <p> You may buy e-Money issued by FINSAI by transferring the relevant amount
                                                        of funds from your Funding Source to your Account. The e-Money balance
                                                        will be available to you immediately upon completion of the Transaction.
                                                        You will be able to use our e-Money to pay for goods and services of
                                                        various merchants, complete foreign exchange operations, perform
                                                        peer-to-peer transfers, and receive debit/credit card payments for the
                                                        sale of goods and services online.</p>
                                                    <p>Unless otherwise provided by this Clause 6, you may perform Transactions
                                                        anytime by sending the relevant instructions through the Website and by
                                                        funding the Transactions using your e-Money balance or your Funding
                                                        Source. Use of a third-party Funding Source for funding your Transaction
                                                        is prohibited.</p>
                                                    <p>Unless otherwise provided by this Clause 6, you may exchange any currency
                                                        to our e-Money or another currency by requesting the relevant service
                                                        via the Website. The funds will be deposited to your Account or to your
                                                        Funding Source respectively. FINSAI reserves the right to retain any
                                                        funds if FINSAI reasonably suspects that such Funding Source does not
                                                        belong to you.</p>
                                                    <p>For security purposes, FINSAI will have to verify that the Funding Source
                                                        linked to your Account belongs to you. You will not be able to perform
                                                        any Transaction within the first three business days following the day
                                                        when your Funding Source has been linked to your Account. This rule will
                                                        apply to each new Funding Source that you link to your Account.</p>
                                                    <p className="black_21 font_600 pt-3">2. Transactional Thresholds</p>
                                                    <p>Upon registration of an Account, you will be able to perform Transactions
                                                        within certain amounts according to the limits established by FINSAI and
                                                        the applicable law. You will be able to provide some additional
                                                        information as instructed by FINSAI in order to increase or eliminate
                                                        these limits.</p>
                                                    <p className="black_21 font_600 pt-3">3. Acceptable Use</p>
                                                    <p>When accessing or using the Services, you agree that you will not violate
                                                        any law, contract, intellectual property or other third-party right or
                                                        commit a tort, and that you are solely responsible for your conduct
                                                        while using the Services. Without limiting the generality of the
                                                        foregoing, you agree that you will not:</p>
                                                    <ul className="ul-liststyle pt-4">
                                                        <li>use the Services in any manner that could interfere with, disrupt,
                                                            negatively affect or inhibit other users from fully enjoying our
                                                            Services, or that could damage, disable, overburden or impair the
                                                            functioning of our Services in any manner;</li>
                                                        <li>use the Services to pay for, support or otherwise engage in any
                                                            illegal gambling activities, fraud, money-laundering, terrorist
                                                            activities, or other illegal activities;</li>
                                                        <li>use any robot, spider, crawler, scraper or other automated means or
                                                            interface not provided by FINSAI to access the Services or to
                                                            extract data;</li>
                                                        <li>use or attempt to use another Customer's Account without
                                                            authorization;</li>
                                                        <li>attempt to circumvent any content filtering techniques we employ, or
                                                            attempt to access any service or area of our Services that you are
                                                            not authorized to access;</li>
                                                        <li>develop any third-party applications that interact with the Services
                                                            without our prior written consent; and</li>
                                                        <li>provide false, inaccurate, or misleading information.</li>
                                                    </ul>

                                                    <p className="black_21 font_600 pt-3">4. Declining the Transactions</p>
                                                    <p>FINSAI may refuse to process a Transaction, if:</p>
                                                    <ul className="ul-liststyle pt-4">
                                                        <li>Your Funding Source does not have sufficient funds at the time of a Transaction;</li>
                                                        <li>You fail to pay any outstanding amounts due to FINSAI;</li>
                                                        <li>We have reasonable grounds to believe that you are acting in breach of this
                                                            Agreement;</li>
                                                        <li>We believe that a Transaction is suspicious or potentially illegal (for example, if we
                                                            believe that a Transaction is being made fraudulently or by using the Software flaws),
                                                            or otherwise violates this Agreement; or</li>
                                                        <li>There are errors or failures, whether mechanical or otherwise.</li>

                                                    </ul>
                                                    <p>If we refuse to process a Transaction because we think it is suspicious or potentially illegal, we will contact you by phone, if we are permitted to do so under applicable law. If we refuse to process a Transaction for any other reason, we will inform you by email without undue delay and in any event by the end of the next business day, giving our reasons and explaining how you can correct those reasons, unless informing you would compromise security measures or be unlawful.</p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-7" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Commission</p>
                                                <div className="content-card">
                                                    <p>Commissions applicable to your Account are set out in the Fees section of
                                                        the Website. FINSAI reserves the right to change the Commissions anytime
                                                        in its sole discretion without providing a prior notification to you.
                                                    </p>
                                                    <p>The Commission amounts will be deducted from your Funding Source and
                                                        settled immediately after the corresponding Transaction is completed.
                                                    </p>

                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-8" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Customer verification / Due diligence</p>
                                                <div className="content-card">
                                                    <p>You will not be able to use some of the Services until you have passed
                                                        our identity and security validation and verification checks, and
                                                        provided information and documents requested in accordance with the
                                                        applicable anti-money laundering regulations. We may check all personal
                                                        and identity verification information you provide FINSAI with credit
                                                        reference or fraud prevention agencies and other organizations. These
                                                        agencies may keep a record of your information and the searches made.
                                                        However, we do not perform a credit check and any search is for identity
                                                        purposes only and will be recorded as such. We shall keep records of the
                                                        information and documents we obtain to verify your identity in
                                                        accordance with all applicable legal and regulatory requirements.
                                                    </p>
                                                    <p>For the purposes of anti-money laundering prevention and combatting
                                                        terrorist activities, FINSAI reserves the right to request any
                                                        additional information and documents from you about you and/or your
                                                        Transactions, and suspend your access to your Account anytime in case
                                                        the documents or information provided by you are unsatisfactory or
                                                        insufficient, as FINSAI decides in its sole discretion.
                                                    </p>
                                                    <p>Should the documents fail our internal identity or security checks e.g, if we suspect that you have been tampered with, or are
                                                        in any way proven to be misleading or misrepresenting – we shall be
                                                        under no obligation to accept such documents as valid, and under no
                                                        obligation to provide feedback on the exact nature of our findings with
                                                        regards to these documents. In addition, we may request that you attend
                                                        a video call to verify your identity.</p>
                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-9" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Account security</p>
                                                <div className="content-card">
                                                    <p>It is your responsibility to keep your identification, security
                                                        information, password, security questions and answers and other
                                                        information specific to your Account confidential and never disclose
                                                        them to anyone. Should another person gain access to your Account by
                                                        passing all security validation and verification checks, FINSAI may be
                                                        entitled to treat any Transaction conducted by that person as valid and
                                                        authorised by you and will not be responsible for any loss or damage you
                                                        may incur as a result.
                                                    </p>
                                                    <p>Your Account access will be protected by one of the verification
                                                        mechanisms allowed by FINSAI and chosen by you in the course of the
                                                        Account application. You will be able to activate an additional
                                                        verification mechanism to ensure your Account is protected from
                                                        unauthorized access and use. For instance, if you chose to use a
                                                        password or a social network account log in as an authorization
                                                        mechanism, you may activate an SMS verification that will request you to
                                                        enter an SMS-code sent by FINSAI every time before accessing the
                                                        Account. Activating an additional verification instrument is advisable
                                                        to strengthen your Account’s security.
                                                    </p>
                                                    <p>If you have any indication or suspicion that your Account, password, or
                                                        other security details are lost, stolen, misappropriated, used without
                                                        authorisation or otherwise compromised, you should change your password
                                                        and contact FINSAI immediately by sending an email at&nbsp;
                                                        <a href="mailto: antifraud@FINSAI.co.uk?subject=Account security&body=Description"
                                                            className="link">antifraud@FINSAI.co.uk</a>.
                                                        Any delay in notifying
                                                        FINSAI will result in you
                                                        being liable for any and all losses.
                                                    </p>
                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-10" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Unauthorised transactions</p>
                                                <div className="content-card">
                                                    <p>If you think a processed Transaction is unauthorised or has been
                                                        incorrectly executed, you must notify FINSAI immediately via email at&nbsp;
                                                        <a href="mailto: antifraud@FINSAI.co.uk?subject=Unauthorized transactions&body=Description" className="link">
                                                            antifraud@FINSAI.co.uk</a>. We will investigate the circumstances,
                                                        and, if
                                                        you ask FINSAI to, will make efforts to trace the Transaction
                                                        immediately and will notify you of the outcome.
                                                    </p>
                                                    <p>If you believe that you did not authorise a particular Transaction, or
                                                        that a Transaction or deduction was incorrectly executed:
                                                    </p>
                                                    <ul className="ul-liststyle">
                                                        <li>You must contact us without undue delay (i.e. as soon as you become
                                                            aware of the problem). We will not be held liable for any
                                                            Transaction that was unauthorised or incorrectly executed if you
                                                            have failed to contact us without undue delay – in these
                                                            circumstances you may be held liable for the entire loss of the
                                                            Transaction. You should also describe the disputed Transaction in
                                                            writing, setting out full details of the Transaction and your reason
                                                            for disputing it.</li>
                                                        <li>
                                                            You must provide us with all receipts and information that are
                                                            relevant to your claim.</li>
                                                    </ul>
                                                    <p>
                                                        Subject to you notifying us without undue delay, we shall refund to you the amount of any
                                                        Transaction not authorised by you immediately.
                                                    </p>
                                                    <p>If we subsequently establish that the Transaction was authorised by you,
                                                        or you have acted fraudulently or have with intent or gross negligence
                                                        failed to keep your Account’s security details safe, we may deduct the
                                                        amount of the Transaction from your Account balance. If you do not have
                                                        sufficient Account balance for the deduction, you must repay us the
                                                        amount immediately on demand.</p>
                                                    <p>If you have acted fraudulently or have with intent or gross negligence
                                                        failed to keep your Account security details safe, you will also be
                                                        liable for all losses we suffer in connection with the unauthorised
                                                        Transaction, including but not limited to the cost of any investigation
                                                        we carry out in relation to the Transaction.</p>
                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-11" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Fraud liability </p>
                                                <div className="content-card">
                                                    <p>If our investigation shows that a Transaction reported by you as
                                                        unauthorised or incorrectly executed was in fact authorised by you or
                                                        correct, or you have acted fraudulently or with gross negligence (for
                                                        example, by failing to keep your security information secure), or you
                                                        performed a Transaction by using the Software flaws, FINSAI may, in its
                                                        sole discretion, cancel the Transaction and restore your Account to the
                                                        state it would have been if the Transaction had not taken place, and/or
                                                        terminate this Agreement, close your Account, and deny you from using
                                                        the Services in the future. Depending on the particular circumstances,
                                                        FINSAI may decide to submit information about the Transaction and your
                                                        related activities to the relevant state authorities for further
                                                        investigation.
                                                    </p>

                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-12" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Privacy </p>
                                                <div className="content-card">
                                                    <p>You agree that FINSAI can use your personal information in accordance
                                                        with our privacy policy. This consent is not related to, and does not
                                                        affect, any rights or obligations we or you have in accordance with data
                                                        protection laws and regulations. You can withdraw your consent to us
                                                        providing the Services to you at any time by closing your account with
                                                        us. However, we may retain and continue to process your personal
                                                        information for other purposes.
                                                    </p>
                                                    <p>Our privacy policy is available on the Website and provides details of
                                                        your personal information that we collect, how such personal information
                                                        is used, and who we share it with.</p>
                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-13" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Risk disclosure </p>
                                                <div className="content-card">
                                                    <p>For the avoidance of doubt, you acknowledge and agree that FINSAI does
                                                        not act as a financial advisor, does not provide any investment advice
                                                        in connection with the Services contemplated by this Agreement, and any
                                                        communication between you and FINSAI cannot be considered as an
                                                        investment advice. Without prejudice to our foregoing obligations, in
                                                        asking us to enter into any Transaction, you represent that you have
                                                        been solely responsible for making your own independent appraisal and
                                                        investigations into the risks of the Transaction. You represent that you
                                                        have sufficient knowledge, market sophistication and experience to make
                                                        your own evaluation of the merits and risks of any Transaction and that
                                                        you received professional advice thereon. We give you no warranty as to
                                                        the suitability of the Services and assume no fiduciary duty in our
                                                        relations with you.
                                                    </p>

                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-14" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Taxation </p>
                                                <div className="content-card">
                                                    <p>FINSAI makes no representations concerning the tax implications of the
                                                        Transactions or the possession or use of the Account. The Customer bears
                                                        the sole responsibility to determine if any currency or potential
                                                        appreciation or depreciation in the value of such currency over time has
                                                        tax implications for the Customer in the Customer’s home jurisdiction.
                                                        By using the Services, and to the extent permitted by law, the Customer
                                                        agrees not to hold FINSAI liable for any tax liability associated with
                                                        or arising from the Customer’s use of the Services.
                                                    </p>

                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-15" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Disclaimer of warranties </p>
                                                <div className="content-card">
                                                    <p>YOU EXPRESSLY AGREE THAT YOU ARE USING THE SERVICES AT YOUR SOLE RISK AND
                                                        THAT THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF
                                                        ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                                                        WARRANTIES OF TITLE OR IMPLIED WARRANTIES, MERCHANTABILITY OR FITNESS
                                                        FOR A PARTICULAR PURPOSE (EXCEPT ONLY TO THE EXTENT PROHIBITED UNDER THE
                                                        APPLICABLE LAW), COMPLETENESS AND/OR ACCURACY OF THE WEBSITE AND/OR THE
                                                        SERVICES AND/OR INFRINGEMENT OF APPLICABLE LAWS AND REGULATIONS. THE
                                                        ENTIRE RISK AS TO THE USE, QUALITY AND PERFORMANCE OF THE SERVICES
                                                        AND/OR WEBSITE LIES WITH YOU.
                                                    </p>

                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-16" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Limitation of liability; waivers </p>
                                                <div className="content-card">
                                                    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, FINSAI MAKES NO
                                                        WARRANTY THAT THE WEBSITE AND/OR SERVICES WILL MEET YOUR REQUIREMENTS,
                                                        WILL BE UNINTERRUPTED OR ERROR-FREE, TIMELY OR SECURE, THAT DEFECTS WILL
                                                        BE CORRECTED, AND/OR THAT WEBSITE AND THE SERVER THAT MAKES IT AVAILABLE
                                                        ARE FREE OF VIRUSES AND BUGS AND/OR REPRESENTS THE FULL FUNCTIONALITY,
                                                        ACCURACY, RELIABILITY OF THE MATERIALS AND/OR AS TO RESULTS OR THE
                                                        ACCURACY OF ANY INFORMATION OBTAINED BY YOU THROUGH THE SERVICES AND/OR
                                                        WEBSITE.
                                                    </p>
                                                    <p>FINSAI HAS NO OBLIGATION TO MAINTAIN YOUR ACCOUNT NAME OR PASSWORD. IF
                                                        YOU MISPLACE, FORGET OR LOSE YOUR ACCOUNT NAME OR PASSWORD BECAUSE OF
                                                        ANYTHING OTHER THAN PAT SET'S GROSS NEGLIGENCE OR WILFUL MISCONDUCT,
                                                        FINSAI SHALL NOT BE LIABLE.</p>
                                                    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
                                                        FINSAI , ITS AFFILIATES AND/OR THEIR RESPECTIVE SERVICE PROVIDERS,
                                                        AND/OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT
                                                        VENTURERS, EMPLOYEES AND THEIR REPRESENTATIVES, BE LIABLE TO YOU OR
                                                        ANYONE ON YOUR BEHALF, FOR ANY DIRECT, CONSEQUENTIAL, INDIRECT,
                                                        INCIDENTAL OR SPECIAL DAMAGE OR LOSS OF ANY KIND WHATSOEVER; AND/OR ANY
                                                        LOSS OF BUSINESS, PROFITS, REVENUES, CONTRACTS OR ANTICIPATED SAVINGS;
                                                        AND/OR LOSS OR ANY DAMAGE ARISING FROM YOUR USE OF THE WEBSITE OR
                                                        SERVICES, ALL WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
                                                        LIABILITY OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED
                                                        OR UNAUTHORIZED USE OF THE WEBSITE AND/OR SERVICES.</p>
                                                    <p>YOU AGREE THAT ANY CLAIM OR CAUSE OF ACTION WHICH YOU MAY HAVE ARISING
                                                        OUT OF OR RELATED TO THE USE OF THE WEBSITE, SERVICES, OR TO THIS
                                                        AGREEMENT MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF
                                                        ACTION AROSE OR BE FOREVER BARRED.</p>
                                                    <p>WE SHALL NOT BE LIABLE TO YOU IN ANYWAY WHATSOEVER FOR THE TRANSFER OF
                                                        ANY AND ALL CURRENCY IF YOU PROVIDE US WITH ANY INCORRECT AND/OR
                                                        INCOMPLETE PAYMENT DETAILS.</p>
                                                    <p>NOTWITHSTANDING THE FOREGOING, NOTHING IN THIS AGREEMENT SHALL AFFECT
                                                        YOUR STATUTORY RIGHTS OR EXCLUDE INJURY ARISING FROM ANY WILFUL
                                                        MISCONDUCT OR FRAUD OF FINSAI.</p>
                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-17" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Intellectual property and third-party
                                                    content</p>
                                                <div className="content-card">
                                                    <p>You may only install and use the software connected to the Website (the
                                                        “Platform”) and all content derived from the Platform, including without
                                                        limitation the patents, copyrights, design rights, trademarks and any
                                                        other intellectual property rights in the Platform, in connection with
                                                        the Services, for your personal and non-commercial use and in accordance
                                                        with this Agreement. The Platform's code, structure and organization are
                                                        protected by intellectual property rights. You must not:

                                                    </p>
                                                    <ul className="ul-liststyle">
                                                        <li>copy, interfere with, tamper with, redistribute, publish, reverse
                                                            engineer, decompile, disassemble, amend, modify, translate or make
                                                            any attempt to access the source code to create derivative works of
                                                            the source code, or otherwise;</li>
                                                        <li>
                                                            sell, assign, sublicense, transfer, distribute or lease the
                                                            Platform;
                                                        </li>
                                                        <li>make the Platform available to any third party through a computer
                                                            network or otherwise;</li>
                                                        <li>export the Platform to any country (whether by physical or
                                                            electronic means);or </li>
                                                        <li>use the Platform in a manner prohibited by any laws or regulations
                                                            which apply to the use of the Platform (collectively the “Forbidden
                                                            Practices”).</li>
                                                    </ul>
                                                    <p>You will be liable to us for any damage, cost or expense we suffer or
                                                        incur that arise out of or in connection with your committing any of the
                                                        Forbidden Practices. You shall notify us as soon as reasonably possible
                                                        after becoming aware of the commission by any person of any of the
                                                        Forbidden Practices and shall provide us with reasonable assistance with
                                                        any investigation we may conduct in light of the information provided by
                                                        you in this respect.</p>
                                                    <p>The brand names relating to the Website and any other trademarks, service
                                                        marks and/or trade names used by us either on our own behalf from time
                                                        to time (the “Trademarks”) are owned by us or our licensors. In addition
                                                        to the rights in the Trademarks, we and/or our licensors own the rights
                                                        in all other content of the Website (the “Content”). By using the
                                                        Services, you shall not obtain any rights in the Trademarks or the
                                                        Website Content and you may use the Trademarks and Content only in
                                                        accordance with the provisions of this Agreement.</p>
                                                    <p>FINSAI makes no representations whatsoever about any other interface
                                                        which you may access through the Website. Notwithstanding anything to
                                                        the contrary herein, the Website may provide links or other forms of
                                                        reference to other interfaces, resources or data, over which FINSAI has
                                                        no control (“External Content”). Therefore, you acknowledge that any
                                                        link to an External Content is provided in the Website for convenience
                                                        purposes only. FINSAI is not responsible for the availability or any
                                                        content of the External Content, including opinions, advice, statements,
                                                        prices, activities and advertisements, and you use such content at your
                                                        own risk. You have to review the policies posted on the External Content
                                                        regarding privacy and other topics before using it.</p>

                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-18" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">License</p>
                                                <div className="content-card">
                                                    <p>The Software and the Website are subject to provisions of the English
                                                        Copyright, Designs and Patents Act 1988, as amended, and FINSAI owns all
                                                        intellectual property rights for them.
                                                    </p>

                                                    <p>We provide a license to you on a non-exclusive non-transferable basis to
                                                        use the Software and Website during the term of this Agreement. The
                                                        remuneration for license is included in the Commission. You are not
                                                        allowed to grant a license to any third party. The scope of license is
                                                        territorially unlimited.</p>
                                                    <p>We are authorized to change the Software or the Website, or any of its
                                                        parts, however deemed necessary during the term of the Agreement. Any
                                                        such change does not constitute a change in license and the license is
                                                        granted to the changed Software and Website immediately after such
                                                        change is adopted.</p>
                                                    <p>Except where necessary in order to view the information on the Website,
                                                        or as permitted under the English law or this Agreement, no information
                                                        or content on the Website may be reproduced, adapted, uploaded to a
                                                        third party, linked to, framed, performed in public, distributed or
                                                        transmitted in any form by any process without a specific written
                                                        consent of FINSAI.</p>

                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-19" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Dormant accounts</p>
                                                <div className="content-card">
                                                    <p>If the Account has not been active for 12 (twelve) months, consecutively it shall be classified as dormant. This type of
                                                        Account will be charged dormancy fees of GBP 19.95 per month starting
                                                        from the 13th (thirteenth) month of inactivity. The dormancy fee will
                                                        be deducted from the Account balance until the Account becomes active,
                                                        or until the balance reaches zero. The Account becomes active as soon as
                                                        the Customer logs in onto the Website either for transactional or
                                                        information purposes.
                                                    </p>



                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-20" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Suspension, Closure and Termination</p>
                                                <div className="content-card">
                                                    <p>Your Account does not expire and will remain valid until cancelled by
                                                        either you or FINSAI.
                                                    </p>
                                                    <p>Within 14 days of the date of opening your Account, you have
                                                        the right to close your Account at no cost by contacting FINSAI and
                                                        requesting that in writing; provided, however, that you will be liable
                                                        for all Transactions made by you during such period. After the initial
                                                        14-day period, you may close your Account at any time by giving one
                                                        month’s notice to FINSAI in writing via email at <a
                                                            href="mailto: support@FINSAI.co.uk?subject=&body=Description" className="link">support@FINSAI.co.uk</a>.
                                                    </p>
                                                    <p>When your Account is closed, it cannot be used. You will remain liable
                                                        for all obligations relating to your Account even after your Account has
                                                        been closed. Closing your Account does not mean that we delete the
                                                        personal data that we hold on you and we will continue to store such
                                                        data, including the history of your Transactions for a minimum period of
                                                        5 (five) years as required by law.</p>
                                                    <p>We may terminate this Agreement and close your Account by giving you a
                                                        one month’s notice via email with or without reason.</p>
                                                    <p>We may suspend or terminate this Agreement and your Account, or refuse to
                                                        reactivate your Account, immediately upon a notice given to you via
                                                        email:</p>
                                                    <ul className="ul-liststyle">
                                                        <li>if you violate any provision of this Agreement and fail to resolve
                                                            the matter in a timely manner;</li>
                                                        <li>
                                                            if you act in a manner that is threatening or abusive to our staff
                                                            or any of our representatives;
                                                        </li>
                                                        <li>
                                                            if you fail to pay Commission or any part of the Transaction Price;
                                                        </li>
                                                        <li>
                                                            if your Account is dormant and its balance has reached zero;
                                                        </li>
                                                        <li>
                                                            if you attempt to gain unauthorized access to the Software or
                                                            another Customer's Account or provide assistance to others
                                                            attempting to do so;
                                                        </li>
                                                        <li>
                                                            if you overcome our Software security features;
                                                        </li>
                                                        <li>
                                                            in case of some unexpected operational difficulties on our side;
                                                        </li>
                                                        <li>
                                                            if we are concerned about the security of your Account;
                                                        </li>
                                                        <li>if we suspect your Account is being used in an unauthorised manner,
                                                            including without limitation, by a person other than you, or that
                                                            the Services are being used for the benefit of someone other than
                                                            you, or used to commit fraud or for other illegal purposes;</li>
                                                        <li>
                                                            if we need to do so to comply with the law or with a request of law
                                                            enforcement or other government agencies;
                                                        </li>
                                                        <li>
                                                            we are so required by a subpoena, court order, order or request of a
                                                            government authority or regulatory authority;
                                                        </li>
                                                        <li>
                                                            you take any action that we deem as circumventing our controls,
                                                            including without limitation opening multiple Accounts;
                                                        </li>
                                                        <li>
                                                            use of your Account is subject to any pending litigation,
                                                            investigation, or government proceeding or we perceive a heightened
                                                            risk of legal or regulatory non-compliance associated with your
                                                            Account activity;
                                                        </li>
                                                        <li>
                                                            our service partners are unable to support your use of the Services;
                                                        </li>
                                                        <li>
                                                            we believe that you adversely affect our reputation;
                                                        </li>
                                                        <li>

                                                            we reasonably believe that your Account is associated with any
                                                            account that has been suspended or terminated for breach of this
                                                            Agreement or suspended for any other reason;
                                                        </li>
                                                        <li>
                                                            if you do not provide information upon the request of FINSAI or the
                                                            information provided does not meet FINSAI's requirements;
                                                            <p className="ps-2 mb-1">- due to a Force Majeure event; or</p>
                                                            <p className="ps-2 mb-1">- for any other reasonable ground, we deem fit.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                    <p>We will notify you on the above circumstances as soon as we can before we do this, if possible, or otherwise immediately afterwards, giving our reasons, unless letting you know would compromise reasonable security measures or be otherwise unlawful.
                                                    </p>
                                                    <p>The right to terminate the Agreement and to close your Account shall not stop you or us from exercising any other right or remedy in respect of the breach concerned (if any) or any other breach.
                                                    </p>
                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-21" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Governing Law and Jurisdiction</p>
                                                <div className="content-card">
                                                    <p>This Agreement and the relationship between you and us will be governed
                                                        by and construed in accordance with the English law without reference to
                                                        conflict of law or choice of law provisions, and you irrevocably submit
                                                        to the exclusive jurisdiction of the courts in London, England, for
                                                        determination of any dispute concerning this Agreement (provided that
                                                        this shall not prevent FINSAI from bringing an action in the courts of
                                                        any other jurisdiction). You further irrevocably waive any objection
                                                        which you may have at any time to the laying of venue of any proceeding
                                                        brought in any such court and agrees not to claim that such proceedings
                                                        have been brought in an inconvenient forum or that such court does not
                                                        have jurisdiction over it.
                                                    </p>



                                                </div>

                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-22" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <p className="font_26 text-black font_700">Miscellaneous</p>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">Entire Agreement</p>

                                                    <p>This Agreement contains the entire agreement and supersedes all prior and
                                                        contemporaneous understandings between the parties regarding the
                                                        Services. In the event of any conflict between this Agreement and any
                                                        other agreement you may have with FINSAI, the terms of this Agreement
                                                        shall prevail.
                                                    </p>



                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">Amendment</p>

                                                    <p>We reserve the right to make changes or modifications to this Agreement
                                                        from time to time, in our sole discretion. We will provide you with a
                                                        notice of at least 60 (sixty) calendar days of any material changes by
                                                        sending an email, providing notice on the Website and updating the “Last
                                                        Updated” date at the bottom of this Agreement. The amended Agreement
                                                        will be deemed effective immediately upon posting for new Customers.
                                                    </p>
                                                    <p>In all other cases, the amended Agreement will become effective upon the
                                                        earlier of either: </p>
                                                    <ul className="ul-liststyle">
                                                        <li>the date a Customer clicks a button to accept such changes, or </li>
                                                        <li>continued use of the Services 15 (fifteen) days after FINSAI
                                                            provides notice of such changes.</li>
                                                    </ul>
                                                    <p>The amended Agreement will apply prospectively to use of the Services
                                                        after such changes become effective. If you do not agree to any amended
                                                        Agreement, you must discontinue using the Services and contact FINSAI to
                                                        close your Account.</p>



                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">Severability</p>

                                                    <p>The invalidity or unenforceability of any provision of this Agreement
                                                        shall not affect the validity or enforceability of any other provision
                                                        of this Agreement, all of which shall remain in full force and effect.
                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">Force Majeure Events</p>

                                                    <p>Neither party shall be liable for any inaccuracy, error, delay
                                                        in, or omission of the transmission or delivery of information; or any loss or
                                                        damage arising from any Force Majeure.
                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">Assignment</p>

                                                    <p>You may not assign or transfer any of your rights or obligations under
                                                        this Agreement without prior written consent from FINSAI, including by
                                                        operation of law or in connection with any change of control. FINSAI may
                                                        assign or transfer any or all of its rights or obligations under this
                                                        Agreement, in whole or in part, without obtaining your consent or
                                                        approval.
                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">Outsourcing</p>

                                                    <p>Subject to applicable laws and regulations, FINSAI may outsource any or
                                                        all of the Services it provides under this Agreement to third parties.
                                                    </p>
                                                </div>
                                                <div className="content-card">
                                                    <p className="black_21 font_600">Survival</p>

                                                    <p>Clause’s “Eligibility”, “Services Use”, “Risk Disclosure”, “Commission”,
                                                        “Dormant Accounts”, “License”, “Intellectual Property and Third-Party
                                                        Content”, “Disclaimer of Warranties”, “Limitation of Liability”;
                                                        “Waivers”; “Suspension, Closure and Termination”, “Applicable Law and
                                                        Jurisdiction” and this Clause “Miscellaneous” shall survive any
                                                        termination of this Agreement.
                                                    </p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br /> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}
export default TermsConditions