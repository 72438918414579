import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom'
import Model from "../components/model/model";
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const Payments = () => {
    const [scroll, setScroll] = useState(true)

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main className="finsai-payment-main">
                <Header />
                <section className="payment-banner-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-xl-5 col-xxl-6 col-md-7 col-12">
                                <div>
                                    <p className="font_54 font_700">Hassle-Free<br />
                                        Money Transfers </p>
                                    <p className="font_24 font_500 mb-4">Enjoy secure, swift, and reliable payment<br /> solutions for
                                        your
                                        money
                                        transfers.</p>
                                    <Link to="/select-country" target="_blank" className="white_btn font_20 font_600 ">Send money now</Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-xxl-3 col-md-7 col-12">

                                <div className="position-relative w-max-content">
                                    <div className="success-card">
                                        <img src="assets/images/profile-payment.png" className="profile-icon" alt="profile" />
                                        <div className="w-100">
                                            <p className="mb-0 font_14 font_600">Nick Jonson</p>
                                            <div className="d-flex justify-content-between">
                                                <p className="font_12 mb-0 ">Payment Successful</p>
                                                <p className="mb-0 font_12">08:42 AM</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="correct-icon">
                                        <img src="assets/images/icon/correct-icon.svg" alt="right" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-7 col-sm-6 col-12">
                                <div className="payment-modal-img">
                                    <img src="assets/images/payment-female.svg" alt="pay" />
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* </div> */}
                </section>
                <section className="sec-pad">
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-xl-11 col-11">
                                <div className="pay-request">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <p className="font_40 font_700">Request money with ease</p>
                                            <p className="font_24 text-request">Simplify all your financial transactions with
                                                FINSAI’s
                                                easy-to-use
                                                payment
                                                request feature. Easily collect payments, make simpler financial transactions,
                                                and upgrade your financial management.</p>
                                            <Link to="/select-country" target="_blank" className="white_btn font_20 font_600 mb-3">Try it today</Link>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div className="req-money-icon">
                                                <img src="assets/images/req_money_ease.png" className="img-fluid" alt="req-money" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-6 col-xl-5 col-12">
                                <div className="pay-icon">
                                    <img src="assets/images/splitting_bills.png" className="img-fluid" alt="bills" />
                                </div>

                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div>
                                    <p className="font_40 font_700">Splitting bills made easy and hassle-free</p>
                                    <p className="font_24 font_500 gray_79 mb-4">Dividing your restaurant bills or rent just got
                                        easier.
                                        Log your
                                        expenses
                                        and split
                                        payments with anyone, even if they are not on FINSAI.</p>
                                    <Link to="/select-country" target="_blank" className="sign_gradient_btn font_18 font_600">Get started</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-xl-11 col-11">
                                <div className="pay-request set-moneysec">
                                    <div className="row">

                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div className="pay-icon--1">
                                                <img src="assets/images/transact_globally.png" className="img-fluid" alt="trasact_globally" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <p className="font_40 font_700">Transact globally at local rates</p>
                                            <p className="font_24 text-request">Experience the power of making international
                                                transactions like a local with FINSAI’s competitive exchange rates. We always
                                                ensure that you get the best value for your money and make smoothest
                                                international transactions.</p>
                                            <Link to="/select-country" target="_blank" className="white_btn font_20 font_600">Send money now</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="sec-exchange">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-xl-6 col-12">
                                    <div>
                                        <p className="font_40 font_700">Swiftly exchange in 25 currencies</p>
                                        <p className="font_24 font_500 gray_79 mb-4">Exchange over 25 currencies in real-time with
                                            FINSAI’s
                                            advanced currency conversion. Take control of your global finances with our quick
                                            transactions and competitive rates.</p>
                                        <Link to="/select-country" target="_blank" className="sign_gradient_btn font_18 font_600">Exchange
                                            today</Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 col-12">
                                    <div className="pay-icon">
                                        <img src="assets/images/swiftly_exchange_currencies.png" className="img-fluid" alt="swiftly_exchange_currencies" />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 col-xl-6 col-xxl-6 col-12">
                                <div className="finsai-card">
                                    <img src="assets/images/finsai-card.png" alt="finsai-card" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-5 col-xxl-5 col-12">
                                <div className="mb_78">
                                    <p className="font_40 font_700">Swipe and Spend with Mastercard</p>
                                    <p className="font_24 gray_79 font_500 mb-4">Choose your Mastercard from our vibrant array of
                                        debit
                                        cards
                                        and
                                        start spending and saving
                                        like never before. Enjoy mobile payments, swift real-time notification, protection,and
                                        many
                                        more benefits. Swipe to a new era of convenience.</p>
                                    <Link to="/select-country" target="_blank" className="sign_gradient_btn font_18 font_600">Get started</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="transform pay-sec sec-pad pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="future_together text-center m-5">
                                    <p className="font_22 text-center text"> Don’t miss out!</p>
                                    <p className="font_43 font_700 text-center text-white mb-sm-5">Save time and tap for instant
                                        transfers</p>

                                    <Link to="/select-country" target="_blank" className="white_btn font_20 font_600">
                                        Try it now
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-pad">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-120 flex-wrap">
                            <div>
                                <p className="font_64 font_700">Pricing plans</p>
                                <p className="font_20 gray_52" >Discover more, experience better – choose your plan and unlock a
                                    world<br /> tailored to you.</p>
                            </div>
                            <div>
                                <Link to="/select-country" target="_blank" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="card" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Lite <br />
                                            £ 0.00<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Start your journey with confidence toward meeting your
                                            financial goals. Experience optimized financial transactions and custom-made
                                            solutions for your needs.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--1.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="card" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Smart
                                            <br />
                                            £ 4.99<span className="font_14 font_600">/Month</span>

                                        </p>
                                      
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Conveniently handle your day-to-day finances with our
                                            Smart
                                            Plan.Unlock a world of convenient money management and personalized perks. Grab your
                                            key to a smarter way of handling your finances.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--2.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="card" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Premium
                                            <br /> £ 9.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Experience the epitome of luxury with our Premium
                                            Debit
                                            Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                            that redefine your financial journey.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--3.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="card" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Metal
                                            <br /> £ 14.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                      
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Indulge yourself in the ultimate experience with our
                                            Metal
                                            Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                            rewards, top-tier security, and an aura of exclusivity.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--4.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <Footer />
                <Model />
                <HelmetProvider>
                    <Helmet>
                        <script>
                            {
                                `
                                ////text height////
                                var highestBox = 0;
                                $('.card-plan .text').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.card-plan .text').height(highestBox);
                            `
                            }
                        </script>
                    </Helmet>
                </HelmetProvider>
            </main >
        </>
    )
}

export default Payments