
import { Link } from "react-router-dom"
import Header from "../components/header/header"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"
import Footer from "../components/footer/footer"
import Model from "../components/model/model"

const MonitoringPlan = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="analysis-title">
                    <div className="container">
                        <p className="mb-4 font_24 font_300 black_21">
                            <Link to="/compliance-monitoring-plan"> <img src="assets/images/icon/left-arrow-icon.png"
                                className="title-icon" alt="icon" /></Link>
                            FINSAI Compliance Monitoring Plan
                        </p>
                        <div className="content-card">
                            {/* <!-- <p className="black_21 font_600">Attachment 1: FINSAI AML / CFT Risk Assessment GAP analysis</p> --> */}
                            <div className="table-assess table-responsive mb-3">
                                <table className="table rounded-corners">
                                    <thead>
                                        <tr>
                                            <th className="text-center font_14">Requirement
                                            </th>
                                            <th className="text-center font_14">Monitoring<br />
                                                overview
                                            </th>
                                            <th className="text-center font_14">Method
                                            </th>
                                            <th className="text-center font_14">Frequency
                                            </th>
                                            <th className="text-center font_14 text-nowrap">Last performed<br />
                                                (and by whom)
                                            </th>
                                            <th className="text-center font_14 text-nowrap">Next due<br />
                                                (and by whom)
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="gray_42 plan-font">
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">REGULATED ACTIVITIES</td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Permitted business:</span> a
                                                firm is
                                                prohibited from carrying on a
                                                regulated activity in the United Kingdom by way of business other than in
                                                accordance with its FCA permissions.</td>
                                            <td className="font_14">Review of business undertaken by the firm to ensure that it is
                                                appropriately authorised, with particular regard to any new business activity.
                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Quarterly</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">SENIOR MANAGEMENT ARRANGEMENTS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Apportionment of
                                                responsibilities:</span> a firm must appropriately allocate to one or more
                                                individuals, the functions of: (1) dealing with the apportionment of
                                                responsibilities and (2) overseeing the establishment and maintenance of systems
                                                and controls.</td>
                                            <td className="font_14">Confirmation that individuals in senior management have been
                                                allocated with these functions and are discharging them appropriately.
                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">SYSTEMS AND CONTROLS
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Governance:</span> A firm must
                                                have robust governance arrangements, (including a clear organisational structure
                                                with well defined, transparent and consistent lines of responsibility, effective
                                                processes to identify, manage, monitor and report the risks it is or might be
                                                exposed to, and internal control mechanisms), including sound administrative and
                                                accounting procedures and effective control and safeguard arrangements for
                                                information processing systems.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height"> Confirmation that reporting lines are clear,
                                                    appropriate and
                                                    documented,
                                                    responsibilities are clearly defined, and job descriptions are appropriate
                                                    and
                                                    complete (particularly for significant influence functions).</p>
                                                <p className="font_14 text-height"> Updates to any internal structure charts to
                                                    reflect
                                                    organisational changes/staff
                                                    turnover, and ensure these are communicated internally.</p>
                                                <p className="font_14 text-height"> Confirm that terms of reference for all standing
                                                    committees exist and are
                                                    appropriate.</p>
                                                <p className="font_14 text-height"> Is the organisational structure still sufficient
                                                    for the
                                                    size and scope of
                                                    operations?</p>
                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-wrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Compliance function:</span>  A
                                                firm must establish, implement and maintain adequate policies and procedures
                                                sufficient to ensure compliance of the firm with its obligations under the
                                                regulatory system.</td>
                                            <td className="font_14">
                                                Review of all policies and procedures, and compliance manual, and amendments as
                                                necessary (including to reflect legal/regulatory changes).
                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Quarterly</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Regular Monitoring: </span> A
                                                firm must monitor and, on a regular basis, evaluate the adequacy and
                                                effectiveness of its systems, internal control mechanisms and arrangements and
                                                take appropriate measures to address any deficiencies.</td>
                                            <td className="font_14">
                                                Assessment of monitoring programme and amendments if necessary.
                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Quarterly</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td><span className="font_600 black_21">Telephone monitoring </span></td>
                                            <td>
                                                <p className="font_600 black_21 font_14 mb-1">Telephone Calls</p>
                                                <p className="font_14 mb-0">Review of Telephone conversations</p>
                                                <ol className="ul-liststyle protection-policy ps-3 mb-0" type="1">
                                                    <li>Acting within the scope of permission</li>
                                                    <li>Professionalism</li>
                                                    <li>Information given</li>
                                                    <li>Adherence to procedure</li>
                                                </ol>
                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Daily</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Outsourcing:  </span> A firm
                                                must, on a continuous and satisfactory basis, ensure that it takes reasonable
                                                steps to avoid undue additional operational risk.  Any outsourcing must not
                                                impair materially: (a) the quality of its internal controls; and (b) the ability
                                                of the FCA to monitor the firm's compliance with its obligations under the
                                                regulatory system.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height"> Confirmation that any new outsourced
                                                    arrangements comply with the rules and guidance.</p>
                                                <p className="font_14 text-height">Confirmation that FCA is notified of any material
                                                    changes to existing arrangements.</p>

                                                <p className="font_14 text-height mb-0">
                                                    Confirmation that any outsourced arrangements are clearly documented (SLAs,
                                                    KPIs etc.)</p>
                                            </td>
                                            <td></td>
                                            <td className="font_14">
                                                <p className="font_14 text-height text-center text-nowrap">Quarterly</p>
                                                <p className="font_14 text-height text-center text-nowrap">Ad-Hoc</p>

                                                <p className="font_14 text-height text-center text-nowrap">Quarterly</p>

                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Risk Control:</span> A firm must
                                                establish, implement and maintain adequate risk management policies and
                                                procedures, including effective procedures for risk assessment, which identify
                                                the risks relating to the firm's activities, processes and systems, and where
                                                appropriate, set the level of risk tolerated by the firm.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">Review actual and potential operational risks
                                                    arising from personnel, IT systems, failures in processing information or
                                                    failures of security of systems that contain information. Notify FCA
                                                    immediately of any material matters that arise.</p>
                                                <p className="font_14 text-height">Confirmation that regular risk-assessments are
                                                    being undertaken.</p>

                                                <p className="font_14 text-height mb-0">
                                                    Confirmation that incident reports are being completed and followed up.</p>
                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Quarterly</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Business Continuity: </span> A
                                                firm should have in place appropriate arrangements to ensure that it can
                                                continue to function and meet its regulatory obligations in the event of an
                                                unforeseen interruption.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height mb-0">Testing of business continuity arrangements,
                                                    including IT arrangements. Update continuity plan if required, to ensure it
                                                    remains effective and relevant.</p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Record Keeping: </span>A firm
                                                must take reasonable care to make and retain adequate records of matters and
                                                dealings (including accounting records) which are the subject of requirements
                                                and standards under the regulatory system.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">Confirmation that correct records are being kept
                                                    for the required duration, and that data is backed up and can be retrieved
                                                    as required. (Including off-site storage arrangements)</p>
                                                <p className="font_14 text-height mb-0">Include all records of client communications
                                                    including taped recordings, email communications, complaints etc.</p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Six monthlies</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Financial Crime: </span>A firm
                                                must have systems and controls that: (1) enable it to identify, assess, monitor
                                                and manage money laundering risk; and (2) are comprehensive and proportionate to
                                                the nature, scale and complexity of its activities.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">Confirmation of adequacy of money laundering
                                                    policies and procedures against risk-based assessment, KYC procedures and
                                                    client screening, staff training and reporting to authorities.</p>
                                                <p className="font_14 text-height">Assess responsibilities and effectiveness of
                                                    MLRO.
                                                </p>
                                                <p className="font_14 text-height mb-0">Confirm internal blacklists are properly
                                                    maintained.
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Internal SARs</span></td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">Are sufficient volumes of SARs being raised by
                                                    staff members following monitoring activities?</p>
                                                <p className="font_14 text-height mb-0">Appropriate Action being taken including
                                                    regular
                                                    monitoring
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14  text-height text-center text-nowrap">Two monthlies</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Whistleblowing: </span>Firms are
                                                encouraged to consider adopting appropriate internal procedures which will
                                                encourage workers with concerns to blow the whistle internally about matters
                                                which are relevant to the functions of the FCA.</td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">Ensure staff have been advised of the firm’s
                                                    internal procedures on Whistleblowing, including information on how they can
                                                    raise their concerns confidentially.
                                                    (See whistle blowing procedures).
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Client money: </span>Firm safely
                                                holds and accounts for client money</td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">Ensure reconciliation of segregated account
                                                    balances is performed on a regular basis and that sufficient funds are held
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Monthly</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">SUPERVISION
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Regulatory Reporting:
                                            </span> A firm must submit reports to the FCA in writing (including regulatory
                                                reporting on financials, annual controllers report, annual close links report
                                                etc.)</td>
                                            <td className="font_14">

                                                <p className="font_14 text-height">Ensure all relevant personnel are aware of the
                                                    FCA reporting schedule.
                                                </p>
                                                <p className="font_14 text-height mb-0">

                                                    Confirm that all reports are filed with the FCA prior to the due date.
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14">
                                                <p className="font_14 text-height   text-center text-nowrap">Six monthlies
                                                </p>
                                                <p className="font_14  text-height text-center text-nowrap mb-0">Six monthlies
                                                </p>
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Registrations</span></td>
                                            <td className="font_14">


                                                <p className="font_14 text-height mb-0">
                                                    Are all registrations up to date and active, e.g ICO
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14  text-height text-center text-nowrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Breaches</span></td>
                                            <td className="font_14">


                                                <p className="font_14 text-height mb-0">
                                                    Have any breaches been recorded, investigated and reported where relevant?
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14  text-height text-center text-nowrap">Two monthlies</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">STAFF
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">EMD Individuals: </span>Certain
                                                individuals must be approved persons to perform ‘controlled functions.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">
                                                    Ensure staff carrying out controlled functions have been registered
                                                    appropriately with the FCA and have met the necessary Training and
                                                    Competence requirements and are fit and proper.
                                                </p>

                                                <p className="font_14 text-height mb-0">
                                                    Ensure changes in approved persons’ details and departures etc. have been
                                                    notified to FCA.
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Supervision, training and
                                                competence: </span>A firm must assess an employee as competent to carry on
                                                certain activities with clients (this can include a requirement to pass a
                                                competency test)</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">
                                                    Maintain training schedules for all new employees and existing employees to
                                                    cover regulatory requirements.
                                                </p>

                                                <p className="font_14 text-height mb-0">
                                                    Ensure initial and refresher training carried out
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Ad-Hoc</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Employee – Disciplinary Action
                                            </span></td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">
                                                    Ensure that any issues related to the fitness and propriety of staff (e.g.
                                                    disciplinary action) have been reviewed and acted upon including where
                                                    necessary notification to the FCA.
                                                </p>

                                                <p className="font_14 text-height mb-0">
                                                    Ensure adequate Disciplinary procedures
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">
                                                <p className="font_14 text-height   text-center text-nowrap">Ad-Hoc
                                                </p>
                                                <p className="font_14  text-height text-center text-nowrap mb-0">Annually
                                                </p>
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">CLIENT TAKE-ON
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">KYC/AML:
                                            </span>Firms must apply client due diligence measures when they establish a
                                                business relationship.</td>
                                            <td className="font_14">
                                                <p className="font_14 text-height">
                                                    Confirm all KYC checks are carried out on clients, and that appropriate
                                                    records are made and kept.
                                                </p>
                                                <p className="font_14 text-height">

                                                    For a sample of existing clients, check that KYC information has been
                                                    updated at least every [three] years and any changes recorded.
                                                </p>

                                                <p className="font_14 text-height mb-0">
                                                    Ensure unable to open clients who appear on the sanctions list and relevant reports submitted.
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-height text-center text-nowrap">Monthly</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Change of Address:
                                            </span>Clients wishing to change their address</td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Compliance to periodically check for forged docs
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Ad-Hoc</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">KYC:
                                            </span>Ongoing obligation to keep up to date information on your clients</td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Review clients on a periodic basis
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Ad-Hoc</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Sanctions, PEPs and Criminal
                                                checks
                                            </span></td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Ensure systems are in place to catch any existing client who suddenly
                                                    appears on these lists.
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Annually</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Client agreements:
                                            </span>A firm must enter into a written basic agreement, on paper or other
                                                durable medium, with the client setting out the essential rights and obligations
                                                of the firm and the client.</td>
                                            <td className="font_14">

                                                <p className="font_14 text-height">
                                                    Ensure all clients are party to the firm’s terms of business and that
                                                    records are kept of the agreement that applies to each client.
                                                </p>
                                                <p className="font_14 text-height mb-0">


                                                    Ensure client agreements are updated to take account of relevant regulatory
                                                    and legal changes.
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">Quarterly</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Data Protection:
                                            </span>A firm must comply with the requirements of the Data Protection Act 1998.
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height">
                                                    Ensure client data is kept securely and only transferred to third parties
                                                    with client’s consent.
                                                </p>
                                                <p className="font_14 text-height">
                                                    Ensure client documentation particularly ID info etc is kept secure and
                                                    confidential at all times
                                                    Check data is password protected or secure
                                                </p>
                                                <p className="font_14 text-height mb-0">

                                                    Ensure all relevant employees have received data protection training
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center">

                                                <p className="font_14 text-height  text-nowrap">
                                                    Annually
                                                </p>
                                                <p className="font_14 text-height  text-nowrap">
                                                    Annually
                                                </p>
                                                <p className="font_14 text-height  text-nowrap mb-0">

                                                    Annually
                                                </p>
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">CONDUCT OF BUSINESS
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Complaints:
                                            </span> A firm must establish, implement and maintain effective and transparent
                                                procedures for the reasonable and prompt handling of complaints from clients
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height">
                                                    Review internal complaints handling procedures and complaints received (in
                                                    particular for patterns). Train staff where necessary.
                                                </p>
                                                <p className="font_14 text-height">
                                                    Check whether any cases have been referred to FOS in the period and what
                                                    outcome was.  Identify any patterns.
                                                </p>
                                                <p className="font_14 text-height mb-0">
                                                    Check complaints handling procedures used correctly and update if necessary.
                                                </p>

                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Monthly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Client money:
                                            </span>  A firm must make adequate arrangements to safeguard client's rights in
                                                client money and assets and prevent the use of client money and assets for its
                                                own account.
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Review procedures for holding client money and assets.
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Quarterly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">MARKETING AND CLIENT COMMUNICATIONS
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Financial promotions:
                                            </span> must be identifiable as such.
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Confirm that all client communications which could constitute financial
                                                    promotions are reviewed by Compliance prior to distribution. This includes
                                                    bulk emails to clients
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Quarterly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Client communications:
                                            </span> must be clear, fair and not misleading.
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height">
                                                    Review all current brochures, fliers, websites etc.
                                                </p>
                                                <p className="font_14 text-height mb-0">
                                                    Ensure all communications to clients comply with rules
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Quarterly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Financial promotion record
                                                keeping:
                                            </span>
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Ensure copies are kept of all approved financial promotions
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Quarterly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">TCF
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">TCF:
                                            </span>A firm must act honestly, fairly and professionally in accordance with
                                                the best interests of its client
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Ensure six consumer outcomes are consistently met in all activities of the
                                                    firm, including product design, client information, any advice, and product
                                                    performance.
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Quarterly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">FINANCIAL RESOURCES
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Financial Resources
                                            </span></td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Ensure the Firm has an adequate excess of Financial resources to cover the
                                                    Capital Requirement
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Monthly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td colspan="6" className="font_600 font_14 black_21">BRIBERY AND CORRUPTION
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Bribery & Corruption: </span>The
                                                crime of bribery is described as occurring when a person offers, gives or
                                                promises to give a "financial or other advantage" to another individual in
                                                exchange for "improperly" performing a "relevant function or activity".
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height mb-0">
                                                    Has the Firm robust procedures in place to ensure its employees and
                                                    connected parties do not give or receive bribes?
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Annually
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Staff Training </span>
                                            </td>
                                            <td className="font_14">

                                                <p className="font_14 text-height">
                                                    Are Employees aware of the firm’s policy and procedures?
                                                </p>
                                                <p className="font_14 text-height mb-0">
                                                    Have they received training?
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Annually
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Third Parties </span>
                                            </td>
                                            <td className="font_14">


                                                <p className="font_14 text-height mb-0">
                                                    Are any parties the Firms does business with aware and have agreed to abide
                                                    by the firm’s procedures.
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Quarterly
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Third Party Vetting </span>
                                            </td>
                                            <td className="font_14">


                                                <p className="font_14 text-height mb-0">
                                                    The Firm must carry out due diligence on all third parties (including those
                                                    with existing relationships) and know sufficient information about the Firm
                                                    or individual that it is about to conduct business with.
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Six monthlies
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Gift Register </span>
                                            </td>
                                            <td className="font_14">


                                                <p className="font_14 text-height mb-0">
                                                    Firm is required to maintain a gift register.
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Six monthlies
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td className="font_14"><span className="font_600 black_21">Risk Register </span>
                                            </td>
                                            <td className="font_14">


                                                <p className="font_14 text-height mb-0">
                                                    Ensure Risk register is up to date
                                                </p>


                                            </td>
                                            <td></td>
                                            <td className="font_14 text-center text-nowrap">
                                                Annually
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className="p-4">
                                    <p className="font_500 gray_65">Review conducted:</p>
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5 col-xxl-5 col-12">
                                            <div className="row mb-3 g-0 align-items-end">
                                                <div className="col-lg-3 col-xl-2 col-xxl-2 col-3">
                                                    <p className="pb-0 text-height font_18 font_500 gray_65 mb-0">By:
                                                    </p>

                                                </div>
                                                <div className="col-lg-8 col-xl-9 col-xxl-9 col-9">
                                                    <input type="email" className="form-control define-input bottom-border flex-1"
                                                        id="exampleFormControlInput1" placeholder="" disabled />
                                                </div>
                                            </div>
                                            <div className="row mb-3 g-0 align-items-end">
                                                <div className="col-lg-3 col-xl-2 col-xxl-2 col-3">
                                                    <p className="pb-0 text-height font_18 font_500 gray_65 mb-0">Signed:
                                                    </p>

                                                </div>
                                                <div className="col-lg-8 col-xl-9 col-xxl-9 col-9">
                                                    <input type="email" className="form-control define-input bottom-border flex-1"
                                                        id="exampleFormControlInput1" placeholder="" disabled />
                                                </div>
                                            </div>
                                            <div className="row mb-3 g-0 align-items-end">
                                                <div className="col-lg-3 col-xl-2 col-xxl-2 col-3">
                                                    <p className="pb-0 text-height font_18 font_500 gray_65 mb-0">Date:
                                                    </p>

                                                </div>
                                                <div className="col-lg-8 col-xl-9 col-xxl-9 col-9">
                                                    <input type="email" className="form-control define-input bottom-border flex-1"
                                                        id="exampleFormControlInput1" placeholder="" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}
export default MonitoringPlan