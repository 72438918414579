import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="border-bottom">
                        <div className="row pb-3">

                            <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12 col-12">
                                <div className="social-links">
                                    <div className="logo-white">
                                        <img src="assets/images/logo-white.png" alt="logo-white" />
                                    </div>
                                    <p className="font_18 font_500 lh-base">FINSAI stands as a leading state-of-the-art payment service
                                        provider that is revolutionizing the financial landscape globally. Offering a convenient,
                                        secure, and reliable digital platform catering to the needs of individuals and businesses.
                                    </p>
                                    <ul className="list-unstyled">
                                        <li>
                                            <div> <a href="https://www.facebook.com/finsailtd
" target="_blank" rel="noreferrer"><img src="assets/images/icon/facebook.png" alt="facebook" /></a></div>
                                        </li>
                                        <li>
                                            <div>
                                                <a href="https://www.linkedin.com/company/finsai-ltd/
" target="_blank" rel="noreferrer"><img src="assets/images/icon/linkedin.png" alt="linkedin" /></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <a href="https://twitter.com/Finsai_ltd" target="_blank" rel="noreferrer"><img
                                                    src="assets/images/icon/x-twitter.svg" alt="twitter" /></a>
                                            </div>


                                        </li>
                                        {/* <!-- <li>
                                            <div>
                                                <a href="javascript:void(0)"> <img src="assets/images/icon/youtube.png"></a>
                                            </div>


                                        </li> --> */}
                                        <li>
                                            <div> <a href="https://www.instagram.com/finsai.ltd/?next=%2F" target="_blank" rel="noreferrer"><img
                                                src="assets/images/icon/Instagram.png" alt="Instagram" /></a></div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className=" col-md-6 col-lg-2 col-xl-2 col-sm-6 col-12">
                                <div className="footer-links">
                                    <p>Products </p>
                                    <ul className="list-unstyled">
                                        <li><Link to="/payments"> Mobile Payments</Link></li>
                                        <li><Link to="/cards"> Cards</Link></li>
                                        <li><Link to="/accounts"> Accounts</Link></li>

                                        <li><Link to="/international-transfer"> International Transfers</Link></li>
                                        <li><Link to="/currency-exchange"> Currency Exchange</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 col-xl-2 col-sm-6 col-12">
                                <div className="footer-links">
                                    <p>Company</p>
                                    <ul className="list-unstyled">
                                        <li><Link to="/about-us"> About us</Link></li>
                                        {/* <!-- <li><a href="javascript:void(0);"> Blogs</a></li>
                                        <li><a href="javascript:void(0);"> Media</a></li>
                                        <li><a href="javascript:void(0);"> Partners</a></li> --> */}
                                        <li><Link to="/careers"> Careers</Link></li>
                                        <li><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop">Contact us</a></li>

                                    </ul>
                                </div>
                            </div>
                            {/* <!-- <div className="col-md-6 col-lg-2 col-xl-2 col-sm-6 col-12">
                                <div className="footer-links">
                                    <p>Help</p>
                                    <ul className="list-unstyled">
                                        <li><a href="javascript:void(0);"> Pricing</a></li>
                                        <li><a href="javascript:void(0);"> FAQs</a></li>
                                        <li><a href="javascript:void(0);"> Get in Touch</a></li>
                                        <li><a href="javascript:void(0);"> Service Status</a></li>
                                        <li><a href="javascript:void(0);">Keep Safe From<br>
                                            Fraud</a></li>

                                    </ul>
                                </div>
                            </div> --> */}
                            <div className="col-md-6 col-lg-2 col-xl-2 col-sm-6 col-12">
                                <div className="footer-links">
                                    <p>Legal</p>
                                    <ul className="list-unstyled">
                                        <li><Link to="/terms-conditions"> Terms &
                                            Conditions</Link></li>
                                        {/* <li><Link to=""> Fees & Limits</Link></li> */}
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        {/* <li><Link to=""> Cookies Policy</Link></li> */}
                                        {/* <li><Link to="">Fraud Page</Link></li> */}
                                        <li><Link to="/data-protection-policy">Data Protection Policy</Link></li>
                                        <li><Link to="/aml-risk-assessment">AML Risk Assessment</Link></li>
                                        <li><Link to="/aml-policy-procedure">AML Policy Procedure</Link></li>
                                        <li><Link to="/risk-matrix">Risk Matrix</Link></li>
                                        <li><Link to="/compliance-monitoring-plan">Compliance Monitoring Plan</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom">
                        {/* <p className="text-white pt-4 pb-4 mb-0">FINSAI is a trading name of ALLOY PAY Limited. Alloy Pay Ltd. is
                            a company registered in England and Wales with the registered company number 13858479 and its registered
                            address at Amber Court William Armstrong Drive, Newcastle Business Park, Newcastle Upon Tyne, England,
                            NE4 7YA. For UK customers Alloy Pay is an electronic money institution authorized by the Financial Conduct Authority under the Electronic Money
                            Regulations 2011 (with firm reference 969388) for the issuing of electronic money and payment
                            instruments. </p> */}
                        <p className="text-white pt-4 pb-4 mb-0">FINSAI is a company registered in England and Wales with the registered company number 13858479. </p>

                    </div>

                    <div className="copyright-sec flex-wrap">
                        <p className="font_18 font_500 text-white  ">©2023 Finsai. All rights reserved.</p>
                        {/* <!-- <div className="d-flex gap-4">
                            <p className="mb-0">Impressum</p>
                            <p className="mb-0">Datenschutz</p>
                        </div> --> */}
                    </div>
                </div>
                <Link id="button" className="show"></Link>
            </footer>
        </>
    )
}

export default Footer