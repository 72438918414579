import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"


const CurrencyExchange = () => {

    const [scroll, setScroll] = useState(true)

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main className="finsai-about-main">
                <Header />
                <section className="sec-pad currency-exchange-banner mb-120">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-sm-8 col-md-7 col-lg-6 col-xl-7 col-xxl-6 col-12">
                                <div>
                                    <p className="font_54 font_700 "> Exchange Currency with no extra charges</p>
                                    <p className="font_18 font_500 gray_65 font_inter mb-lg-5 mb-3">Exchanging Currencies now became
                                        easy
                                        with FINSAI</p>
                                    <Link to="/select-country" target="_blank" className=" font_20 font_600 get-started w-fit-content">Explore
                                        Now</Link>
                                </div>
                            </div>
                            <div className=" col-12 p-0 d-sm-none">
                                <div className="accounts-model-img"><img src="assets/images/currency-model.png" alt="pay" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-xl-11 col-12">
                                <div className="pay-request transfer-vector">
                                    <p className="font_40 font_700">Transfer funds in 20+ countries now</p>
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-7 col-md-6 col-12">

                                            <p className="font_24 text-request">In a rapidly changing landscape where our businesses
                                                expand and we travel, the primary requirement is currency exchange. With FINSAI,
                                                you can now transfer your funds to 20+ countries. It's as easy as a click away!
                                            </p>

                                        </div>
                                        <div className="col-lg-6 col-xl-5 col-md-6 col-12">
                                            <div className="transfer-icon">
                                                <img src="assets/images/transfer-money-1.png" alt="money" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="sec-pad mb_78">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-xl-4 col-12">
                                <div className="account-img">
                                    <img src="assets/images/transfer-icon--1.png" alt="icon" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xl-8 col-12">
                                <div>
                                    <p className="font_40 font_700">Effortless Conversions</p>
                                    <p className="font_22 gray_79">With our user-friendly online and mobile platforms, you can
                                        effortlessly perform currency conversions, ensuring that your finances remain under your
                                        control no matter where you are. It also guarantees secure fund transfers because FINSAI
                                        has cutting-edge financial technology.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/* <section className="currency-banner transfer-banner-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <p className="font_64 font_700 text-center">Our Popular Conversions
                            </p>

                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-12">
                                <div className="currency-flags d-flex flex-wrap gap-5">
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-1.svg" className="flag" alt="country" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-2.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-3.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-4.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-5.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-6.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-7.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-8.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-9.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-10.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-11.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-12.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-13.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-14.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-15.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-16.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-17.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-18.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-19.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>
                                    <div className="country-name">

                                        <img src="assets/images/flags/country-20.svg" className="flag" alt="" />

                                        <p className="mb-0 text font_16">Country Name</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}
                <section className="card-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-xl-6 col-12 mb-4">
                                <div className="transfer-card">
                                    <div className="place-img">
                                        <img src="assets/images/transfer-card-11.png" alt="card" />
                                    </div>

                                    <p className="font_32 text-white font_600">Transfer like Local, Business like Global</p>
                                    <p className="font_22 gray_f6">We offer a broad spectrum of currencies for exchange, allowing
                                        you to
                                        access the currency
                                        you need, no matter where your financial journey takes you. Whether it's pounds, US
                                        dollars, euros, yen, or any other currency, we have got you covered.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 col-12 mb-4">
                                <div className="transfer-card">
                                    <div className="place-img">
                                        <img src="assets/images/transfer-card-123.png" alt="card" />
                                    </div>

                                    <p className="font_32 text-white font_600">Currency Exchange is now
                                        in your Pocket</p>
                                    <p className="font_22 gray_f6">Exchange your fiat currencies effortlessly and securely using
                                        just your smartphone. With our user-friendly app, managing your money on the go has
                                        never been easier. Say goodbye to complicated online payments to convert currency. Your
                                        global currency exchange solution is just a tap away. </p>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="transfer-card">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="pay-icon">
                                                <img src="assets/images/payment-icon-044.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div>
                                                <p className="font_32 font_600 text-white">Cross-Border Money
                                                    Freedom</p>
                                                <p className="font_22 gray_f6">No more worrying about transferring money
                                                    internationally. With our seamless
                                                    money transfer service, you can send and receive funds easily and
                                                    efficiently. Just add the account details, tap on the options, and select
                                                    the type. It's easy, efficient, and affordable!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <p className="font_64 font_700 text-center mb-120">Key Benefits of FINSAI
                                    Financial Services
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="row align-items-stretch flex-wrap">
                                <div className="col-md-4 col-lg-4 col-xl-4 col-12">
                                    <div className="whats-new-card">
                                        <div className="whats-new-icon">
                                            <img src="assets/images/Time-1.png" alt="time" />
                                        </div>
                                        <div className="whats-new-para">
                                            <p className="font_24 font_700 mb-1">Real-Time Rates</p>
                                            <p className="font_16 gray_79">Get up-to-the-minute exchange rates for accurate currency
                                                conversion.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4 col-12">
                                    <div className="whats-new-card">
                                        <div className="whats-new-icon">
                                            <img src="assets/images/Time-2.png" alt="time2" />
                                        </div>
                                        <div className="whats-new-para">
                                            <p className="font_24 font_700 mb-1">Secure and Reliable</p>
                                            <p className="font_16 gray_79">Trust our robust system for secure and dependable money
                                                transactions.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4 col-12">
                                    <div className="whats-new-card">
                                        <div className="whats-new-icon">
                                            <img src="assets/images/Time-3.png" alt="time" />
                                        </div>
                                        <div className="whats-new-para">
                                            <p className="font_24 font_700 mb-1">Pay Internationally</p>
                                            <p className="font_16 gray_79">Effortlessly Send and Receive Payments Worldwide with
                                                Absolute Confidence.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="transform transfer-sec sec-pad">
                    <div className="container">

                        <div className="future_together bg-white">
                            <div className="row">
                                <div className="col-lg-7 col-12">
                                    <div>
                                        <p className="text-black font_30 font_700">Your Safety is our Priority
                                        </p>
                                        <p className="font_22 text-darkgray">Rest easy knowing that we prioritize the security of
                                            your
                                            financial transactions. We
                                            employ state-of-the-art security measures, so you can confidently exchange
                                            currencies without worrying about your financial assets. We understand the risk of
                                            online payments and hence take extra care to safeguard the platform and your
                                            interests.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-5 col-12">
                                    <div className="exchange-icon--1">
                                        <img src="assets/images/safety-priority.png" alt="priority" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
                {/* <section className="bg-white multi-cur-sec mb_78">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6 col-12">
                                <div className="accounts-icon">
                                    <img src="assets/images/accounts-secure-icon.png" alt="icon" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <p className="font_40 font_700">Experience Security, with FINSAI Financial Technology
                                    </p>
                                    <p className="font_22 gray_65 font_300">We have a dedicated team that looks after your safety
                                        and security with all your digital payments. Whatever type of account you use for your
                                        online payment, our financial technology keeps you secure at all times.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="begins-sec mb_78 mt_100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-11 col-12">
                                <div className="text-center">
                                    <p className="title font_22">Don’t Wait!
                                    </p>
                                    <p className="font_40 text-white font_700 mb-4"> Expand your business globally or travel
                                        internationally hassle-free with streamlined fund transfers and online payments</p>
                                    <Link to="/select-country" target="_blank" className="sign_gradient_btn font_18 font_600 mb-4 mb-sm-5"> Sign
                                        Up Now</Link>
                                    <div className="text-center d-sm-flex align-items-center justify-content-center">
                                        <Link to="/select-country" target="_blank" className="d-block">
                                            <img src="assets/images/play-store.svg"
                                                className="me-sm-3 mb-3 mb-sm-0 img-fluid icon-playstore" alt="play store" />
                                        </Link>
                                        <Link to="/select-country" target="_blank" className="d-block ">
                                            <img src="assets/images/app-store.svg" className="img-fluid icon-playstore"
                                                alt="app store" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-pad">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-120 flex-wrap">
                            <div>
                                <p className="font_64 font_700">Pricing plans</p>
                                <p className="font_20 gray_52">Discover more, experience better – choose your plan and unlock a
                                    world<br /> tailored to you.</p>
                            </div>
                            <div>
                                <Link to="/select-country" target="_blank" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Lite <br />
                                            £ 0.00<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Start your journey with confidence toward meeting your
                                            financial goals. Experience optimized financial transactions and custom-made
                                            solutions for your needs.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--1.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Smart
                                            <br />
                                            £ 4.99<span className="font_14 font_600">/Month</span>

                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Conveniently handle your day-to-day finances with our
                                            Smart
                                            Plan.Unlock a world of convenient money management and personalized perks. Grab your
                                            key to a smarter way of handling your finances.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--2.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Premium
                                            <br /> £ 9.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Experience the epitome of luxury with our Premium
                                            Debit
                                            Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                            that redefine your financial journey.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--3.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <div className="d-flex mb-3 gap-2">
                                            <img src="assets/images/icon/logo-card.png" alt="logo" />
                                            <p className="vip-tag">VIP</p>
                                        </div>
                                        <p className="font_34 font_600 text-blue  lh-sm">Metal
                                            <br /> £ 14.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Indulge yourself in the ultimate experience with our
                                            Metal
                                            Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                            rewards, top-tier security, and an aura of exclusivity.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--4.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <Footer />
                <Model />
                <HelmetProvider>
                    <Helmet>
                        <script>
                            {
                                `
                                ////text height////
                                var highestBox = 0;
                                $('.card-plan .text').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.card-plan .text').height(highestBox);
                            `
                            }
                        </script>
                    </Helmet>
                </HelmetProvider>
            </main>
        </>
    )
}
export default CurrencyExchange