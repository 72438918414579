import { Link, useLocation } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState, useEffect } from 'react';

const Header = () => {
    const [activeLink, setActiveLink] = useState('personal');
    const location = useLocation();

    useEffect(() => {

        if (location.pathname === '/personal') {
            setActiveLink('personal');
        } else if (location.pathname === '/business') {
            setActiveLink('business');
        } else {
            setActiveLink('');
        }
    }, [location.pathname]);

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const header = document.querySelector('.header-space');
    //         if (header) {
    //             if (window.scrollY > 90) {
    //                 header.classList.add('fixed-top');
    //             } else {
    //                 header.classList.remove('fixed-top');
    //             }
    //         }
    //     };

    //     document.onscroll = handleScroll;

    //     return () => {
    //         document.onscroll = null;
    //     };
    // }, []);
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <script>
                        {
                            `
                            $(document).on("scroll", function () {
                            if ($(document).scrollTop() > 90) {
                                $(".header-space").addClass("fixed-top");
                            } else {
                                $(".header-space").removeClass("fixed-top");
                            }
                        });
                        `
                        }
                    </script>
                </Helmet>
            </HelmetProvider>

            <header className="header-space ">
                {/* <header className={`header-space ${activeLink ? 'fixed-top' : ''}`}> */}
                <div className="container ">
                    <nav className="navbar navbar-expand-lg navbar-header navbar-light bg-transparent">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/"><img src="assets/images/logo.png" alt="logo" /></Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto ms-lg-5 mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className={`nav-link font_16 hover-underline-animation ${activeLink === 'personal' ? 'active' : ''}`} aria-current="page"
                                            to="/personal" onClick={() => handleLinkClick('personal')}>Personal</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link font_16 hover-underline-animation ${activeLink === 'business' ? 'active' : ''}`} to="/business" onClick={() => handleLinkClick('business')}>Business</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className="nav-link font_16 hover-underline-animation" href="#">Pricing</a>
                                    </li>  */}
                                    <li className="nav-item">
                                        <a className={`nav-link font_16 hover-underline-animation ${activeLink === 'contact' ? 'active' : ''}`} href="#" data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop" onClick={() => handleLinkClick('contact')}>Contact us</a>
                                    </li>

                                </ul>
                                <form className="d-flex gap-4 align-items-center">
                                    <Link to="/select-country" target="_blank" className="text-blue font_18 font_500">Login</Link>
                                    <Link to="/select-country" target="_blank" className="primary-btn font_15 font_500">Get Started</Link>
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Header