import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const RiskMatrix = () => {
    const [scroll, setScroll] = useState(true)

    return (
        <>
            <main>
                {scroll ? <ScrollToTop /> : null}

                <Header />
                <section className="terms-title-sec">
                    <div className="container">
                        <p className="title mb-0">Finsai Risk Matrix</p>
                    </div>
                </section>
                <section className="analysis-title pt-0">
                    <div className="container">

                        <div className="content-card">

                            <div className="table-assess matrix-table-padd table-responsive mb-3">
                                <table className="table rounded-corners">
                                    <thead>
                                        <tr>
                                            <th className="font_14">Risk Determinants
                                            </th>
                                            <th className="font_14">Risk Variables/ Determinants
                                            </th>
                                            <th className="text-center font_14">Assigned<br />
                                                Risk Weight
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="gray_42">
                                        <tr>
                                            <td className=" font_14 font_600 black_21" rowSpan="12">Customers</td>
                                            <td className="font_14 ">Exceptions in getting KYC related
                                                information from customer</td>
                                            <td className="font_14 ">301</td>

                                        </tr>
                                        <tr>
                                            <td className="font_14">Exceptions in getting KYB related information from Merchant</td>
                                            <td className="font_14">301</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Customer matches Politically exposed person (PEP) list, its
                                                close associate or family member</td>
                                            <td className="font_14">150</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Ultimate Beneficial Owners/ Directors match PEP list, its close
                                                associate or family member</td>
                                            <td className="font_14">150</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Requirement to fall back to manual verification </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Unclear source of funds or income from undocumented sources</td>
                                            <td className="font_14">20</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">The type of relationship – in a business relationship  </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">The type of relationship – occasional customers </td>
                                            <td className="font_14">10</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">The type of relationship – one off customers </td>
                                            <td className="font_14">20</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Merchant operating in high-risk industry </td>
                                            <td className="font_14">150</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Merchant operating in medium-risk industry </td>
                                            <td className="font_14">99</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Merchant operating in low risk industry  </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21" colSpan="3">
                                                <p className="invisible mb-0 font_14">Customers</p>
                                            </td>


                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21">Product</td>
                                            <td className="font_14 ">Merchant Wallet</td>
                                            <td className="font_14 ">5</td>

                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21" colSpan="3">
                                                <p className="invisible mb-0 font_14">Customers</p>
                                            </td>


                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21" rowSpan="4">Channels</td>
                                            <td className="font_14 ">Online or Mobile application </td>
                                            <td className="font_14 ">5</td>

                                        </tr>

                                        <tr>
                                            <td className="font_14">Face-to-face </td>
                                            <td className="font_14">0</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Referrals from third parties</td>
                                            <td className="font_14">20</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Agents </td>
                                            <td className="font_14">20</td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21" colSpan="3">
                                                <p className="invisible mb-0 font_14">1</p>
                                            </td>


                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21" rowSpan="13">Jurisdiction</td>
                                            <td className="font_14 ">Customer is issuing money or sending money to High Risk
                                                Jurisdictions as per FATF</td>
                                            <td className="font_14 ">99</td>

                                        </tr>
                                        <tr>
                                            <td className="font_14">Customer is issuing money or sending money to Medium Risk
                                                Jurisdictions as per FATF</td>
                                            <td className="font_14">10</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Customer is issuing money or sending money to Low Risk
                                                Jurisdictions as per FATF</td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Customer is based in EEA Country </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Customer is based in non-EEA Country  </td>
                                            <td className="font_14">99</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Business incorporated in EEA Country   </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Business incorporated in non-EEA Country   </td>
                                            <td className="font_14">99</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Ultimate Beneficial Owners (UBO)/Directors are based in EEA
                                                country   </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Ultimate Beneficial Owners (UBO)/Directors are based in non-EEA
                                                country </td>
                                            <td className="font_14">99</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Merchant has business in UN Sanctioned Countries</td>
                                            <td className="font_14">301</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Customer  is sending money to UN Sanctioned Countries</td>
                                            <td className="font_14">301</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Customer matches Sanctions lists database</td>
                                            <td className="font_14">301</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Ultimate Beneficial Owners (UBO)/Directors match Sanctions lists
                                                database </td>
                                            <td className="font_14">301</td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21" colSpan="3">
                                                <p className="invisible mb-0 font_14">1</p>
                                            </td>


                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21 border-bottom-0" rowSpan="6">Transactions</td>
                                            <td className="font_14 ">Abnormal transaction volume growth or decline</td>
                                            <td className="font_14 ">10</td>

                                        </tr>
                                        <tr>
                                            <td className="font_14">Growth or decline in relevant ratios such as returns and
                                                credits </td>
                                            <td className="font_14">20</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Growth or decline in dispute management ratios</td>
                                            <td className="font_14">20</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Growth or decline in transaction average size </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Changes in pricing of offered products and services </td>
                                            <td className="font_14">5</td>
                                        </tr>
                                        <tr>
                                            <td className="font_14">Changes in products and/or services offered for sale</td>
                                            <td className="font_14">5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="table-assess matrix-table-padd table-responsive mb-3">
                                        <table className="table rounded-corners">
                                            <thead>
                                                <tr>
                                                    <th className="font_14">Scale
                                                    </th>
                                                    <th className="font_14">Score
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody className="gray_42">
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">No risk</td>
                                                    <td className="font_14 ">0</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">Low risk</td>
                                                    <td className="font_14 ">5</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">Moderate</td>
                                                    <td className="font_14 ">10</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">High</td>
                                                    <td className="font_14 ">20</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">Definite </td>
                                                    <td className="font_14 ">99</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">Rejected </td>
                                                    <td className="font_14 ">301</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="table-assess matrix-table-padd table-responsive mb-3">
                                        <table className="table rounded-corners">
                                            <thead>
                                                <tr>
                                                    <th className="font_14">Range
                                                    </th>
                                                    <th className="font_14">Rating
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody className="gray_42">
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">
                                                        &lt; 50</td>
                                                    <td className="font_14 ">1</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">51-80</td>
                                                    <td className="font_14 ">2</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">81-110</td>
                                                    <td className="font_14 ">3</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">111-140</td>
                                                    <td className="font_14 ">4</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">141-170 </td>
                                                    <td className="font_14 ">5</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21">171 -300</td>
                                                    <td className="font_14 ">6</td>
                                                </tr>
                                                <tr>
                                                    <td className=" font_14 font_600 black_21"> &gt; 301 </td>
                                                    <td className="font_14 ">7</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="table-assess table-responsive">
                                <table className="table rounded-corners mb-0">
                                    <thead>
                                        <tr>
                                            <th className="font_14">Rating
                                            </th>
                                            <th className="font_14">Risk Profile
                                            </th>
                                            <th className="font_14">Due Diligence
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="gray_42">
                                        <tr>
                                            <td className=" font_14 font_600 black_21">
                                                1-2</td>
                                            <td className="font_14">Low</td>

                                            <td className="font_14">Standard</td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21">3-4</td>
                                            <td className="font_14">Medium</td>

                                            <td className="font_14">Standard + Enhanced ongoing monitoring </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600 black_21">5-6</td>
                                            <td className="font_14 ">High</td>
                                            <td className="font_14">Enhanced + Enhanced ongoing monitoring </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}
export default RiskMatrix