import { Link } from "react-router-dom"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"

const AboutUs = () => {
    return (
        <>
            <ScrollToTop />
            <main className="finsai-about-main">
                <Header />
                <section className="about_banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xxl-5 col-xl-6 col-lg-6 col-md-7 col-sm-12">
                                <h1 className="font_700 font_54 mb-4">Let’s Together Build a Bright Financial
                                    Tomorrow </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-4 col-lg-5 col-md-6 col-sm-12">
                                <p className="mb-0 font_18 font_500">Explore FINSAI, the premier payments solution that fulfills all your payment requirements.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about_us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="font_64 font_700 text-center mb_40">About us</h2>
                                <p className="text-center mb_40 font_20 font_400 gray_65">In today's modern world, where convenience and efficiency are considered supreme, FINSAI rises as a transformative force, reshaping the dynamics of financial management. We offer customized solutions for personal and business finance, transforming our clients financial journeys. </p>
                                <p className="text-center mb-0 font_20 font_400 gray_65">Our all-in-one platform is more than just a service provider; it is a committed partner that helps individuals achieve their financial aspirations, simplify complexities, and embrace smart money management. </p>
                                <h2 className="font_46 font_700 all_abt_heading">It's all about...</h2>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="its_all">
                                    <h2 className="font_52 font_700 about_digit">01.</h2>
                                    <div className="its_all_text">
                                        <h5 className="font_20 font_600 blue_00">Versatile Platform</h5>
                                        <p className="mb-md-0 font_16 gray_79">FINSAI is committed to offer you a wide range of services that cater to your financial needs. Whether it is Personal or Business, we have got you covered!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-md-block d-none"></div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-md-block d-none"></div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="its_all">
                                    <h2 className="font_52 font_700 about_digit">02.</h2>
                                    <div className="its_all_text">
                                        <h5 className="font_20 font_600 blue_00">Hassle-Free Experience</h5>
                                        <p className="mb-md-0 font_16 gray_79">We have designed our platform to take the hassle out of your financial services and provide you with a seamless experience. So, you can open an account anywhere, be it sitting at home, in the office, or even in your car.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="its_all">
                                    <h2 className="font_52 font_700 about_digit">03.</h2>
                                    <div className="its_all_text">
                                        <h5 className="font_20 font_600 blue_00">Smart & Secure Interface</h5>
                                        <p className="mb-md-0 font_16 gray_79">Our innovative smart interface allows you to use your money with ease. So, you can send money transfers, make purchases, and manage all your financial needs using the tools in our financial hub.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mission_blue">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <h3 className="font_40 text_lightblue font_700 mb-4">Our Mission</h3>
                                <p className="font_16 font_400 text_lightblue mb-4">Our mission is to simplify the experience for individuals and businesses through our digital solutions. As our world technologically advances at an exponential rate, we have designed our platform to provide our customers with the latest solutions to enhance their experience.</p>
                                <p className="font_16 font_400 text_lightblue">At FINSAI, our purpose doesn’t just stop at transforming the financial landscape; we also focus on educating and empowering our customers with the right knowledge so they can achieve their financial goals.</p>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-4 mt-md-0">
                                <div className="d-flex justify-content-center">
                                    <img src="assets/images/target.png" className="img-fluid icon" alt="target" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="our_values">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="blue_00 text-center font_40 font_700 mb-4">Our Values</h2>
                                <p className="text-center gray_79 font_16 font_400 mb-0">At FINSAI, our values are built on four pillars:</p>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="innovation_box">
                                    <h5 className="font_20 font_600 mb-3">Innovation</h5>
                                    <p className="font_300 font_16 gray_52 mb-0">We strongly aim to reshape the finance industry and seek innovative solutions that enhance the customer’s journey. As technology evolves and advances, we implement every possible opportunity to and stay ahead of the curve to give our customers the latest solution to achieve their financial goals.</p>
                                    <div className="img_top">
                                        <img src="assets/images/bulb.png" className="img-fluid" alt="bulb" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="innovation_box">
                                    <h5 className="font_20 font_600 mb-3">Transparency</h5>
                                    <p className="font_300 font_16 gray_52 mb-0">We place trust as the fundamental value of all our relationships, and at FINSAI, we are committed to delivering the highest level of transparency in all our operations, ensuring that our customers are well informed about our services, fees, and policies.</p>
                                    <div className="second_img_top img_top">
                                        <img src="assets/images/transpenacvy.png" className="img-fluid" alt="transpenacvy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="innovation_box">
                                    <h5 className="font_20 font_600 mb-3">Partnership</h5>
                                    <p className="font_300 font_16 gray_52 mb-0">At FINSAI, customers mean everything to us. We're here to actively listen, assist, and tailor our services to align with your individual requirements, ensuring your utmost satisfaction. Your success is our success, and we are proud to be your trusted partner in your financial journey.</p>
                                    <div className="third_img_top img_top">
                                        <img src="assets/images/shake-hands.png" className="img-fluid" alt="shake-hands" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="innovation_box">
                                    <h5 className="font_20 font_600 mb-3">Integrity</h5>
                                    <p className="font_300 font_16 gray_52 mb-0">We believe in operating our business with the highest ethical standards. Our core foundations are built mainly on honesty, accountability, and equality, which encourage a culture of trust and reliability not just at our workplace but also with our customers.</p>
                                    <div className="fourth_img_top img_top">
                                        <img src="assets/images/growth.png" className="img-fluid" alt="growth" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="transform">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="future_together">
                                    <h2 className="font_40 font_700 text-center text_lightblue mb-3"> Let’s Transform Your Financial Future Together</h2>
                                    <p className="font_16 font_300 text-center text_lightblue mb-5">Register with FINSAI today and experience a new era of modern financial services at your convenience.</p>
                                    <div className="text-center d-sm-flex align-items-center justify-content-center">
                                        <Link to="/select-country" target="_blank" className="d-block img-fluid">
                                            <img src="assets/images/play-store.svg" className="me-sm-3 mb-3 mb-sm-0 img-fluid" alt="play store" />
                                        </Link>
                                        <Link to="/select-country" target="_blank" className="d-block img-fluid">
                                            <img src="assets/images/app-store.svg" className="img-fluid" alt="app store" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="discover">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="font_40 font_700 blue_00 text-center mb-5">Discover Without Limitations</h2>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="discover_box" data-match-height="groupName">
                                    <h5 className="font_20 font_600 mb-3">Media & Press Releases</h5>
                                    <p className="gray_52 font_16 font_300 mb-5">A story is incomplete without its visuals. If you wish to make a media enquiry, please don’t hesitate to reach out to us. You are free to request pictures or any resources that you deem beneficial for your coverage.</p>
                                    {/* <Link to="/" className="get_width_content get-started font_16 font_600 d-inline-block">Get more information</Link> */}
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
                                <div className="discover_box" data-match-height="groupName">
                                    <h5 className="font_20 font_600 mb-3">Join The FINSAI Family</h5>
                                    <p className="gray_52 font_16 font_300 mb-5">Do you wish to be a part of a vibrant community of professionals and make a difference? Then search no further! Like each member of our FINSAI family, you too could be a part of our mission to revolutionize the financial landscape for many users around the world. Let’s give everyone the chance to achieve financial freedom.</p>
                                    {/* <Link href="/" className="get_width_content get-started font_16 font_600 d-inline-block">Join our Community</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
            <Model />
        </>
    )
}

export default AboutUs