import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react';

const HeaderBusiness = () => {
    const [activeLink, setActiveLink] = useState('business');
    const location = useLocation();

    useEffect(() => {

        if (location.pathname === '/personal') {
            setActiveLink('personal');
        } else if (location.pathname === '/business') {
            setActiveLink('business');
        } else {
            setActiveLink('');
        }
    }, [location.pathname]);
    const handleLinkClick = (link) => {
        setActiveLink(link);
    };
    return (
        <>
            <header className="business_header header-space">
                <div className="container ">
                    <nav className="navbar navbar-expand-lg navbar-header navbar-light bg-transparent">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/business"><img src="assets/images/white-logo.svg" alt="logo" /></Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto ms-lg-5 mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className={`nav-link font_16 hover-underline-animation ${activeLink === 'personal' ? 'active' : ''}`} aria-current="page"
                                            to="/personal" onClick={() => handleLinkClick('personal')}>Personal</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link font_16 hover-underline-animation ${activeLink === 'business' ? 'active' : ''}`} to="/business" onClick={() => handleLinkClick('business')}>Business</Link>
                                    </li>
                                    {/* <li class="nav-item">
                                        <a class="nav-link font_16 hover-underline-animation" href="#">Pricing</a>
                                    </li>  */}
                                    <li className="nav-item">
                                        <a className={`nav-item nav-link font_16 hover-underline-animation ${activeLink === 'contact' ? 'active' : ''}`} href="#" data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop" onClick={() => handleLinkClick('contact')}>Contact us</a>
                                    </li>

                                </ul>
                                <form className="d-flex gap-4 align-items-center">
                                    <Link to="/select-country" target="_blank" className="text-white font_18 font_500">Login</Link>
                                    <Link to="/select-country" target="_blank" className="primary-btn font_15 font_500">Get Started</Link>
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default HeaderBusiness