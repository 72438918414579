import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Index from './pages';
import Country from './pages/select_country';
import WaitingList from './pages/waiting_list';
import AboutUs from './pages/about_us';
import Careers from './pages/careers';
import { addCountryList } from './store/slices/countryListSlice';
import { useDispatch } from 'react-redux';
import { getCountries } from './api/apiHandler';
import Swal from 'sweetalert2'
import Personal from './pages/personal';
import Business from './pages/business';
import InternationalTransfer from './pages/international_transfer';
import Payments from './pages/payments';
import Cards from './pages/cards';
import Accounts from './pages/accounts';
import CurrencyExchange from './pages/currency_exchange';
// import CookieConsent from "react-cookie-consent";
import { useState, useEffect } from 'react';
import TermsConditions from './pages/terms_conditions';
import PrivacyPolicy from './pages/privacy_policy';
import DataProtection from './pages/data_protection';
import RiskAssessment from './pages/risk_assessment';
import AMLPolicyProcedure from './pages/aml_policy_procedure';
import ComplianceMonitoringPlan from './pages/compliance_monitoring_plan';
import RiskAssessmentGapAnalysis from './pages/risk_assessment_gap_analysis';
import MonitoringPlan from './pages/monitoring_plan';
import RiskMatrix from './pages/risk_matrix';

function App() {
  const dispatch = useDispatch()

  getCountries({}).then((response) => {
    response = JSON.parse(response)
    if (response.code === 200) {
      dispatch(addCountryList(response.data))
    }
    else {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: response.message,
        toast: true,
        showConfirmButton: false,
        timer: 2000
      })
    }
  })

  const checkCookieStatus = () => {
    return localStorage.getItem("cookieConsent") === "true";
  };

  useEffect(() => {
    setAcceptedCookie(checkCookieStatus());
  }, []);

  // //This is for refreshing the page everytime when resizing window
  // useEffect(() => {
  //   setAcceptedCookie(checkCookieStatus());

  //   const handleResize = () => {
  //     window.location.reload();
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const [acceptedCookie, setAcceptedCookie] = useState(checkCookieStatus());

  const cookieResponse = () => {
    // localStorage.setItem("cookieConsent", true);
    localStorage.setItem("cookieConsent", false);

    setAcceptedCookie(true);
  };

  const cookieDecline = () => {
    localStorage.setItem("cookieConsent", false);
    // console.log(acceptedCookie)
    setAcceptedCookie(true);
  };

  return (

    <div className='h-100'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/select-country" element={<Country />} />
          <Route path="/waiting-list" element={<WaitingList />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/personal' element={<Personal />} />
          <Route path='/business' element={<Business />} />
          <Route path='/international-transfer' element={<InternationalTransfer />} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/cards' element={<Cards />} />
          <Route path='/accounts' element={<Accounts />} />
          <Route path='/currency-exchange' element={<CurrencyExchange />} />
          <Route path='/terms-conditions' element={<TermsConditions />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/data-protection-policy' element={<DataProtection />} />
          <Route path='/aml-risk-assessment' element={<RiskAssessment />} />
          <Route path='/aml-risk-assessment-gap-analysis' element={<RiskAssessmentGapAnalysis />} />
          <Route path='/aml-policy-procedure' element={<AMLPolicyProcedure />} />
          <Route path='/compliance-monitoring-plan' element={<ComplianceMonitoringPlan />} />
          <Route path='/monitoring-plan' element={<MonitoringPlan />} />
          <Route path='/risk-matrix' element={<RiskMatrix />} />
        </Routes>
      </BrowserRouter>

      {
        (acceptedCookie === false) ? (
          <div className="fixed-bottom col-12 col-xxl-3 col-xl-4 col-md-6 col-lg-4 col-sm-6 cookie cookie-custom" >

            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <div className='font_24 font_400'>We value your privacy</div>
              </div>
              <button style={{ background: "none" }} onClick={cookieDecline}><img src="assets/images/icon/close.svg" alt="close"
                className="" style={{ height: "30px", width: "30px" }} /></button>
            </div>

            <p className="font_16 mb-4">We use cookies to enhance your browsing experience. By clicking "Accept", you consent to our use of cookies.</p>
            <div className='d-flex gap-2'>
              <button onClick={cookieDecline} type="button" className="get-started-1 font_16 font_400" >
                <span>Reject</span>
              </button>
              <button onClick={cookieResponse} type="button" className="get-started-1 font_16 font_400" >
                <span>Accept</span>
              </button>
            </div>

          </div>
        ) : false
      }
    </div >
  );
}

export default App;