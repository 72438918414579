import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const PrivacyPolicy = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="terms-title-sec">
                    <div className="container">
                        <p className="title mb-0">Privacy Policy</p>
                    </div>
                </section>
                <section className="terms-content-sec privacy-sec">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="content-card">
                                    <p>FINSAI (“FINSAI”, “we,” “our” or “us”) is committed to protecting your privacy. The
                                        following
                                        privacy policy details how FINSAI collects, processes, uses and protects your personal
                                        information when you visit or login to our website (FINSAI.com), it describes your
                                        choices
                                        regarding use, access and correction of your personal information, and applies to all
                                        persons
                                        using our services, website and/or mobile application. Use of our services, website
                                        and/or
                                        mobile application constitutes consent to the data collection, processing, use and
                                        security
                                        practices detailed herein.</p>
                                    <p>We periodically post changes to our privacy policy. We therefore recommend that you
                                        consult this
                                        page frequently or at any time should concerns arise. If any significant changes are
                                        made, as is
                                        required by law, we will notify all of our website users. Furthermore, if changes are
                                        significant, we will provide a more prominent notice (including, for certain services,
                                        email
                                        notification of privacy policy changes).</p>
                                    <p>Please read the privacy policy set out herein carefully before using the services
                                        provided by
                                        FINSAI. By using or accessing the website (FINSAI.com), or the mobile application or the
                                        services offered by FINSAI, you agree that this privacy policy shall apply to it. If you
                                        do not
                                        agree to any of the provisions of this privacy policy, you should immediately cease
                                        using the
                                        website, the mobile application and the services.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4 ms-lg-3 me-lg-3">
                            <div className=" col-xl-4 col-lg-4 col-md-5">
                                <div className="nav custom-tabs rounded flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-tab1" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        <p className="num mb-0">1</p>
                                        <p className="mb-0 text">Purpose and consent</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-2"
                                        type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        <p className="num mb-0">2</p>
                                        <p className="mb-0 text">Information we collect
                                            about you</p>

                                    </button>
                                    <button className="nav-link" id="v-pills-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-3"
                                        type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        <p className="num mb-0">3</p>
                                        <p className="mb-0 text">Use of information</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-4"
                                        type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        <p className="num mb-0">4</p>
                                        <p className="mb-0 text">Disclosure of your
                                            information to third parties</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-5"
                                        type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                        <p className="num mb-0">5</p>
                                        <p className="mb-0 text">International transfer
                                            of information</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab6" data-bs-toggle="pill" data-bs-target="#v-pills-6"
                                        type="button" role="tab" aria-controls="v-pills-6" aria-selected="false">
                                        <p className="num mb-0">6</p>
                                        <p className="mb-0 text">Third-party advertising and analytics</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab7" data-bs-toggle="pill" data-bs-target="#v-pills-7"
                                        type="button" role="tab" aria-controls="v-pills-7" aria-selected="false">
                                        <p className="num mb-0">7</p>
                                        <p className="mb-0 text">Your rights to your information</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab8" data-bs-toggle="pill" data-bs-target="#v-pills-8"
                                        type="button" role="tab" aria-controls="v-pills-8" aria-selected="false">
                                        <p className="num mb-0">8</p>
                                        <p className="mb-0 text">Security of your information</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab9" data-bs-toggle="pill" data-bs-target="#v-pills-9"
                                        type="button" role="tab" aria-controls="v-pills-9" aria-selected="false">
                                        <p className="num mb-0">9</p>
                                        <p className="mb-0 text">Opt out and deactivation options</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab9" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-10" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">10</p>
                                        <p className="mb-0 text">Data retention policy</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab11" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-11" type="button" role="tab" aria-controls="v-pills-11"
                                        aria-selected="false">
                                        <p className="num mb-0">11</p>
                                        <p className="mb-0 text">Data protection officer and
                                            our contact details</p>
                                    </button>
                                    <button className="nav-link" id="v-pills-tab12" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-12" type="button" role="tab" aria-controls="v-pills-10"
                                        aria-selected="false">
                                        <p className="num mb-0">12</p>
                                        <p className="mb-0 text">Amendments</p>
                                    </button>

                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-7 bg-lightblue">

                                <div className="tab-content" id="v-pills-tabContent">

                                    {/* <!-- <div className="tab-pane fade " id="v-pills-default" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="terms-para">

                                                    <p className="mb-0">PLEASE READ THE TERMS & CONDITIONS SET OUT HEREIN (THIS
                                                        “AGREEMENT”)
                                                        CAREFULLY BEFORE
                                                        ACCEPTING THEM AND USING THE SERVICES PROVIDED BY US. YOU AGREE THAT ANY
                                                        USE OF OUR
                                                        SERVICES BY YOU SHALL CONSTITUTE YOUR ACCEPTANCE OF THIS AGREEMENT. IF
                                                        YOU DO NOT
                                                        ACCEPT
                                                        THE TERMS AND CONDITIONS OUTLINED IN THIS AGREEMENT, DO NOT ACCESS OR
                                                        USE FINSAI
                                                        SERVICES.</p>

                                                </div>
                                                <div className="content-card">
                                                    <p className="mb-0">ALLOY PAY Limited is a legal entity (13858479) trading as
                                                        The activity of
                                                        FINSAI. The
                                                        activity of Finsai is regulated by the Payment Services Regulation 2017
                                                        and the
                                                        Electronic Money Regulation 2011 (SI 2011/99). This Agreement is an
                                                        electronic
                                                        agreement between you and FINSAI . This Agreement emphasizes certain
                                                        risks
                                                        associated with the use of our services. This Agreement is made in
                                                        English and the
                                                        English version of the Agreement prevails over any other version
                                                        translated into any
                                                        other language, which may be provided by us to you for convenience
                                                        purposes.</p>
                                                </div>
                                            </div>
                                            <p className="note-p">Note : Please print off and retain a hard copy of these<br> terms
                                                &
                                                conditions for
                                                your records.</p>
                                        </div>

                                    </div> --> */}
                                    <div className="tab-pane fade active show" id="v-pills-1" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <div className="pt-0 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 text-black font_700 pt-2">1. Purpose and consent</p>
                                                    <div className="d-flex gap-3 ps-2">
                                                        <p className="font_500">1.1</p>
                                                        <p>This privacy policy has been developed for purposes of compliance
                                                            with the Regulation (EU) 2016/679 of the European Parliament and of
                                                            the Council of 27 April 2016 on the protection of natural persons
                                                            with regard to the processing of personal data and on the free
                                                            movement of such data (“GDPR”) and the Data Protection Act 2018
                                                            (“DPA”), including any amendments or replacements to them.</p>
                                                    </div>
                                                    <div className="d-flex gap-3 ps-2">
                                                        <p className="font_500">1.2</p>
                                                        <p>Without information about you, we may not be able to provide you with the services or the support you request or require. Some of the information we obtain is collected to comply with applicable laws and regulations, including anti-money laundering laws.</p>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex gap-3 ps-2">
                                                            <p className="font_500 mb-1">1.3</p>
                                                            <p className="mb-1">This privacy policy explains:
                                                            </p>

                                                        </div>
                                                        <ol className="ul-liststyle margin-left" type="a">
                                                            <li>Types of information we collect about you;</li>
                                                            <li>How we use information about you;</li>
                                                            <li>Types of information we disclose to third parties and the types
                                                                of such third parties;</li>
                                                            <li>How we protect your personally identifiable information; and
                                                            </li>
                                                            <li>
                                                                How you may instruct us not to disclose certain information
                                                                about
                                                                you which we are otherwise permitted to disclose by law.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div className="d-flex gap-3 ps-2">
                                                        <p className="font_500 mb-1">1.4</p>
                                                        <p className="mb-1">By applying or signing up for an account with FINSAI,
                                                            you authorize and consent to our obtaining from, and disclosing to,
                                                            third parties any information about you in connection with identity
                                                            or account verification, fraud detection, or collection procedure,
                                                            or as may otherwise be required by applicable law.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-2" role="tabpanel"
                                        aria-labelledby="v-pills-profile-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">2. Information we collect about you
                                                    </p>
                                                    <div>
                                                        <p className="font_20  mb-2"><span className="pe-3 font_22 font_500">2.1</span>
                                                            We collect the
                                                            following
                                                            information about you:</p>
                                                        <ol className="ul-liststyle margin-left" type="a">
                                                            <li>Identification information, such as your name, email address,
                                                                home
                                                                address, phone number and date of birth, along with
                                                                identification
                                                                details of documents confirming your identification and address;
                                                            </li>
                                                            <li>
                                                                Financial information, including without limitation bank account
                                                                and
                                                                payment card numbers, bank statements, etc.;</li>
                                                            <li>Other information you provide when you participate in contests
                                                                or
                                                                promotions offered by us or our partners, respond to our surveys
                                                                or
                                                                otherwise communicate with us;</li>
                                                            <li>Information about when and where the transactions occur, the
                                                                names
                                                                of the transacting parties, a description of the transactions,
                                                                the
                                                                payment or transfer amounts, billing and shipping information,
                                                                and
                                                                the devices and payment methods used to complete the
                                                                transactions;
                                                            </li>

                                                            <li>

                                                                Information about the location of your device and some other
                                                                device
                                                                specifics, including your hardware model, operating system and
                                                                version, unique device identifier, mobile network information,
                                                                and
                                                                information about the device’s interaction with our services. We
                                                                may
                                                                also identify other software running on the device for
                                                                anti-fraud
                                                                and malware-prevention purposes (but will not collect any
                                                                content
                                                                from such software);
                                                            </li>
                                                            <li>
                                                                Information about how you use our services, including your
                                                                access
                                                                time, browser type and language, and IP address;
                                                            </li>
                                                            <li>
                                                                Information about you from third parties, including third-party
                                                                verification services, credit bureaus, mailing list providers
                                                                and
                                                                publicly available sources (where lawful, this information may
                                                                include your government-issued identification number);
                                                            </li>
                                                            <li>
                                                                Information collected by cookies and web beacons, including
                                                                using
                                                                web beacons and sending cookies to your device (for more
                                                                information, please refer to our Cookies Policy at&nbsp;
                                                                <a href="https://www.finsai.com/cookie-policy" className="link">
                                                                    https://www.finsai.com/cookie-policy;</a>
                                                            </li>
                                                            <li>
                                                                Pictures of your identification card, utility bills and other
                                                                documents as may be requested by us and provided by you;
                                                            </li>
                                                            <li>
                                                                Employment information; and
                                                            </li>
                                                            <li>
                                                                Information contained in or relating to any communication that
                                                                you
                                                                send to us with or without our request, including without
                                                                limitation
                                                                the communication content and metadata associated with the
                                                                communication.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div className="d-flex gap-3">
                                                        <p className="pe-2 font_22 font_500">2.2</p>
                                                        <p className="font_20  mb-2">
                                                            We may collect
                                                            the Information in the course of your signing up for an account with
                                                            FINSAI, or in the course of our identity or account verification
                                                            process, or in the course of your use of your account with us or our
                                                            services.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="v-pills-3" role="tabpanel"
                                        aria-labelledby="v-pills-messages-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">3. Use of information
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">3.1</p>
                                                            <p className="font_20  mb-2">
                                                                We do not sell, exchange or give to any other person your
                                                                information, whether public or private, for any reason
                                                                whatsoever,
                                                                without your consent, other than for the express purpose of
                                                                providing our services to you.</p>
                                                        </div>

                                                    </div>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">3.2</p>
                                                            <p className="font_20  mb-2">
                                                                We collect, process and use information about you for the
                                                                following purposes:</p>
                                                        </div>
                                                        <ol className="ul-liststyle margin-left" type="a">
                                                            <li>
                                                                To provide the services to you, including without limitation to
                                                                process your transactions;
                                                            </li>

                                                            <li>
                                                                To improve, personalize and facilitate your use of our services,
                                                                e.g. when you sign up for an account with FINSAI, we may
                                                                associate certain information with your new account, such as
                                                                information about prior transactions you made using our
                                                                services;
                                                            </li>
                                                            <li>To measure, customize, and enhance our services, including the
                                                                design, content, and functionality of our mobile application and
                                                                website, or to track and analyze trends and usage in connection
                                                                with our services;
                                                            </li>
                                                            <li>To analyze the use of our services;
                                                            </li>

                                                            <li>
                                                                To improve our customer service;
                                                            </li>
                                                            <li>
                                                                To send periodic emails, news and information, or to conduct
                                                                surveys and collect feedback, about our services and to
                                                                communicate with you about products, services, contests,
                                                                promotions, discounts, incentives and rewards offered by us and
                                                                our partners, based on your communication preferences and
                                                                applicable law, and the email address you provided for such
                                                                communications;
                                                            </li>

                                                            <li>
                                                                To administer our internal information processing and other IT
                                                                systems;
                                                            </li>

                                                            <li>
                                                                To operate our website and services, including to ensure their
                                                                security;
                                                            </li>
                                                            <li>
                                                                To maintain back-ups of our databases and to keep the records in
                                                                accordance with our internal policies and procedures and the
                                                                applicable law;
                                                            </li>
                                                            <li>
                                                                To communicate with you, including without limitation to deliver
                                                                the information and support you request, including technical
                                                                notices, security alerts, and support and administrative
                                                                messages, to resolve disputes, collect fees, and provide
                                                                assistance for problems with our services or your account with
                                                                us;
                                                            </li>
                                                            <li>
                                                                To establish, exercise or defend legal claims, whether in court
                                                                proceedings or in an administrative or out-of-court procedure
                                                                for the protection and assertion of our legal rights, your legal
                                                                rights and the legal rights of others;

                                                            </li>
                                                            <li>To obtain or maintain insurance coverage, to manage risks, or
                                                                obtain professional advice;
                                                            </li>

                                                            <li>To comply with our obligations either required by law or by
                                                                written agreements with third parties;
                                                            </li>
                                                            <li>
                                                                To display transactions history;
                                                            </li>
                                                            <li>
                                                                To develop new products and services;
                                                            </li>
                                                            <li>
                                                                To protect our rights or property, or the security or integrity
                                                                of our services;
                                                            </li>
                                                            <li>
                                                                To enforce the terms of our Terms and Conditions or other
                                                                applicable agreements or policies;
                                                            </li>
                                                            <li>
                                                                To verify your identity (e.g. some of the government-issued
                                                                identification numbers we collect are used for this purpose);
                                                            </li>
                                                            <li>
                                                                To investigate, detect, and prevent fraud, security breaches and
                                                                other potentially prohibited or illegal activities; and
                                                            </li>
                                                            <li>
                                                                To comply with any applicable law, regulation or legal process.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div className="d-flex gap-3">
                                                        <p className="pe-2 font_22 font_500">3.3</p>
                                                        <p className="font_20  mb-2">
                                                            We may use third-party service providers to, process and store
                                                            your information in the United Kingdom and other countries at our
                                                            sole discretion.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-4" role="tabpanel"
                                        aria-labelledby="v-pills-settings-tab">
                                        <div className="pt-3 content--main">
                                            <div>

                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">4. Disclosure of your information to
                                                        third parties
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">4.1</p>
                                                            <p className="font_20  mb-2">
                                                                Any third party that receives or has access to the information
                                                                listed in Section 3 of this Privacy Policy is required to
                                                                protect such information and use it only to carry out the
                                                                services they are performing for you or for FINSAI, unless
                                                                otherwise required or permitted by law. We shall ensure any such
                                                                third party is aware of our obligations under this privacy
                                                                policy and we enter into contracts with such third parties by
                                                                which they are bound by terms no less protective of any
                                                                information disclosed to them than the obligations we undertake
                                                                to you under this privacy policy or which are imposed on us
                                                                under applicable data protection laws.</p>
                                                        </div>

                                                    </div>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">4.2</p>
                                                            <p className="font_20  mb-2">
                                                                We may disclose any and all information specified in Section 3.2
                                                                of this privacy policy to:</p>
                                                        </div>
                                                        <ol className="ul-liststyle margin-left" type="a">

                                                            <li>our suppliers or subcontractors as reasonably necessary for
                                                                providing our services to you;</li>

                                                            <li>
                                                                our payment services providers only to the extent necessary
                                                                for the purposes of processing your payments, refunding such
                                                                payments and dealing with complaints and queries relating to
                                                                such payments and refunds;</li>
                                                            <li>our partners, governmental bodies and regulatory
                                                                authorities, judicial bodies, our associates, agents,
                                                                attorneys or other representatives for compliance with legal
                                                                obligations to which we are subject or for the
                                                                establishment, exercise or defense of legal claims, whether
                                                                in court proceedings or in an administrative or out-of-court
                                                                procedure;</li>
                                                            <li>Our group companies, including our affiliates, for rendering
                                                                our services, compliance with applicable laws and improving
                                                                the quality of our services;
                                                            </li>

                                                            <li>
                                                                Our business partners that run advertising campaigns,
                                                                contests, special offers, or other events or activities in
                                                                connection with our services;
                                                            </li>
                                                            <li>
                                                                Other users of our services with whom you interact through
                                                                your own use of our services, e.g. we may share information
                                                                when you make a transaction.</li>
                                                        </ol>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-5" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">5. International transfer of
                                                        information
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">5.1</p>
                                                            <p className="font_20  mb-2">
                                                                Without prejudice to Section 4 of this privacy policy, you
                                                                should be aware that we may transfer your information outside
                                                                EEA, as shall be designated herein from time to time, solely for
                                                                the purposes of providing our services to you and only on a need
                                                                to know basis. Each transfer will be protected by appropriate
                                                                safeguards of the relevant legally binding obligations of the
                                                                recipient to protect and handle your information in accordance
                                                                with this privacy policy, GDPR and DPA.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">5.2</p>
                                                            <p className="font_20  mb-2">
                                                                As an international company with various global partnerships,
                                                                FINSAI interacts with certain third-party service providers,
                                                                such as payment gateways and other payment transaction
                                                                processors, which you should be aware that they may be located,
                                                                or have facilities that are located, outside EEA in a different
                                                                jurisdiction than either you or us. Therefore, if you elect to
                                                                proceed with a transaction that involves the services of a
                                                                third-party service provider, then your personal information may
                                                                become subject to the laws of the jurisdiction(s) in which that
                                                                service provider or its facilities are located, e.g. if you are
                                                                located in the United Kingdom and your transaction is processed
                                                                by a payment gateway located in Australia, then your personal
                                                                information used in completing that transaction may be subject
                                                                to disclosure under Australian legislation. For these providers,
                                                                we recommend that you read their privacy policies, so you can
                                                                understand the manner in which your personal information will be
                                                                handled by these providers.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-6" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">6. Third-party advertising and
                                                        analytics
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">6.1</p>
                                                            <p className="font_20  mb-2">
                                                                We may allow third-party service providers to deliver content
                                                                and advertisements in connection with our services and to
                                                                provide anonymous site metrics and other analytics services.
                                                                These third parties may use cookies, web beacons and other
                                                                technologies to collect information, such as your IP address,
                                                                identifiers associated with your device, other applications on
                                                                your device, the browsers you use to access our services, web
                                                                pages viewed, time spent on webpages, links clicked, and
                                                                conversion information (e.g. transactions entered into). This
                                                                information may be used by us and third-party service providers
                                                                on our behalf to analyze and track usage of our services,
                                                                determine the popularity of certain content, deliver advertising
                                                                and content targeted to your interests, and better understand
                                                                how you use our services.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">6.2</p>
                                                            <p className="font_20  mb-2">
                                                                The third-party service providers that we engage are bound by
                                                                confidentiality obligations and applicable laws with respect to
                                                                their use and collection of your information.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">6.3</p>
                                                            <p className="font_20  mb-2">
                                                                THIS PRIVACY POLICY DOES NOT APPLY TO, AND WE ARE NOT
                                                                RESPONSIBLE FOR, THIRD-PARTY COOKIES, WEB BEACONS, OR OTHER
                                                                TRACKING TECHNOLOGIES, WHICH ARE COVERED BY SUCH THIRD PARTIES’
                                                                PRIVACY POLICIES. FOR MORE INFORMATION, WE ENCOURAGE YOU TO
                                                                CHECK THE PRIVACY POLICIES OF THESE THIRD PARTIES TO LEARN ABOUT
                                                                THEIR PRIVACY PRACTICES.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-7" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">7. Your rights to your information
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">7.1</p>
                                                            <p className="font_20  mb-2">
                                                                You have the following rights with respect to your information:
                                                            </p>
                                                        </div>
                                                        <ul className="ul-liststyle margin-left ">

                                                            <li><span className="font_600">Right to Confirmation -</span> you have
                                                                the right to confirm that we process your information and to
                                                                access such information together with the details of the
                                                                purposes of the processing, the categories of information
                                                                concerned and the recipients of your information. You have a
                                                                right to request and receive a copy of your information that we
                                                                have subject to compliance with the applicable laws. You can
                                                                access your information by logging into your account with FINSAI
                                                                and checking the relevant section in the account interface.</li>

                                                            <li><span className="font_600">Right to Rectification -</span> you have
                                                                the right to rectify any inaccurate information about you and to
                                                                complete any incomplete information about you.</li>
                                                            <li><span className="font_600">Right to Erasure - </span>you have the
                                                                right to demand erasure of your information with us if: </li>
                                                            <ul className="ul-liststyle ">
                                                                <li>The information is no longer necessary in relation to the
                                                                    purposes for which it was collected or otherwise processed;
                                                                </li>
                                                                <li>You withdraw consent to consent-based processing;</li>
                                                                <li>You object to the processing of your information under the
                                                                    applicable law;</li>
                                                                <li>The processing of your information is done for direct
                                                                    marketing purposes only; and</li>
                                                                <li>Your information has been unlawfully processed. We may
                                                                    refuse your demand if your Information is processed for
                                                                    exercising the right of freedom of expression and
                                                                    information, compliance with a legal obligation or
                                                                    establishment, exercise or defense of legal claims. </li>
                                                            </ul>
                                                            <li><span className="font_600">Right to Restrict Processing - </span>you
                                                                have the right to restrict processing of your information if:
                                                            </li>
                                                            <ul className="ul-liststyle ">
                                                                <li>You contest the accuracy of the information;
                                                                </li>
                                                                <li>Processing is unlawful, but you oppose erasure;</li>
                                                                <li>We no longer need the information for the purposes of our
                                                                    processing, but you require information for the
                                                                    establishment, exercise or defense of legal claims; and</li>
                                                                <li>You have objected to processing, pending the verification of
                                                                    that objection, in which case we may continue to store your
                                                                    information, but we will only otherwise process it: (i) with
                                                                    your consent; (ii) for the establishment, exercise or
                                                                    defense of legal claims; (iii) for the protection of the
                                                                    rights of another natural or legal person; or (iv) for
                                                                    reasons of important public interest. </li>

                                                            </ul>
                                                            <li><span className="font_600 pe-2">Right to Object to Processing -
                                                            </span>you have the right to object to our processing of your
                                                                information by any reason to the extent that the legal basis for
                                                                the processing is the performance of a task carried out in the
                                                                public interest or in the exercise of any official authority
                                                                vested in us; or the purposes of the legitimate interests
                                                                pursued by us or by a third party. Please note, that we will
                                                                cease processing your information if such processing was done
                                                                for direct marketing purposes. Otherwise, we may disregard your
                                                                objection if we can demonstrate compelling legitimate grounds
                                                                for the processing which override your interests, rights and
                                                                freedoms, or the processing is for the establishment, exercise
                                                                or defense of legal claims.
                                                            </li>
                                                            <li><span className="font_600">Data Portability - </span>to the extent
                                                                that:
                                                            </li>
                                                            <ul className="ul-liststyle ">
                                                                <li>
                                                                    The legal basis for our processing of your information is
                                                                    your consent, a necessity to perform a contract to which you
                                                                    are a party, or to satisfy your request prior to entering
                                                                    into a contract,
                                                                </li>
                                                                <li>Such processing is carried out by automated means, you have
                                                                    the right to receive your information from us in a
                                                                    structured, commonly used and machine-readable format.
                                                                    However, we may refuse to provide you with your information
                                                                    if it would adversely affect the rights and freedoms of
                                                                    third parties.</li>


                                                            </ul>
                                                            <li><span className="font_600">Right to Complain - </span> if you
                                                                consider that our processing of your information violates
                                                                applicable laws and regulations, you have a legal right to file
                                                                a complaint with the U.K. Information Commissioner by following
                                                                the instructions provided at <a
                                                                    href="https://ico.org.uk/concerns/"
                                                                    className="link">https://ico.org.uk/concerns/</a> or
                                                                otherwise as may be convenient for you.
                                                            </li>
                                                            <li><span className="font_600">Right to Withdraw Consent - </span> You
                                                                may withdraw your consent to us processing your information at
                                                                any time, however such withdrawal will not affect the lawfulness
                                                                of processing of your information before it.
                                                            </li>
                                                        </ul>

                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">7.2</p>
                                                            <p className="font_20  mb-2">
                                                                you may exercise any of those rights by sending an email at&nbsp;
                                                                <a href={"mailto: office@FINSAI.co.uk?subject=&body=Description"} className="link">
                                                                    office@FINSAI.co.uk</a> or by using technical tools and
                                                                features of
                                                                the interface of your account with FINSAI. Please note that some
                                                                of your rights related to your information are quite complex,
                                                                and you are advised to read the relevant laws and guidance from
                                                                the regulatory authorities for a full explanation of these
                                                                rights
                                                                need to create it
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-8" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">8. Security of your information
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">8.1</p>
                                                            <p className="font_20  mb-2">
                                                                We take reasonable measures, including administrative,
                                                                technical, and physical safeguards, to protect your information
                                                                from loss, theft, misuse and unauthorized access, disclosure,
                                                                alteration, and destruction. We hold information about you at
                                                                our own premises and with the assistance of third-party service
                                                                providers. We restrict access to personal information to our
                                                                employees, contractors and agents who need to know that
                                                                information in order to transmit, store or process it, who are
                                                                subject to contractual confidentiality obligations consistent
                                                                with this privacy policy, and who may be disciplined or
                                                                terminated if they fail to meet these obligations.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">8.2</p>
                                                            <p className="font_20  mb-2">
                                                                Our third-party service providers store and transmit personal
                                                                information in compliance with this privacy policy and other
                                                                appropriate confidentiality and security measures. Nevertheless,
                                                                we cannot guarantee that unauthorized third parties will never
                                                                be able to defeat our security measures or use your personal
                                                                information for improper purposes.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">8.3</p>
                                                            <p className="font_20  mb-2">
                                                                In the event that any information in our possession or under our
                                                                control is compromised as a result of a security breach, we will
                                                                take reasonable steps to investigate the situation and, where
                                                                appropriate, notify those individuals whose information may have
                                                                been compromised and take other steps in accordance with
                                                                applicable laws or regulations.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-9" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">9. Opt out and deactivation options
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">9.1</p>
                                                            <p className="font_20  mb-2">
                                                                If you wish to deactivate your account with FINSAI, you may do
                                                                so by contacting our customer service. We may retain archived
                                                                copies of information about you and any transactions or services
                                                                in which you may have participated for a period of time that is
                                                                consistent with applicable law, or as we believe is reasonably
                                                                necessary to comply with applicable law, regulation, or legal
                                                                process, to prevent fraud, to collect fees owed, to resolve
                                                                disputes, to address problems with our services, to assist with
                                                                investigations, to enforce our Terms and Conditions or other
                                                                applicable agreements or policies, or to take any other actions
                                                                consistent with applicable law.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">9.2</p>
                                                            <p className="font_20  mb-2">
                                                                If you do not consent to collection of the device location
                                                                information, you may be unable to use our corresponding
                                                                services. You can stop our collection of location information at
                                                                any time by changing the preferences on your mobile device. If
                                                                you do so, some of our mobile applications will no longer
                                                                function. You also may stop our collection of location
                                                                information via mobile application by following the standard
                                                                uninstall process to remove our mobile applications from your
                                                                device.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">9.3</p>
                                                            <p className="font_20  mb-2">
                                                                Some of the cookies used are set by us and others are set by
                                                                third parties who deliver services on our behalf. Most web and
                                                                mobile device browsers are set to automatically accept cookies
                                                                by default. However, you can change your browser settings to
                                                                prevent automatic acceptance of cookies or to notify you each
                                                                time a cookie is set.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">9.4</p>
                                                            <p className="font_20  mb-2">
                                                                You may opt out of receiving promotional messages from us by
                                                                following the instructions in those messages or by changing your
                                                                notification settings by logging into your account with us. If
                                                                you decide to opt out, we may still send you non-promotional
                                                                communications, such as digital receipts and messages about your
                                                                account activities.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                    <div className="tab-pane fade" id="v-pills-10" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">10. Data retention policy
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">10.1</p>
                                                            <p className="font_20  mb-2">
                                                                We will hold your information for 5 (five) years from the date
                                                                of your last use of our services unless otherwise required by
                                                                the applicable law. We do not retain or store your bank card or
                                                                bank account information, unless otherwise required by the
                                                                applicable law.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">10.2</p>
                                                            <p className="font_20  mb-2">
                                                                Notwithstanding Section 10.1, we may retain your information
                                                                where such retention is necessary for compliance with a legal
                                                                obligation to which we are subject, or in order to protect your
                                                                vital interests or the vital interests of another natural
                                                                person.</p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-11" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">11. Data protection officer and our
                                                        contact details
                                                    </p>
                                                    <div className="ps-4">
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">11.1</p>
                                                            <p className="font_20  mb-2">
                                                                FINSAI Limited is a private limited company, incorporated in
                                                                England and Wales.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">11.2</p>
                                                            <p className="font_20  mb-2">
                                                                Any questions or concerns regarding this privacy policy or your
                                                                rights related to protection of your personal information may be
                                                                sent via email at <a className="link"
                                                                    href={"mailto: dpo@FINSAI.co.uk?subject=&body=Description"}>dpo@FINSAI.co.uk</a> or at the
                                                                following address:
                                                            </p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">11.3</p>
                                                            <p className="font_20  mb-2">
                                                                In order to ensure effective and legal handling of our
                                                                customers’ information, we have appointed a Data Protection
                                                                Officer. You can reach our Data Protection Officer by sending an
                                                                email at <a href={"mailto: dpo@FINSAI.co.uk?subject=&body=Description"}
                                                                    className="link">dpo@FINSAI.co.uk.</a>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-12" role="tabpanel">
                                        <div className="pt-3 content--main">
                                            <div>
                                                <div className="content-card">
                                                    <p className="font_26 black_21 font_700">12. Amendments
                                                    </p>
                                                    <div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">12.1</p>
                                                            <p className="font_20  ">
                                                                We reserve the right to modify, alter or otherwise update this
                                                                privacy policy at any time, by posting such changed, updated or
                                                                modified policy on our website.</p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">12.2</p>
                                                            <p className="font_20  mb-2">
                                                                Should you not wish to accept those changes, you should notify
                                                                us and we will take that as an instruction to close your account
                                                                held with us.
                                                            </p>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="font_22 font_500">12.3</p>
                                                            <p className="font_20  mb-2">
                                                                Should we not hear from you, your continued use of our services
                                                                constitutes your acceptance of any amendment of this privacy
                                                                policy. In the event that you close your account following
                                                                rejection of the changes, we will still hold your personal data
                                                                on file, to adhere to applicable law.
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}

export default PrivacyPolicy