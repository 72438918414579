import { Link, useNavigate } from "react-router-dom"
import ScrollToTop from "../components/scroll_top"
import Swal from 'sweetalert2'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from "react-redux";
import { useState } from "react";



const Country = () => {
    const navigate = useNavigate();

    const countryList = useSelector((state) => {
        return state.countryList;
    })
    // console.log("countryList=============", countryList[0])
    // const [countryList, setCountryList] = useState(null)

    // useEffect(() => {
    //     // getCountries({}).then((response) => {
    //     //     response = JSON.parse(response)
    //     //     console.log("-------------->", response.data)
    //     //     if (response.code === 200) {
    //     //         console.log(response.data)
    //     //         setCountryList(response.data)
    //     //     }
    //     // })
    //     getCountries({}).then((response) => {
    //         response = JSON.parse(response)
    //         if (response.code === 200) {
    //             setCountryList(response.data)
    //         }
    //     })
    // }, [])

    // console.log("data", countryList)
    // console.log("data", data);

    const [country, setCountry] = useState('United Kingdom')
    const handleCountryCode = (e) => {
        setCountry(e.target.value)
    }
    const validationSchema = Yup.object().shape({
        country_name: Yup.string()
            .required('Country is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;



    const onSubmit = (data) => {
        console.log(data.country_name)
        localStorage.setItem("country", data.country_name)
        navigate("/waiting-list");
    }

    function convertUnicodeToEmoji(unicodeString) {
        if (!unicodeString) return '';

        const codePoints = unicodeString.split(' ');
        // console.log(parseInt('U+1F1EC', 16))

        const emojiCharacters = codePoints
            .filter((codePoint) => !isNaN(parseInt(codePoint.slice(2), 16)))
            .map((codePoint) => String.fromCodePoint(parseInt(codePoint.slice(2), 16)));

        // console.log(emojiCharacters)

        return emojiCharacters.join('');
    }
    return (
        <>
            <ScrollToTop />
            <main className="h-100">
                <section className="h-100">
                    <div className="container h-100">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-10 col-12">
                                <div className="select-country-card">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div>
                                            <p className="font_24 font_700 mb-2">Rediscover the Art of Payments</p>
                                            <p className="font_14 text mb-0">Open your FINSAI account in Few minutes.</p>
                                        </div>
                                        <Link to="/"> <img src="assets/images/icon/Close.png" alt="close"
                                            className="close-btn-img" /></Link>

                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="input-group mb-4">

                                            {/* <span className="input-group-text border-0" id="basic-addon1">
                                                <img src="assets/images/aus-flag.png" alt="" />
                                            </span> */}
                                            <span className="input-group-text border-0" id="basic-addon1" style={{ "fontSize": "1.6rem" }}>
                                                {countryList[0] ? (

                                                    convertUnicodeToEmoji(countryList[0].find((item) => item.en_name === country)?.emojiU)
                                                    // <img src={convertUnicodeToEmoji(countryList[0].find((item) => item.dial_code === countryCode)?.emojiU)} alt="" />
                                                ) : null}
                                            </span>
                                            <select className="form-select border-0" aria-label="Default select example" {...register('country_name')} onChange={(event) => handleCountryCode(event)}>
                                                <option value="United Kingdom">United Kingdom</option>
                                                {countryList[0] ? countryList[0].map((item, key) => {
                                                    return (
                                                        <option value={item.en_name} key={item.id}>{item.en_name}</option>
                                                    )
                                                }) : null}
                                            </select>
                                        </div>
                                        <div className="invalid-feedback" style={{ textAlign: "center", marginBottom: "8px" }}>{errors.country_name?.message}</div>

                                        <div className="row justify-content-center">
                                            <div className="col-lg-8 col-md-8">
                                                {/* <Link to="/waiting-list" className="get-start font_18 text-center text-white">Get
                                                    Started</Link> */}
                                                <button type="submit" style={{ "border": "none" }} className="get-start font_18 text-center text-white">Get
                                                    Started</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </main>
        </>



    )
}
export default Country