import { Link } from "react-router-dom"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"

const RiskAssessmentGapAnalysis = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main>
                <Header />
                <section className="analysis-title">
                    <div className="container">
                        <p className="mb-4 font_24 font_300 black_21">
                            <Link to="/aml-risk-assessment"> <img src="assets/images/icon/left-arrow-icon.png"
                                className="title-icon" alt="icon" /></Link>
                            AML/CTF Risk Assessment
                        </p>
                        <div className="content-card">
                            <p className="black_21 font_600">Attachment 1: FINSAI AML / CFT Risk Assessment GAP analysis</p>
                            <div className="table-assess table-responsive mb-3">
                                <table className="table rounded-corners">
                                    <thead>
                                        <tr>
                                            <th colspan="4" className="text-center">FINSAI AML / CFT Risk Assessment GAP analyze
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="gray_42">
                                        <tr className="td-heading">
                                            <td className="text-center font_18 black_21">GAP Descriptions</td>
                                            <td className="text-center font_18 black_21">Yes</td>
                                            <td className="text-center font_18 black_21">No</td>
                                            <td className="text-center font_18 black_21">
                                                N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <p className="invisible mb-0 pb-0 font_16">1</p>
                                            </td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="text-center font_16 black_21">AML/CFT systems</td>
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                            <td className="text-center">

                                            </td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16 black_21">FINSAI required to assess their ML / TF risk and then
                                                implement appropriate internal policies, procedures and controls (hereafter
                                                collectively referred to as “AML/CFT systems”) to mitigate risks of ML/TF.</td>
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                            <td className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you taken into account the following risk factors
                                                when
                                                assessing your own ML / TF risk?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>



                                        </tr>
                                        <tr>
                                            <td className="font_14 font_500">(a) Product / service risk</td>
                                            <td className=" font_500">Yes</td>
                                            <td className="font_500"></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="font_500 font_14">(b) Delivery / distribution
                                                channel risk
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td className="font_500"></td>
                                            <td className="font_500"></td>

                                        </tr>
                                        <tr>
                                            <td className=" font_500 font_14">(c) Customer risk
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td className="font_500"></td>
                                            <td className="font_500"></td>

                                        </tr>
                                        <tr>
                                            <td className="font_500 font_14">(d) Country risk
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td className="font_500"></td>
                                            <td className="font_500"></td>

                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <p className="invisible mb-0 pb-0 font_16">1</p>
                                            </td>

                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16">FINSAI required to have effective controls to ensure proper
                                                implementation of AML/CFT policies and procedures.</td>
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                            <td className="text-center">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Does your AML/CFT systems cover the following controls?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="font_14 font_500">(a) Senior management oversight</td>
                                            <td className=" font_500"></td>
                                            <td className="font_500"></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="font_14 ">(i) Is your senior management satisfied with the
                                                current AML/CFT system's capability of addressing the ML/TF risks identified?
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td className="font_500"></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(ii) Have you appointed an appropriate staff as a Compliance
                                                Officer ("CO") and a Money Laundering Reporting Officer ("MLRO")?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="font_14 font_500">(b) Compliance and audit function</td>
                                            <td className=" font_500"></td>
                                            <td className="font_500"></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(i) Have you established a compliance and audit function which
                                                should be independent of all operational and business functions as far as
                                                practicable and has a direct line of communication to senior management?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(ii) If yes, does the function regularly review the A
                                                ML/CFT systems to ensure effectiveness?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(iii) If appropriate, have you sought review assistance from
                                                external sources regarding your AML/CFT systems?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="font_14 font_500">(c) Staff screening</td>
                                            <td className=" font_500"></td>
                                            <td className="font_500"></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(i) Do you establish, maintain and operate appropriate
                                                procedures in order to be satisfied of the integrity of any new employees?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr className="td-heading heading-gray">
                                            <td className="font_16 text-center black_21 font_500">Risk-Based Approach ("RBA")</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to determine the
                                                extent of CDD measures and ongoing monitoring, using an RBA depending upon the
                                                background of the customer and the product, transaction or service used by that
                                                customer.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Does your RBA identify and categorize ML/TF risks at the
                                                customer level and establish reasonable measures based on risks identified?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you consider the following risk factors when determining the
                                                ML/TF risk rating of customers?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(a) Country risk - customers with residence in or
                                                connection
                                                with the below high-risk jurisdictions</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(i) countries identified by the FATF as jurisdictions with
                                                strategic AML/CFT deficiencies</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(ii) countries subject to sanctions, embargos or similar
                                                measures issued by international authorities (e.g. the UN)</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(iii) countries which are vulnerable to corruption</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(iv) countries that are believed to have strong links to
                                                terrorist activities</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(b) Customer risk - customers with the below nature or
                                                behavior might present a higher ML/TF risk</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(i) the public profile of the customer indicating involvement
                                                with, or connection to, politically exposed persons ("PEPs")</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(ii) complexity of the relationship, including use of
                                                corporate structures, trusts and the use of nominee and bearer shares where
                                                there is no legitimate commercial rationale</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(iii) request to use numbered accounts or undue levels of
                                                secrecy with a transaction</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(iv) involvement in cash-intensive businesses</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(v) nature, scope and location of business activities
                                                generating the funds/assets, having regard to sensitive or high-risk activities
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(vi) the origin of wealth (for high risk customers and PEPs)
                                                or ownership cannot be easily verified</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(c) Product/service risk - product/service with the
                                                below factors might present a higher risk</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(i) services that inherently have provided more anonymity</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(ii) ability to pool underlying customers/funds</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(d) Distribution/delivery channels</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(i) a non-face-to-face account opening approach is used</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(ii) Business sold through third party agencies or
                                                intermediaries</td>
                                            <td className=" font_500"></td>
                                            <td className=" font_500">No</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">Do you adjust your risk assessment of customers from time to
                                                time or based upon information received from a competent authority, and review
                                                the extent of the CDD and ongoing monitoring to be applied?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16 text-center black_21 font_500">Customer Due Diligence ("CDD")
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to carry out CDDs, which is a
                                                vital tool for recognising whether there are grounds for knowledge or suspicion
                                                of ML/TF.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">Do you conduct the following CDD measures?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(a) identify the customer and verify the customer's
                                                identity using reliable, independent source documents, data or information</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(b) where there is a beneficial owner in relation to
                                                the customer, identify and take reasonable measures to verify the beneficial
                                                owner's identity, including in the case of a legal person or trust, measures to
                                                enable you to understand the ownership and control structure of the legal person
                                                or trust</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(c) obtain information on the purpose and intended
                                                nature of the business relationship established with you unless the purpose and
                                                intended nature are obvious</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">(d) if a person purports to act on behalf of the
                                                customer:</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 ">(i) identify the person and take reasonable measures to verify
                                                the person's identity using reliable and independent source documents, data or
                                                information</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(ii) verify the person's authority to act on behalf of the
                                                customer (e.g. written authority, board resolution)</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_500">Do you apply CDD requirements in the following
                                                conditions?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) at the outset of a business relationship</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) when you suspect that a customer or a customer's account is
                                                involved in ML/TF</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) when you doubt the veracity or adequacy of any information
                                                previously obtained for the purpose of identifying the customer or for the
                                                purpose of verifying the customer's identity</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to identify and take
                                                reasonable measures to verify the identity of a beneficial owner.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you identify the beneficial owner(s) in relation to a
                                                customer, and, based on your assessment of the ML/TF risks, take reasonable
                                                measures to verify the beneficial owner's identity so that you are satisfied
                                                that you know who the beneficial owner is?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">When an individual is identified as a beneficial owner, do you
                                                obtain the following identification information?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) Full name</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) Date of birth</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) Nationality</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) Identity document type and number</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to take appropriate steps to
                                                verify the genuineness of identification provided if suspicions are raised.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">In case of suspicions raised in relation to any document in
                                                performing CDD, have you taken practical and proportionate steps to establish
                                                whether the document offered is genuine, or has been reported as lost or stolen?
                                                (e.g. search publicly available information, approach relevant authorities)</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you rejected any documents provided during CDD and
                                                considered making a report to the authorities  where suspicion on the
                                                genuineness of the information cannot be eliminated?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to understand the purpose and
                                                intended nature of the business relationship established.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Unless the purpose and intended nature are obvious, have you obtained satisfactory information from all new customers (including non-residents) as to the intended purpose and reason for opening the account or establishing the business relationship, and record the information on the relevant account opening documentation?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to complete the CDD before
                                                establishing business relationships.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you always complete CDD process before establishing business
                                                relationships?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If you are unable to complete the CDD process, do you ensure
                                                that the relevant business relationships must not be established and assess
                                                whether this failure provides grounds for knowledge or suspicion of ML/TF to
                                                submit a report as appropriate?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If the CDD process is not completed before establishing a
                                                business relationship, would these be on an exception basis only and with
                                                consideration of the following:</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) any risk of ML/TF arising from the delayed verification of
                                                the customer's or beneficial owner's identity can be effectively managed.</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) it is necessary not to interrupt the normal course of
                                                business with the customer (e.g., securities transactions).</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) verification is completed as soon as reasonably
                                                practicable.</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) the business relationship will be terminated if
                                                verification cannot be completed as soon as reasonably practicable.</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you adopted appropriate risk management policies and
                                                procedures when a customer is permitted to enter into a business relationship
                                                prior to verification?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, do they include the following?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) establishing timeframes for the completion of the identity
                                                verification measures and that it is carried out as soon as reasonably
                                                practicable</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) placing appropriate limits on the number of transactions
                                                and type of transactions that can be undertaken pending verification</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) ensuring that funds are not paid out to any third party
                                            </td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) other relevant policies and procedures, please further
                                                elaborate in the text box</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">When terminating a business relationship where funds or other
                                                assets have been received, have you returned the funds or assets to the source
                                                (where possible) from which they were received?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to keep the customer
                                                information up-to-date and relevant.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you undertake reviews of existing records of customers to
                                                ensure that the information obtained for the purposes of complying with the AMLO
                                                requirements are up-to-date and relevant when one of the following trigger
                                                events happen?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) when a significant transaction is to take place (not
                                                necessarily linked to monetary value, but also unusual transactions</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) when a material change occurs in the way the customer's
                                                account is operated</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) when your customer documentation standards change
                                                substantially</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) when you are aware that you lack sufficient information
                                                about the customer concerned</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(e) if there are other trigger events that you consider and
                                                defined in your policies and procedures, please elaborate further in the text
                                                box</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to identify and verify the
                                                true and full identity of each natural person by using reliable and independent
                                                sources of information.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you have customers which are natural persons?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you collect the same set of identification information as
                                                listed for personal customers?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI are required to identify and verify
                                                the true and full identity of each legal person and trust and its beneficial
                                                owners by using reliable and independent sources of information.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you have measures to look behind each legal person or trust
                                                to identify those who have ultimate control or ultimate beneficial ownership
                                                over the business and the customer's assets?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you fully understand the customer's legal form, structure
                                                and ownership, and obtain information on the nature of its business, and reasons
                                                for seeking the product or service when the reasons are not obvious?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI may conduct simplified customer due
                                                diligence ("SDD")/EDD instead of full CDD measures given reasonable grounds to
                                                support it</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you conducted SDD/EDD instead of full CDD measures for
                                                your customers?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you refrain from applying SDD when you suspect that the
                                                customer, the customer's account or the transaction is involved in ML/TF, or
                                                when you doubt the veracity or adequacy of any information previously obtained
                                                for the purpose of identifying or verifying the customer?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required, in any situation that by its
                                                nature presents a higher risk of ML/TF, to take additional measures to mitigate
                                                the risk of ML/TF.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you take additional measures or enhanced due diligence
                                                ("EDD") when the customer presents a higher risk of ML/TF?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, do they include the following?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) obtaining additional information on the customer and
                                                updating more regularly the customer profile including the identification data
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) obtaining additional information on the intended nature of
                                                the business relationship, the source of wealth and source of funds
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) obtaining the approval of senior management to commence or
                                                continue the relationship
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) conducting enhanced monitoring of the business
                                                relationship, by increasing the number and timing of the controls applied and
                                                selecting patterns of transactions that need further examination.
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to apply equally effective
                                                customer identification procedures and ongoing monitoring standards for
                                                customers not physically present for identification purposes as for those where
                                                the customer is available for interview.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you accept customers that are not physically present for
                                                identification purposes to open an account?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, have you taken additional measures to compensate for
                                                any risk associated with customers not physically present (i.e. face to face)
                                                for identification purposes?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, do they include at least one of the following?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) further verifying the customer's identity on the basis of
                                                documents, data or information</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) taking supplementary measures to verify information
                                                relating to the customer that have been obtained by you</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to determine whether a
                                                potential customer, a customer or the beneficial owner is a politically exposed
                                                person ("PEP") and to adopt EDD on PEPs.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you define what a PEP (foreign and domestic) is in your
                                                AML/CFT policies and procedures?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you established and maintained effective procedures for
                                                determining whether a customer or a beneficial owner of a customer is a PEP
                                                (foreign and domestic)?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, is screening and searches performed to determine if a
                                                customer or a beneficial owner of a customer is a PEP? (e.g. through
                                                commercially available databases, publicly available sources and internet /
                                                media searches etc.)</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600">Foreign PEPs</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you conduct EDD at the outset of the business relationship
                                                and ongoing monitoring when a foreign PEP is identified or suspected?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you applied the following EDD measures when you know that
                                                a particular customer or beneficial owner is a foreign PEP (for both existing
                                                and new business relationships)?</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) obtaining approval from your senior management</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) taking reasonable measures to establish the customer's or
                                                the beneficial owner's source of wealth and the source of the funds</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) applying enhanced monitoring to the relationship in
                                                accordance with the assessed risks</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14 font_600">Domestic PEPs</td>
                                            <td className=" font_500"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you performed a risk assessment for an individual known to
                                                be a domestic PEP to determine whether the individual poses a higher risk of
                                                ML/TF?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes and the domestic PEP poses a higher ML/TF risk, have you
                                                applied EDD and monitoring?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, have you retained a copy of the assessment for related
                                                authorities, other authorities and auditors and reviewed the assessment whenever
                                                concerns as to the activities of the individual arise?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">For foreign and domestic PEPs assessed to present a higher
                                                risk, are they subject to a minimum of an annual review and ensure the CDD
                                                information remains up-to-date and relevant?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">Due to the higher ML/TF risks associated with
                                                bearer shares, FINSAI should take appropriate measures to ensure that they are
                                                not misused for money laundering.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">For corporate customers, do you obtain and request the
                                                memorandum and articles of association to ascertain whether the company has the
                                                capacity to issue such bearer shares?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">In the case of companies with capital in the form of bearer
                                                shares, do you have procedures to confirm the identities of the holders and
                                                beneficial owners of such shares and ensure that you are notified whenever there
                                                is a change of holder or beneficial owner?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you sought independent evidence where bearer shares have
                                                been deposited with an authorized / registered custodian?</td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, have you performed an ongoing periodic review for it?
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">In the case where the bearer shares are not deposited with an
                                                authorized / registered custodian, do you have procedures to obtain declarations
                                                prior to account opening and annually thereafter from each beneficial owner of
                                                such shares?
                                            </td>
                                            <td className=" font_500">Yes</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI not allowed to maintain anonymous
                                                accounts or accounts in fictitious names for any new or existing customers.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you refrain from maintaining (for any customer) anonymous
                                                accounts or accounts in fictitious names?
                                            </td>

                                            <td></td>
                                            <td className="font_500">No</td>
                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to assess and determine
                                                jurisdictional equivalence as this is an important aspect in the application of
                                                CDD measures.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">When you do your documentation for assessment or determination
                                                of jurisdictional equivalence, do you take the following measures?
                                            </td>

                                            <td></td>
                                            <td className="font_500"></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) make reference to up-to-date and relevant information
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) retain such record for regulatory scrutiny
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) periodically review to ensure it remains up-to-date and
                                                valid
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">Ongoing monitoring</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to perform effective ongoing
                                                monitoring for understanding customer's activities and it helps the firm to know
                                                the customers and to detect unusual or suspicious activities.</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you continuously monitor your business relationship with a
                                                customer by:
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) monitoring the activities (including cash and non-cash
                                                transactions) of the customer to ensure that they are consistent with the nature
                                                of business, the risk profile and source of funds.
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) identifying transactions that are complex, large or unusual
                                                or patterns of transactions that have no    apparent economic or lawful purpose
                                                and which may indicate ML/TF
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you monitor the following characteristics relating to your
                                                customer's activities and transactions?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) the nature and type of transaction (e.g. abnormal size of
                                                frequency)
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) the nature of a series of transactions (e.g. a number of
                                                cash deposits)
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) the amount of any transactions, paying particular attention
                                                to substantial transactions
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) the geographical origin/destination of a payment or receipt
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(e) the customer's normal activity or turnover
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you regularly identify if the basis of the business
                                                relationship changes for customers when the following occur?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) new products or services that pose higher risk are entered
                                                into
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) new corporate or trust structures are created
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) the stated activity or turnover of a customer changes or
                                                increases
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) the nature of transactions change or the volume or size
                                                increases
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(e) if there are other situations, please specify and further
                                                elaborate in the text box
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">In the case where the basis of a business relationship changes
                                                significantly, do you carry out further CDD procedures to ensure that the ML/TF
                                                risk and basis of the relationship are fully understood?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16 text-center black_21 font_500">Suspicious Transaction reports
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to adopt on-going monitoring
                                                procedures to identify suspicious transactions for the reporting of funds or
                                                property known or suspected to be proceeds of crime or terrorist activity to
                                                supervisor authority /SA/
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you have policy or system in place to make
                                                disclosures/suspicious transaction reports with the SA?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you apply the following principles once knowledge or
                                                suspicion has been formed?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td className=" font_14">(a) in the event of suspicion of ML/TF, a disclosure is made
                                                even where no transaction has been conducted by or through your institution
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) internal controls and systems are in place to prevent any
                                                directors, officers and employees, especially those making enquiry with
                                                customers or performing additional or enhanced CDD process, committing the
                                                offence of tipping off the customer or any other person who is the subject of
                                                the disclosure
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16 text-center black_21 font_500">Record Keeping and Retention of
                                                Records
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">Record Keeping and Retention of Records
                                                FINSAI required to maintain customer, transaction and other records that are
                                                necessary and sufficient to meet the record-keeping requirements.
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you keep the following documents/ records relating to
                                                customer identity?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you keep the following documents/ records relating to
                                                transactions?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) the identity of the parties to the transaction
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) the nature and date of the transaction
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) the type and amount of currency involved
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) the origin of the funds
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(e) the form in which the funds were offered or withdrawn
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(f) the destination of the funds
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(g) the form of instruction and authority
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(h) the type and identifying number of any account involved in
                                                the transaction
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(i) if there are other documents/ records considered obtained
                                                in connection with the transactions and / or to establish a financial profile of
                                                any suspect account or customer, please further elaborate in the text box
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16 text-center black_21 font_500">Staff Training
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="td-heading heading-gray">
                                            <td className="font_16  black_21 font_500">FINSAI required to provide adequate ongoing
                                                training for staff in what they need to do to carry out their particular roles
                                                with respect to AML/CFT.
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Have you implemented a clear and well articulated policy to
                                                ensure that relevant staff receive adequate AML/CFT training?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you provide AML/CFT training to your staff to maintain their
                                                AML/CFT knowledge and competence?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, does the training program cover the following topics?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) your institution's and the staff's own personal statutory
                                                obligations and the possible consequences for failure to report suspicious
                                                transactions under relevant laws and regulations
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) any other statutory and regulatory obligations that concern
                                                your institution and the staff under the relevant laws and regulations, and
                                                the possible consequences of breaches of these obligations
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) your own policies and procedures relating to AML/CFT,
                                                including suspicious transaction identification and reporting
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(d) any new and emerging techniques, methods and trends in
                                                ML/TF to the extent that such information is needed by your staff to carry
                                                out their particular roles in your institution with respect to AML/CFT
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you provide AML/CFT training for all your new staff,
                                                irrespective of their seniority and before work commencement?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, does the training program cover the following topics?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) an introduction to the background to ML/TF and the
                                                importance placed on ML/TF by your institution
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) the need for identifying and reporting of any suspicious
                                                transactions to the MLRO, and the offence of "tipping-off"
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you provide AML/CFT training for your members of staff who
                                                are dealing directly with the public?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, does the training program cover the following topics?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) the importance of their role in the institution's ML/TF
                                                strategy, as the first point of contact with potential money launderers
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) your policies and procedures in relation to CDD and
                                                record-keeping requirements that are relevant to their job responsibilities
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(c) training in circumstances that may give rise to suspicion,
                                                and relevant policies and procedures, including, for example, lines of reporting
                                                and when extra vigilance might be required
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you provide AML/CFT training for your back-office staff?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, does the training program cover the following topics?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) appropriate training on customer verification and relevant
                                                processing procedures
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) how to recognise unusual activities including abnormal
                                                settlements, payments or delivery instructions
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you provide AML/CFT training for managerial staff including
                                                internal audit officers and COs?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">If yes, does the training program cover the following topics?
                                            </td>
                                            <td className="font_500"></td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(a) higher level training covering all aspects of your AML/CFT
                                                regime
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">(b) specific training in relation to their responsibilities for
                                                supervising or managing staff, auditing the system and performing random checks
                                                as well as reporting of suspicious transactions to the FCA
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className=" font_14">Do you provide AML/CFT training for your MLROs?
                                            </td>
                                            <td className="font_500">Yes</td>
                                            <td></td>

                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Model />
            </main>
        </>
    )
}
export default RiskAssessmentGapAnalysis