import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Model from "../components/model/model"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom"
import ScrollToTop from "../components/scroll_top"
import { useState } from "react"


const Accounts = () => {

    const [scroll, setScroll] = useState(true)

    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main className="finsai-about-main">
                <Header />
                <section className="sec-pad accounts-banner mb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 col-lg-6 col-xl-6 col-12 ">
                                <div>
                                    <p className="banner-title  font_54 font_700 text-white">Opening and<br />
                                        Managing Accounts<br /> Becomes Easy</p>
                                    <p className="banner-text font_18 font_500 font_inter mb-sm-5 mb-md-4">Now, with FINSAI, you can send and accept<br />
                                        currencies across the Globe.</p>
                                    <Link to="/select-country" target="_blank" className="white_btn font_20 font_600 ">Get
                                        Started</Link>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-6 col-xl-6 col-12 p-0 ">
                                <div className="accounts-model-img"><img src="assets/images/vector-account.png" alt="pay" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="payment-finance-sec pb-0">
                            <div className="row">

                                <div className="col-lg-6 col-xl-7 col-md-7 col-12 flex-order-2">
                                    <div>
                                        <p className="font_40 text-white font_700">Global Transactions, Your Way: Effortless
                                            Currency Choices and Unbeatable Convenience</p>
                                        <p className="font_inter font_18 font_500 text-white mb-4 pb-3">Accounts in FINSAI will aid
                                            you
                                            in quickly paying and receiving funds in the currency of your choice. Choose what
                                            suits you, select, tap, and the transaction is complete! Hurry up now and begin your
                                            journey without relying on physical cards and banks.</p>

                                    </div>


                                </div>
                                <div className="col-lg-6 col-xl-5 col-md-5 col-12 flex-order-1">
                                    <div className="freedom-finance after-pic-none">
                                        <div className="pay-icon">
                                            <img src="assets/images/payment-icon-03.png" alt="payment" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sec-pad">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <p className="font_40 font_700">Pay with Confidence</p>
                                    <p className="font_22 gray_79">Make online payments from your Finsai account. Choose what suits
                                        your transaction and
                                        transfer and receive funds, all from one solution. It will not just reduce the hassle of
                                        managing multiple accounts but also give you financial freedom.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 ">
                                <div className="account-img">
                                    <img src="assets/images/Pay_with_confidence.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pad pt-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="account-img icon2">
                                    <img src="assets/images/Make_global_transactions.png" alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <p className="font_40 font_700">Make Global Transactions</p>
                                    <p className="font_22 gray_79">With international currencies in your pocket on your smartphone,
                                        you can now transfer and receive funds in currencies from across the globe at your
                                        fingertips because you deserve what it takes to be at peace while making your next
                                        online transaction. It is easier, more efficient, and more empowering to make all your
                                        digital payments through one financial service provider.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="sec-pad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <p className="font_40 font_700">Open your Account in Simple steps</p>
                                    <p className="font_22 gray_79">Within the blink of an eye, you can create and manage your
                                        accounts. Just follow the simple steps, and you’re all set to start doing business
                                        globally with all the security and safety you deserve. Seamless and swift, our platform
                                        empowers you to seize opportunities worldwide, unlocking your financial potential with
                                        ease.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="account-img">
                                    <img src="assets/images/account-icon-1.png" alt="account" />
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="bg-white multi-cur-sec">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6 col-12">
                                <div className="accounts-icon">
                                    <img src="assets/images/multi-currency-accounts.png" alt="account" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <p className="font_40 font_700">Multi-Currency IBAN Accounts</p>
                                    <p className="font_22 gray_79 font_500">Open yourself to a multitude of possibilities with the
                                        multi-currency solution at FINSAI.
                                        Make the best use of a single dedicated IBAN to operate globally in more than 20
                                        currencies. Giving you the flexibility to manage your payments in multiple currencies.
                                        Take advantage of seamless payments in USD, GBP, or EUR. The power of your finances is
                                        in your hands. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="transform pay-sec sec-pad">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="future_together text-center">
                                    <p className="font_22 text-center text">Open the doors to Online Finance with FINSAI</p>
                                    <p className="font_43 font_700 text-center text-white mb-sm-5">Your Account, Your Business, Your
                                        Way!</p>

                                    <Link to="/select-country" target="_blank" className="white_btn font_20 font_600">
                                        Try it now
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-white multi-cur-sec mb_78">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-6 col-12">
                                <div className="accounts-icon">
                                    <img src="assets/images/accounts-secure-icon.png" alt="accounts" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <p className="font_40 font_700">Experience Security, with FINSAI Financial Technology
                                    </p>
                                    <p className="font_22 gray_65 font_300">We have a dedicated team that looks after your safety
                                        and security with all your digital payments. Whatever type of account you use for your
                                        online payment, our financial technology keeps you secure at all times.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="begins-sec mb_78">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-11 col-12">
                                <div className="text-center">
                                    <p className="title font_22">Ready to take control of your finances? </p>
                                    <p className="font_40 text-white font_700 mb-4">It's time to open an account with FINSAI, your
                                        trusted partner in the financial service provider industry.</p>
                                    <Link to="/select-country" target="_blank"
                                        className="sign_gradient_btn font_18 font_600 mb-4 mb-sm-5"> Discover FINSAI
                                        now</Link>
                                    <div className="text-center d-sm-flex align-items-center justify-content-center">
                                        <Link to="/select-country" target="_blank" className="d-block">
                                            <img src="assets/images/play-store.svg"
                                                className="me-sm-3 mb-3 mb-sm-0 img-fluid icon-playstore" alt="play store" />
                                        </Link>
                                        <Link to="/select-country" target="_blank" className="d-block ">
                                            <img src="assets/images/app-store.svg" className="img-fluid icon-playstore"
                                                alt="app store" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-pad">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-120 flex-wrap">
                            <div>
                                <p className="font_64 font_700">Pricing plans</p>
                                <p className="font_20 gray_52">Discover more, experience better – choose your plan and unlock a
                                    world<br /> tailored to you.</p>
                            </div>
                            <div>
                                <Link to="/select-country" target="_blank" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Lite <br />
                                            £ 0.00<span className="font_14 font_600">/Month</span>
                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Start your journey with confidence toward meeting your
                                            financial goals. Experience optimized financial transactions and custom-made
                                            solutions for your needs.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--1.png" alt="plans-card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Smart
                                            <br />
                                            £ 4.99<span className="font_14 font_600">/Month</span>

                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Conveniently handle your day-to-day finances with our
                                            Smart
                                            Plan.Unlock a world of convenient money management and personalized perks. Grab your
                                            key to a smarter way of handling your finances.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--2.png" alt="plans-card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Premium
                                            <br /> £ 9.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Experience the epitome of luxury with our Premium
                                            Debit
                                            Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                            that redefine your financial journey.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--3.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <div className="d-flex mb-3 gap-2">
                                            <img src="assets/images/icon/logo-card.png" alt="logo" />
                                            <p className="vip-tag">VIP</p>
                                        </div>
                                        <p className="font_34 font_600 text-blue  lh-sm">Metal
                                            <br /> £ 14.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Indulge yourself in the ultimate experience with our
                                            Metal
                                            Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                            rewards, top-tier security, and an aura of exclusivity.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--4.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <Footer />
                <Model />
                <HelmetProvider>
                    <Helmet>
                        <script>
                            {
                                `
                                ////text height////
                                var highestBox = 0;
                                $('.card-plan .text').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.card-plan .text').height(highestBox);
                            `
                            }
                        </script>
                    </Helmet>
                </HelmetProvider>
            </main>
        </>
    )
}
export default Accounts