import { createSlice } from '@reduxjs/toolkit'
import { getCountries } from '../../api/apiHandler'

const countryListSlice = createSlice({
    name: 'countryList',
    initialState: [],
    reducers: {
        addCountryList(state, action) {
            state.push(action.payload)
        }
    },

})

export default countryListSlice.reducer;
export const { addCountryList } = countryListSlice.actions;
