import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Model from "../components/model/model";
import { useState } from "react"
import ScrollToTop from "../components/scroll_top"

const Personal = () => {
    const [scroll, setScroll] = useState(true)
    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            <main className="finsai-about-main">
                <Header />

                <section className="personal_banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <h1 className="font_80 font_700 text-white mb_30">Control your <br />
                                    financial <br />
                                    future easily</h1>
                                <p className="subtitle font_24 font_500 white_de mb_65">Spend, save and manage your money, all in one place.
                                    Open an account from your phone.</p>
                                <Link to="/select-country" target="_blank" className="white_btn font_20 font_600">Open Finsai Account</Link>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
                                <div className="personal_card_girl">
                                    <img src="assets/images/persoanl-card.png" className="img-fluid" alt="card" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="best_features">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-xl-8 col-lg-8 col-md-9 col-sm-12">
                                <h2 className="font_54 font_700 mb-0 text-center">Feel the best experience with our features</h2>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                <div className="mt_100">
                                    <div className="icon">
                                        <img src="assets/images/multi-currency--1.svg" alt="img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                <div className="iban_card mt_100">
                                    <h3 className="font_28 font_700 mb_30">Multi-currency IBANs in 20+ currencies</h3>
                                    <p className="font_20 font_400 gray_52 mb-sm-5">Operate globally in more than 20 currencies using
                                        your dedicated IBAN. You can manage your payments while benefiting from effortless
                                        transactions in USD, GBP, or EUR through SWIFT/SEPA payment options. Tap into your
                                        financial superpower today!</p>
                                    <Link to="/select-country" target="_blank"
                                        className="text-blue font_22 font_500 d-inline-flex align-items-center">Sign Up Now <img
                                            src="assets/images/right-circle-blue.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-6 col-md-6 col-sm-12 flex-order-2">
                                <div className="iban_card mt_100">
                                    <h3 className="font_28 font_700 mb_30">Simplified International Transactions</h3>
                                    <p className="font_20 font_400 gray_52 mb-sm-5">The days of tedious paperwork and long waits are
                                        gone. With our simplified international transfers, you can send money to your loved
                                        ones, pay suppliers, or cover expenses abroad with unparalleled ease. Our platform
                                        guides you through the process, ensuring you stay in control every step of the way.</p>
                                    <Link to="/select-country" target="_blank"
                                        className="text-blue font_22 font_500 d-inline-flex align-items-center">Learn More <img
                                            src="assets/images/right-circle-blue.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                <div className="mt_100">
                                    <div className="icon">
                                        {/* <img src="assets/images/multi-currency--2.svg" alt="multi" /> */}
                                        <img src="assets/images/Simplified_International_Transactions-1.png" alt="multi" />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="foreign_payments">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12 mb_250">
                                <div className="icon">
                                    <img src="assets/images/fingertips-1.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-7 col-md-7 col-sm-12 mt-4 mt-md-0 mb_78">
                                <h3 className="font_28 font_700 text-white mb_20">Foreign Remittances At Your Fingertips</h3>
                                <p className="font_24 font_300 text-white mb_30">We understand and recognize the significance of
                                    staying in touch with your loved ones while efficiently managing your international
                                    financial commitments. Send money to your connections, all while enjoying a smooth,
                                    local-like experience.</p>
                                <Link to="/select-country" target="_blank"
                                    className="text-white font_22 font_500 d-inline-flex align-items-center">Create account <img
                                        src="assets/images/right-circle.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                            </div>
                            <div className="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-12 mt-4 mt-md-0 flex-order-2">
                                <h3 className="font_28 font_700 text-white mb_20">The Power of Payments</h3>
                                <p className="font_24 font_300 text-white mb_78">Transfer money instantly to your friends,
                                    acquaintances, or business peers with just a few clicks. Our digital payment solutions
                                    enable you to transfer money securely, making your experience effortless. Embrace our
                                    contactless payment option and transform the way you manage your money.</p>
                                <Link to="/select-country" target="_blank" className="sign_gradient_btn pad-btn font_18 font_600">Sign Up for free</Link>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0 flex-order-1">
                                <div className="icon">
                                    <img src="assets/images/fingrtips-2.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fiat_white">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-5 col-sm-12 mb-4 mb-md-0">
                                <div className="crypto-icon">
                                    <img src="assets/images/instant-crypto.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-7 col-sm-12">
                                <h5 className="font_28 font_700 mb_20 mt-3">Instant Crypto to Fiat Conversions</h5>
                                <p className="font_22 font_300 gray_52 mb_20">Manage your finances with ease with our instant crypto
                                    to fiat conversion. Smoothly transform your crypto assets into traditional currencies,
                                    offering you the flexibility to navigate the ever changing financial world.</p>
                                <Link to="/select-country" target="_blank"
                                    className="text-blue font_22 font_500 d-inline-flex align-items-center">Learn More <img
                                        src="assets/images/right-circle-blue.png" className="img-fluid ms-2" alt="arrow" /> </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="personal_effortless">
                    <div className="container-fluid px-lg-0">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_white gateway_1 proff-crd" >
                                    <div className="row align-items-center ">
                                        <div className="col-12 col-xl-5 col-lg-5 col-md-4 col-sm-4">
                                            <div className="icon professional-icon">
                                                <img src="assets/images/personal-proffessional.svg" alt="professional" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-7 col-lg-7 col-md-8 col-sm-8 ">
                                            <h4 className="font_28 font_700 mb_20 personal-title">Personal and <br /> Professional Delight</h4>
                                            <p className="font_22 font_300 mb30 gray_52">Boost your account management with our
                                                streamlined solutions for both Personal and Business accounts. Our intuitive
                                                interface smartly assists in navigating between accounts, accessing your
                                                information, and taking control of your financial journey. Every account with
                                                FINSAI is a step closer to reaching your financial aspirations.</p>
                                            <Link to="/select-country" target="_blank"
                                                className="text-blue font_22 font_500 d-inline-flex align-items-center">Learn More
                                                <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_white gateway_2 gateway_1 proff-crd">
                                    <div className="row align-items-center ">
                                        <div className="col-12 col-xl-5 col-lg-5 col-md-4 col-sm-4">
                                            <div className="icon professional-icon">
                                                <img src="assets/images/effortless-transfer.svg" alt="professional" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-7 col-lg-7 col-md-8 col-sm-8 ">
                                            <h4 className="font_28 font_700 mb_20 personal-title">Effortless FX Transfers</h4>
                                            <p className="font_22 font_300 mb30 gray_52">Experience limitless possibilities with our
                                                Foreign Exchange service. With access to our technology, you can explore a
                                                diverse range of foreign currencies while simultaneously monitoring real-time
                                                currency conversions at the most competitive rates. Start your journey today!
                                            </p>
                                            <Link to="/select-country" target="_blank"
                                                className="text-blue font_22 font_500 d-inline-flex align-items-center">Create
                                                Account <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="virtual_debit_card">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                <div className="virtual_debit_content">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-xl-5 col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
                                            <div className="icon">
                                                <img src="assets/images/varitual-debit-cards.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-7 col-lg-7 col-md-8 col-sm-12">
                                            <h3 className="font_28 font_700 mb_30">Virtual and Debit Cards</h3>
                                            <p className="font_22 font_300 gray_52 mb_30">With our Virtual and Prepaid Debit Cards
                                                available in more than 20 currencies, unlock your financial independence with
                                                just a tap. These cards enable you to instantly access your funds, simplify your
                                                purchases and withdrawals, and make online money transfers with ease and
                                                security. Making your transactions thrive around the clock!</p>
                                            <Link to="/select-country" target="_blank" className="blue_outline_btn font_22 font_500">Explore
                                                Cards<img src="assets/images/right-circle.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="personal_effortless">
                    <div className="container-fluid px-lg-0">
                        <div className="row mt_100">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_white gateway_3 gateway_1">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-xl-5 col-lg-5 col-md-4 col-sm-4">
                                            <div className="icon professional-icon">
                                                {/* <img src="assets/images/connect-funds.svg" alt="funds" /> */}
                                                <img src="assets/images/connect-the-dots.png" alt="funds" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-7 col-lg-7 col-md-8 col-sm-8">
                                            <h4 className="font_28 font_700 mb_20">Connect the Dots, Transfer the Funds</h4>
                                            <p className="font_22 font_300 mb30 gray_52 ">Enjoy the convenience of secure
                                                transactions with ACH, BACS, and CHAPS payments options. Our FINSAI Local and
                                                Domestic transfer solutions enable you to send money using local rails spanning
                                                over 150 countries.</p>
                                            <Link to="/select-country" target="_blank"
                                                className="text-blue font_22 font_500 d-inline-flex align-items-center">Know More
                                                <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="gateway_white gateway_3 gateway_2 gateway_1">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-xl-5 col-lg-5 col-md-4 col-sm-4">
                                            <div className="icon professional-icon">
                                                <img src="assets/images/access-money.png" alt="money" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-7 col-lg-7 col-md-8 col-sm-8">
                                            <h4 className="font_28 font_700 mb_20">Access Money with Ease</h4>
                                            <p className="font_22 font_300 mb30 gray_52 mb_40">Whether your are travelling, eating out, or
                                                working get easy access to your money anytime, anywhere. Our easy to use
                                                interface enables you to access your money and make hassle-free transactions
                                                swiftly.</p>
                                            <Link to="/select-country" target="_blank" className="text-blue font_22 font_500 d-flex">Start using
                                                now <img src="assets/images/right-circle-blue.png" className="img-fluid ms-2"
                                                    alt="arrow" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="discover_magic mb-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                <h3 className="font_40 font_700 text-white mb_25">Discover 24x7 Magic</h3>
                                <p className="font_28 font_300 mb-0 gray_d4 subtitle">Introduce FINSAI to your friends, family, or business
                                    networks with our Refer a Friend program and earn rewards for each successful referral.
                                    Sharing your successful experience now has added benefits. Get the chance to earn exciting
                                    rewards while inviting your connections to share in the game-changing FINSAI experience.</p>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <div className="icon">
                                    <img src="assets/images/magic-gift.svg" alt="gift" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="refer_enjoy">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-10">
                                <h3 className="font_30 font_700 text-center mb_30">Refer, Earn, Enjoy: The Triple Win formula!!</h3>
                                <p className="font_22 font_300 gray_52 mb-0 text-center">Introduce FINSAI to your friends, family,
                                    or business networks with our Refer a Friend program and earn rewards for each successful
                                    referral. Sharing your successful experience now has added benefits. Get the chance to earn
                                    exciting rewards while inviting your connections to share in the game-changing FINSAI
                                    experience.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="sec-pad">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-120 flex-wrap">
                            <div>
                                <p className="font_64 font_700">Pricing plans</p>
                                <p className="font_20 gray_52" />Discover more, experience better – choose your plan and unlock a
                                world<br /> tailored to you.
                            </div>
                            <div>
                                <Link to="/select-country" target="_blank" className="compare-plans-btn font_20 font_600 d-block">Compare Plans</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="logo-card" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Lite <br />
                                            £ 0.00<span className="font_14 font_600">/Month</span>
                                        </p>
                                       
                                        <p className="text-blue font_600 mb-2">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Start your journey with confidence toward meeting your
                                            financial goals. Experience optimized financial transactions and custom-made
                                            solutions for your needs.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--1.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="card" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Smart
                                            <br />
                                            £ 4.99<span className="font_14 font_600">/Month</span>

                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Conveniently handle your day-to-day finances with our
                                            Smart
                                            Plan.Unlock a world of convenient money management and personalized perks. Grab your
                                            key to a smarter way of handling your finances.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--2.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <img src="assets/images/icon/logo-card.png" className="mb-3" alt="card" />
                                        <p className="font_34 font_600 text-blue  lh-sm">Premium
                                            <br /> £ 9.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Experience the epitome of luxury with our Premium
                                            Debit
                                            Card. Access exclusive privileges, elevated security, and a host of premium benefits
                                            that redefine your financial journey.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--3.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="card-plan">
                                    <div className="w-330">
                                        <div className="d-flex mb-3 gap-2">
                                            <img src="assets/images/icon/logo-card.png" alt="card" />
                                            <p className="vip-tag">VIP</p>
                                        </div>
                                        <p className="font_34 font_600 text-blue  lh-sm">Metal
                                            <br /> £ 14.99<span className="font_14 font_600">/Month</span>
                                        </p>
                                        
                                        <p className="text-blue font_600 mb-2 invisible">Open a free account instantly!</p>
                                        <p className="text-gray font_14 text">Indulge yourself in the ultimate experience with our
                                            Metal
                                            Plan. Crafted for the experts of fine finance, this card opens doors to unmatched
                                            rewards, top-tier security, and an aura of exclusivity.</p>
                                        <div className="d-flex align-items-center gap-4 mt-4">
                                            <Link to="/select-country" target="_blank" className="std-btn font_16 font_600">Choose Standard</Link>
                                            <Link to="/select-country" target="_blank" className="text-blue font_600">Learn more</Link>
                                        </div>
                                    </div>
                                    <div className="crcard-img">
                                        <img src="assets/images/plans-card--4.png" alt="card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <Footer />
                <Model />
                <HelmetProvider>
                    <Helmet>
                        <script>
                            {
                                `   
                               

                                ////text height////
                                var highestBox = 0;
                                $('.gateway_1 .text').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.gateway_1 .text').height(highestBox);

                                var highestBox = 0;
                                $('.waiting_text .text').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.waiting_text .text').height(highestBox);

                                var highestBox = 0;
                                $('.indulge_height').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.indulge_height').height(highestBox);

                                ////text height////
                                var highestBox = 0;
                                $('.card-plan .text').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.card-plan .text').height(highestBox);

                                var highestBox = 0;
                                $('.personal-title').each(function () {
                                    if ($(this).height() > highestBox) {
                                        highestBox = $(this).height();
                                    }
                                });
                                $('.personal-title').height(highestBox);

                               
                              
                            `
                            }
                        </script>
                    </Helmet>
                </HelmetProvider>
            </main>
        </>
    )
}

export default Personal